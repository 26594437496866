import { createIcon } from "@chakra-ui/react";

const customIcon = (fill?: string) =>
  createIcon({
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 1.125C9.31066 1.125 9.5625 1.37684 9.5625 1.6875V2.25H14.625C14.9234 2.25 15.2095 2.36853 15.4205 2.5795C15.6315 2.79048 15.75 3.07663 15.75 3.375V6.75C15.75 7.04837 15.6315 7.33452 15.4205 7.54549C15.2095 7.75647 14.9234 7.875 14.625 7.875H9.5625V9H14.3918C14.3918 9 14.3919 9 14.3918 9C14.5396 8.99999 14.6861 9.0291 14.8226 9.08567C14.9591 9.14223 15.0832 9.22513 15.1876 9.32964C15.1876 9.32959 15.1877 9.32968 15.1876 9.32964L17.2727 11.4148C17.4924 11.6344 17.4924 11.9906 17.2727 12.2102L15.1878 14.2952C15.1877 14.2953 15.1878 14.2952 15.1878 14.2952C15.0833 14.3997 14.9591 14.4828 14.8226 14.5393C14.6861 14.5959 14.5397 14.625 14.3919 14.625C14.3919 14.625 14.3919 14.625 14.3919 14.625H9.5625V16.3125C9.5625 16.6232 9.31066 16.875 9 16.875C8.68934 16.875 8.4375 16.6232 8.4375 16.3125V14.625H3.375C3.07663 14.625 2.79048 14.5065 2.5795 14.2955C2.36853 14.0845 2.25 13.7984 2.25 13.5V10.125C2.25 9.82663 2.36853 9.54048 2.5795 9.3295C2.79048 9.11853 3.07663 9 3.375 9H8.4375V7.875H3.60818C3.60815 7.875 3.60821 7.875 3.60818 7.875C3.46038 7.87501 3.31393 7.8459 3.17739 7.78933C3.04087 7.73277 2.91684 7.64987 2.81237 7.54536C2.81233 7.54532 2.81241 7.54541 2.81237 7.54536L0.727252 5.46025C0.507583 5.24058 0.507583 4.88442 0.727252 4.66475L2.81224 2.57977C2.8122 2.57981 2.81228 2.57972 2.81224 2.57977C2.91671 2.47526 3.04087 2.39223 3.17739 2.33567C3.31393 2.2791 3.46029 2.24999 3.60809 2.25C3.60806 2.25 3.60812 2.25 3.60809 2.25H8.4375V1.6875C8.4375 1.37684 8.68934 1.125 9 1.125ZM3.60809 3.375L1.9205 5.0625L3.60786 6.74987L14.625 6.75V3.375H3.60809ZM3.375 10.125L3.375 13.5H14.3919L16.0795 11.8125L14.3921 10.1251L3.375 10.125Z"
          fill={fill}
        />
      </svg>
    ),
    defaultProps: {
      fill: "black"
    }
  });

export const DirectionsIcon = customIcon("black");
