import { createIcon } from "@chakra-ui/icons";

const customIcon = (fill?: string, stroke?: string) =>
  createIcon({
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="18" height="18" rx="9" fill={fill} stroke="none" />
        <path
          d="M10.875 10.9062H7.125C6.08947 10.9062 5.25 11.7457 5.25 12.7812V13.7188H9H12.75V12.7812C12.75 11.7457 11.9105 10.9062 10.875 10.9062Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 9.03125C10.0355 9.03125 10.875 8.19178 10.875 7.15625C10.875 6.12072 10.0355 5.28125 9 5.28125C7.96447 5.28125 7.125 6.12072 7.125 7.15625C7.125 8.19178 7.96447 9.03125 9 9.03125Z"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none",
      stroke: "#44337A"
    }
  });

export const HumanIcon = customIcon();
export const HumanPurple100Icon = customIcon("#E9D8FD");
export const HumanCyan100Icon = customIcon("#C4F1F9", "#086F83");
