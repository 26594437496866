import { ElphiEntityType } from "@elphi/types";
import { flatten } from "flat";
import { accountAssetFieldSpecs } from "../../../form-builder/field-specs/asset/asset.fields";
import { creditReportFieldSpecs } from "../../../form-builder/field-specs/credit-report/credit-report.fields";
import { dealPropertyFieldSpecs } from "../../../form-builder/field-specs/deal-property/deal-property.fields";
import { dealFieldSpecs } from "../../../form-builder/field-specs/deal/deal.fields";
import { propertySpecFields } from "../../../form-builder/field-specs/property/property.fields";
import { statementFieldSpecs } from "../../../form-builder/field-specs/statement/statement.fields";
import { taskSpec } from "../../../form-builder/field-specs/task/task.fields";
import {
  assetFields,
  buildLabel,
  creditScoreFields,
  dealFields,
  dealPartyFieldSpecs,
  dealPartyFields,
  dealPropertyFields,
  partyFieldSpecs,
  partyFields,
  partyRelationFieldSpecs,
  partyRelationFields,
  propertyFields,
  statementFields,
  taskFields
} from "../field-selection.utils";
import {
  FieldMappingOption,
  FieldSelectionBuilder
} from "./field-selection.builder.types";

export const fieldSelection: FieldSelectionBuilder = {
  build: (
    entityType?: ElphiEntityType
  ): {
    label: string;
    value: string;
  }[] => {
    const mapping = entityType ? entityTypeMappings[entityType] : undefined;
    return mapping ? buildOptions(mapping) : [];
  }
};

const entityTypeMappings: Record<ElphiEntityType, FieldMappingOption> = {
  [ElphiEntityType.deal]: {
    fields: dealFields,
    fieldSpecs: dealFieldSpecs
  },
  [ElphiEntityType.property]: {
    fields: propertyFields,
    fieldSpecs: propertySpecFields
  },
  [ElphiEntityType.party]: {
    fields: partyFields,
    fieldSpecs: partyFieldSpecs
  },
  [ElphiEntityType.dealParty]: {
    fields: dealPartyFields,
    fieldSpecs: dealPartyFieldSpecs
  },
  [ElphiEntityType.partyRelation]: {
    fields: partyRelationFields,
    fieldSpecs: partyRelationFieldSpecs
  },
  [ElphiEntityType.dealProperty]: {
    fields: dealPropertyFields,
    fieldSpecs: dealPropertyFieldSpecs
  },
  [ElphiEntityType.asset]: {
    fields: assetFields,
    fieldSpecs: accountAssetFieldSpecs
  },
  [ElphiEntityType.statement]: {
    fields: statementFields,
    fieldSpecs: statementFieldSpecs
  },
  [ElphiEntityType.creditScore]: {
    fields: creditScoreFields,
    fieldSpecs: creditReportFieldSpecs
  },
  [ElphiEntityType.task]: {
    fields: taskFields,
    fieldSpecs: taskSpec
  }
};

const buildOptions = (option: FieldMappingOption) => {
  return Object.keys(flatten(option.fields, { safe: true })).map((key) => {
    return {
      label: buildLabel({
        object: option.fieldSpecs,
        key,
        shouldKeepFullPath: true
      }),
      value: key
    };
  });
};
