import { BaseEntity } from "../../db/baseEntity.types";

export enum TaskType {
  Document = "document",
  DocumentOrderStatus = "document_order_status",
  Integration = "integration"
}

export const TaskTypeValues = Object.values(TaskType);

export enum ActionType {
  MarkAsOrdered = "Mark as Ordered",
  UploadFile = "Upload File",
  ViewFile = "View File",
  ReadyForReview = "Ready for Review",
  FinalApproval = "Final Approval",
  Complete = "Complete",
  Reopen = "Reopen",
  Deny = "Deny"
}
export enum TaskStatusType {
  Created = "Created",
  ReadyToOrder = "Ready to Order",
  Ordered = "Ordered",
  DataCollection = "Data Collection",
  FileUploaded = "File Uploaded",
  ReadyForReview = "Ready for Review",
  FinalApproval = "Final Approval",
  Completed = "Completed",
  Delayed = "Delayed",
  Reopened = "Reopened",
  Denied = "Denied",
  Archived = "Archived",
  UnderReview = "Under Review",
  NotApplicable = "Not Applicable"
}

export enum SelectDataType {
  EntireChecklist = "Entire Checklist",
  FilteredChecklist = "Filtered Checklist"
}

export enum SelectFormatsType {
  TableToClipboard = "Table to clipboard",
  CSV = "CSV to file download",
  CSVToClipboard = "CSV to clipboard"
}

export type ActionToStatus = { [K in ActionType]: TaskStatusType };

export const actionToStatus: ActionToStatus = {
  "Final Approval": TaskStatusType.FinalApproval,
  "Mark as Ordered": TaskStatusType.Ordered,
  "Ready for Review": TaskStatusType.ReadyForReview,
  "Upload File": TaskStatusType.FileUploaded,
  "View File": TaskStatusType.UnderReview,
  Complete: TaskStatusType.Completed,
  Deny: TaskStatusType.Denied,
  Reopen: TaskStatusType.Reopened
};
export type StatusToColor = { [K in TaskStatusType]: string };

export const statusToColor: StatusToColor = {
  Created: "white",
  "Ready to Order": "orange.100",
  Ordered: "orange.400",
  "File Uploaded": "orange.300",
  "Ready for Review": "blue.200",
  "Final Approval": "pink.200",
  Completed: "green.200",
  Delayed: "red.700",
  Reopened: "white",
  Denied: "red.300",
  "Under Review": "purple.200",
  "Data Collection": "orange.200",
  Archived: "blackAlpha.300",
  "Not Applicable": "blackAlpha.500"
};
export type BaseTaskTemplate<TType extends TaskType> = BaseEntity<{
  template: {
    templateName: string;
    type: TType;
    taskStatus?: TaskStatusType | string;
    editableBy: string[];
    checklistOf: string[];
    assignableBy: string[];
    availableActions: ActionType[];
    availableStatus: TaskStatusType[];
  };
}>;
