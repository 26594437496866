import {
  BaseEntity,
  FirestoreTimestampFieldUnionType
} from "../db/baseEntity.types";

export enum UserRoleStatus {
  Active,
  Disabled
}
export type LOSUserRole = {
  label: string;
  value: string;
  status?: UserRoleStatus;
};
export type LOSUser = BaseEntity<{
  email: string;
  role: "los_user" | "admin" | "guest";
  roles: LOSUserRole[];
  name: string;
  firstName: string;
  middleName: string;
  lastName: string;
  assignedDeals?: string[];
  status: "active" | "disabled";
  lastSignIn?: FirestoreTimestampFieldUnionType;
  losStatus?: "away" | "active" | "busy";
  title?: string;
}>;

export enum UserRoleType {
  OpsAnalyst = "ops_analyst",
  ClosingAnalyst = "closing_analyst",
  Notary = "notary"
}

/**

milestones: 
- drafted milestone

los-user: 
- will create brokers

support custom fields * should help opx

 */
