import { BaseEntity } from "../../db";

export type AuditEvent = BaseEntity<AuditEventFields>;

export type AuditEventFields =
  | AuditDealEventFields
  | AuditPartyEventFields
  | AuditPropertyEventFields
  | AuditTaskEventFields
  | AuditDealPartyEventFields
  | AuditPartyAssetEventFields
  | AuditDealPropertyEventFields
  | AuditAssetEventFields
  | AuditStatementEventFields
  | AuditDealUserEventFields
  | AuditPartyUserEventFields;

export type AuditDealEventFields =
  BaseAuditEventFields<AuditEventEntityType.Deal>;
export type AuditPartyEventFields =
  BaseAuditEventFields<AuditEventEntityType.Party>;
export type AuditPropertyEventFields =
  BaseAuditEventFields<AuditEventEntityType.Property>;
export type AuditTaskEventFields =
  BaseAuditEventFields<AuditEventEntityType.Task> & {
    configurationId: string;
  };
export type AuditDealPartyEventFields =
  BaseAuditEventFields<AuditEventEntityType.DealParty>;
export type AuditPartyAssetEventFields =
  BaseAuditEventFields<AuditEventEntityType.PartyAsset>;
export type AuditDealPropertyEventFields =
  BaseAuditEventFields<AuditEventEntityType.DealProperty>;
export type AuditAssetEventFields =
  BaseAuditEventFields<AuditEventEntityType.Asset>;
export type AuditStatementEventFields =
  BaseAuditEventFields<AuditEventEntityType.Statement>;
export type AuditDealUserEventFields =
  BaseAuditEventFields<AuditEventEntityType.DealUser>;
export type AuditPartyUserEventFields =
  BaseAuditEventFields<AuditEventEntityType.PartyUser>;

export type BaseAuditEventFields<TEntityType extends AuditEventEntityType> = {
  dealIds?: string[];
  entityId: string;
  entityType: TEntityType;
  entityPrettyIdentifier?: string[];
  fieldPath?: string;
  fieldType?: AuditEventFieldType;
  beforeValue?: string;
  afterValue?: string;
  operation: AuditEventOperation;
  userEmail?: string;
};

export enum AuditEventOperation {
  Created = "created",
  Updated = "updated",
  Deleted = "deleted"
}

export enum AuditEventFieldType {
  Value = "value",
  Focus = "focus",
  FieldStatus = "fieldStatus",
  IntegrationStatus = "integrationStatus",
  UploadedFile = "uploaded-file",
  DeletedFile = "deleted-file"
}

export enum AuditEventEntityType {
  Deal = "deal",
  Party = "party",
  Property = "property",
  Task = "task",
  DealParty = "deal-party",
  PartyAsset = "party-asset",
  DealProperty = "deal-property",
  Asset = "asset",
  Statement = "statement",
  DealUser = "deal-user",
  PartyUser = "party-user"
}
