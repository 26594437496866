import { BaseEntity } from "../../db";

export const DEFAULT_PARTY_GROUP = "default";

export type Status = "active" | "disabled";

export type PartyGroup = BaseEntity<{
  name: string;
  description: string;
  status: Status;
}>;
