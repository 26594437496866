import { BaseEntity } from "../../db";

export enum PartyValidationType {
  TaxpayerIdentifierDuplication = "taxpayerIdentifierDuplication",
  PartyGroupDuplication = "partyGroupDuplication"
}

export type PartyValidationConfiguration = BaseEntity<{
  validationTypes: PartyValidationType[];
  name: string;
}>;
