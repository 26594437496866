import { get, min } from "lodash";
import {
  AggregationField,
  AggregationLeasedFinancedUnitsFieldValueType,
  BaseEntity,
  BooleanSelectType,
  DefaultAggregation,
  FirebaseCollections,
  LeasedFinancedUnit,
  LeasedFinancedUnits,
  LeasedFinancedUnitsFieldType,
  ThirdPartyAggregationField
} from "../../../types";
import { DotNestedKeys } from "../../../types/utils/flatten";
import { elphiDecimal } from "../../../utils/src/elphiDecimal";
import { ensureNonNegativeDecimalString } from "../../../utils/src/number.utils";

export const getFocusedData = (
  aggregation?: DefaultAggregation | ThirdPartyAggregationField
) => {
  if (!aggregation) return null;
  if (aggregation.focused === "override") return aggregation.override;
  else if (aggregation.focused === "calculated" && aggregation.calculated)
    return aggregation?.calculated;
  else if (aggregation.focused === "thirdParty") return aggregation?.thirdParty;

  return aggregation?.thirdParty || aggregation?.calculated || null;
};

export const getMonthDifference = (startDate: Date, endDate: Date) => {
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  );
};

export const calculate_EscrowAmount = (r: {
  id: string;
  annualTaxAmount: string | undefined;
  aggregationField: AggregationField | undefined;
}) => {
  const calc =
    (Number(r.annualTaxAmount) / 12) *
    Number(getFocusedData(r.aggregationField));
  const calculatedValue = !Number.isNaN(calc) ? String(calc) : "";
  return calculatedValue;
};

export const calculate_lesserOrNotNull = (
  sum1: string | undefined | number,
  sum2: string | undefined | number
) => {
  const isNotNullOrZero = (n: string | undefined | number) =>
    n != null && Number(n) !== 0;
  return isNotNullOrZero(sum1) && isNotNullOrZero(sum2)
    ? elphiDecimal(min([Number(sum1), Number(sum2)]) || 0).toString()
    : isNotNullOrZero(sum1)
    ? elphiDecimal(sum1 || 0).toString()
    : isNotNullOrZero(sum2)
    ? elphiDecimal(sum2 || 0).toString()
    : 0;
};

export const calculate_unitSum = (r: {
  units?: LeasedFinancedUnits | LeasedFinancedUnitsFieldType;
  field:
    | keyof LeasedFinancedUnit
    | keyof AggregationLeasedFinancedUnitsFieldValueType;
  isAggregation?: boolean;
}) => {
  const { units, field, isAggregation } = r;
  if (!units) return null;
  return Object.values(units)
    .map((v) => {
      const value: string | undefined = isAggregation
        ? getFocusedData(get(v, field))
        : get(v, field);
      return getNumberElseZero(value);
    })
    .reduce((a, b) => a + b);
};

export const getNumberElseZero = (s: string | undefined | null): number => {
  const castedToNumber = Number(s);
  if (s && !isNaN(castedToNumber)) return castedToNumber;
  return 0;
};

//IF ExecutionType = Not Applicable THEN deal.aggregation.RequestedLoanAmount * (NoteRatePercent/360) * (The difference between the last day of the EstimatedClosingDate - EstimatedClosingDate day.)
//NOTE - count EstimatedClosingDate day and the last day of the month of the EstimatedClosingDate ).
//Or (Last day of the month- estimated closing date) +1
//IF ExecutionType = Commitment Funded THEN (deal.aggregation.RequestedLoanAmount - deal.aggregation.TotalBudgetAmount) * (NoteRatePercent/360) * (The difference between the last day of the month of EstimatedClosingDate - EstimatedClosingDate day.)
//NOTE - count EstimatedClosingDate day and the last day of the month of the estimatedClosingDate) Or (Last day of the month- estimated closing date) +1.  IF ExecutionType = commitment 90% holdback THEN Empty
export const calculate_PrepaidInterestAmount = (r: {
  id: string;
  loanAmount: string;
  noteRatePercent: string;
  diffDays: number;
}) => {
  const calc = elphiDecimal(r.loanAmount)
    .mul(elphiDecimal(r.noteRatePercent).div(100).toString())
    .div(360)
    .mul(r.diffDays)
    .mul(100)
    .trunc()
    .div(100)
    .toString();
  const prepaidInterestAmount = ensureNonNegativeDecimalString(calc);
  return [
    {
      collection: FirebaseCollections.Deal,
      id: r.id,
      fields: {
        aggregations: {
          PrepaidInterestAmount: { calculated: prepaidInterestAmount }
        }
      }
    }
  ];
};

export const pickFromPayload = <T extends object>(r: {
  payload: T;
  pickKeys: DotNestedKeys<T>[];
}) => {
  return Object.fromEntries(
    r.pickKeys.map((key) => [key, get(r.payload, key)])
  );
};
const resolvePath = (object: any, path: string[], defaultValue: any) =>
  path.reduce((o, p) => (o ? o[p] : defaultValue), object);

const getFocusedAggregationSpecs = (v?: Partial<DefaultAggregation>) => {
  if (!v) return "";
  if (v.focused === "calculated") return v.calculated;
  else if (v.focused === "override") return v.override;
  else if (v.focused === "thirdParty") return v.thirdParty;
  else {
    //missing focused field value, unhandled case
    return "";
  }
};

const ZERO = "0";
export const sumAllForField = <T extends Partial<BaseEntity<object>>>(
  entities: T[],
  fieldPath: DotNestedKeys<T>
): string => {
  if (entities.length === 0) return ZERO;
  const stringPath: string = fieldPath;
  const pathArray = stringPath.split(".");
  const isPrefixAggergation =
    (pathArray?.[0] === "quote" && pathArray?.[1] === "aggregations") ||
    pathArray?.[0] === "aggregations";
  if (isPrefixAggergation) {
    return entities
      .map(
        (entity) => getFocusedAggregationSpecs(get(entity, fieldPath)) || ZERO
      )
      .reduce((prev, curr) => {
        return elphiDecimal(validateCalculatedString(String(prev)) ? prev : 0)
          .add(validateCalculatedString(String(curr)) ? curr : 0)
          .toString();
      }, ZERO);
  }
  const returnValue = entities
    .map((entity) => get(entity, fieldPath, 0))
    .reduce(
      (prev, curr) =>
        elphiDecimal(prev ? prev : 0)
          .add(curr ? curr : 0)
          .toString(),
      ZERO
    );
  if (returnValue === ZERO) return "";
  return returnValue;
};

export const checkIfYesExists = <T extends Partial<BaseEntity<object>>>(
  entities: T[],
  fieldPath: string[]
): BooleanSelectType => {
  if (entities.length === 0) return BooleanSelectType.false;
  if (fieldPath[0] === "aggregations") {
    return !!entities.find(
      (entity) =>
        getFocusedAggregationSpecs(
          resolvePath(entity, fieldPath, BooleanSelectType.false)
        ) === BooleanSelectType.true
    )
      ? BooleanSelectType.true
      : BooleanSelectType.false;
  } else {
    return !!entities.find(
      (entity) =>
        resolvePath(entity, fieldPath, BooleanSelectType.false) ===
        BooleanSelectType.true
    )
      ? BooleanSelectType.true
      : BooleanSelectType.false;
  }
};

export const checkIfEnumExists = <T extends Partial<BaseEntity<object>>>(
  entities: T[],
  fieldPath: string[],
  enumOption: string
): BooleanSelectType => {
  if (entities.length === 0) return BooleanSelectType.false;
  if (fieldPath[0] === "aggregations") {
    return !!entities.find(
      (entity) =>
        getFocusedAggregationSpecs(resolvePath(entity, fieldPath, "")) ===
        enumOption
    )
      ? BooleanSelectType.true
      : BooleanSelectType.false;
  } else {
    return !!entities.find(
      (entity) => resolvePath(entity, fieldPath, "") === enumOption
    )
      ? BooleanSelectType.true
      : BooleanSelectType.false;
  }
};

export const checkIfConditionIsTrue = <T extends Partial<BaseEntity<object>>>(
  entities: T[],
  conditionChecker: (entities: T) => boolean
): BooleanSelectType => {
  if (entities.length === 0) return BooleanSelectType.false;
  return entities.some((entity) => conditionChecker(entity))
    ? BooleanSelectType.true
    : BooleanSelectType.false;
};

export const daysUntilNextMonth = (date: Date) => {
  const utcDate = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  const firstDayOfNextMonth = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth() + 1,
    1,
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
  return (firstDayOfNextMonth - utcDate) / (1000 * 3600 * 24); // number of milliseconds in a day
};

export const validateCalculatedString = (
  s: string,
  invalidValues?: string[]
) => {
  return ["Infinity", "undefined", "NaN", "null"]
    .concat(invalidValues || [])
    .includes(s)
    ? ""
    : s;
};
