import { Box } from "@chakra-ui/react";
import { Tree } from "react-organizational-chart";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import ScaleChart, { useScaleChartHook } from "./ScaleChart";
import { convertDataToTreeNodes } from "./TreeUtils";
import { GenericTreeProps } from "./types/Tree.types";
const GenericTree = <T extends unknown>(props: GenericTreeProps<T>) => {
  const {
    treeState,
    elphiTreeOperations,
    nodeActions,
    customComponent,
    handleNodeSelect
  } = props;

  const {
    currectScaleFactor,
    scaleUp,
    isScaleUpDisabled,
    scaleDown,
    isScaleDownDisabled,
    currentFactor
  } = useScaleChartHook();

  const treeNodes = convertDataToTreeNodes(
    {
      rootNode: treeState,
      operations: elphiTreeOperations,
      nodeActions: treeState?.nodeActions?.length
        ? treeState.nodeActions
        : nodeActions,
      customComponent: customComponent,
      handleNodeSelect
    },
    0
  );

  return (
    <Box transform={`scale(${currentFactor || 1})`} transformOrigin="center">
      <Box bgColor="white" paddingBottom="10px">
        <Tree
          lineWidth={props?.lineWidth ?? "5px"}
          lineColor={
            props?.lineColor ?? elphiTheme.components.light.treeLayout.lineColor
          }
          lineBorderRadius={props.lineBorderRadius ?? "10px"}
          label={
            <Box>
              <ScaleChart
                currectScaleFactor={currectScaleFactor}
                scaleUp={scaleUp}
                isScaleUpDisabled={isScaleUpDisabled}
                scaleDown={scaleDown}
                isScaleDownDisabled={isScaleDownDisabled}
              />
            </Box>
          }
        >
          {treeNodes}
        </Tree>
      </Box>
    </Box>
  );
};

export default GenericTree;
