import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { FieldType, RolodexConfiguration } from "@elphi/types";
import { useEffect } from "react";
import elphiTheme from "../../../../../../assets/themes/elphi.theme.default";
import { EMPTY } from "../../../../../../constants/common";
import { useRolodexConfiguration } from "../../../../../../hooks/rolodexConfiguration.hooks";
import FormBuilder from "../../../../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../../../../form-builder/InputBuilder";
import ModalContainer from "../../../../../modal-container/ModalContainer";

export const ConfigurationModal = (props: {
  isShow: boolean;
  onClose: () => void;
}) => {
  const { onChange, state } = useFormBuilderStateHandler<
    Pick<RolodexConfiguration, "name">
  >({
    initialState: {
      name: EMPTY
    }
  });

  useEffect(() => {
    if (!props.isShow) {
      onChange({
        value: EMPTY,
        fieldType: FieldType.String,
        fieldKey: ["name"]
      });
    }
  }, [props.isShow]);

  const {
    createConfiguration,
    cloneConfiguration,
    createResponse,
    selectedConfiguration
  } = useRolodexConfiguration();

  return (
    <ModalContainer
      isShow={props.isShow}
      onCloseModal={props.onClose}
      header={"Rolodex Configuration"}
      body={
        <>
          {selectedConfiguration && (
            <Text>Cloning: {selectedConfiguration.name}</Text>
          )}
          <FormBuilder
            customKey="createRolodexConfigurationForm"
            elphiView="form"
            onChange={onChange}
            sections={[
              {
                inputs: [
                  {
                    label: "name",
                    labelPosition: "up",
                    fieldType: FieldType.String,
                    fieldKey: ["name"],
                    currentValue: state.name,
                    isValid: !!state.name
                  }
                ]
              }
            ]}
          />
        </>
      }
      footer={
        <>
          <Flex>
            <Box p="5px">
              <Button
                isLoading={!!selectedConfiguration && createResponse.isLoading}
                onClick={() =>
                  cloneConfiguration({ ...state }).finally(() => {
                    props.onClose();
                  })
                }
                isDisabled={!selectedConfiguration || !state.name}
                float="right"
                {...elphiTheme.components.light.button.secondary}
              >
                Clone
              </Button>
            </Box>
            <Box p="5px">
              <Button
                isLoading={!selectedConfiguration && createResponse.isLoading}
                onClick={() =>
                  createConfiguration({ ...state }).finally(() => {
                    props.onClose();
                  })
                }
                isDisabled={!!selectedConfiguration || !state.name}
                float="right"
                {...elphiTheme.components.light.button.primary}
              >
                Submit New
              </Button>
            </Box>
          </Flex>
        </>
      }
    />
  );
};
