import {
  Asset,
  BaseEntity,
  BaseSnapshot,
  Deal,
  DealPartyRelation,
  DealProperty,
  DealUser,
  ElphiSnapshotEntity,
  FirebaseCollections,
  LOSUser,
  Party,
  PartyAsset,
  PartyRelation,
  Property,
  Statement,
  Task
} from "@elphi/types";
import { CreditScore } from "@elphi/types/entities/creditReport.types";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { snapshotApi, snapshotSlice } from "../redux/v2/snapshot";

export const formatSnapshot = <T,>(r: {
  snapshot: ElphiSnapshotEntity["snapshot"];
  collection: FirebaseCollections;
}) => {
  const collectionSnapshot:
    | BaseSnapshot<FirebaseCollections, BaseEntity<object>>
    | undefined = r.snapshot?.[r.collection];
  if (!collectionSnapshot) return undefined;
  const ids = Object.keys(collectionSnapshot.documents);
  return {
    ids,
    entities: collectionSnapshot.documents
  } as T;
};
export type SnapshotDataStateFormat<T> = {
  ids: string[];
  entities: {
    [id: string]: T;
  };
};

const cachedSnapshotIds: {
  [snapshotId: string]: {
    partyState?: ReturnType<
      typeof formatSnapshot<SnapshotDataStateFormat<Party>>
    >;
    propertyState?: ReturnType<
      typeof formatSnapshot<SnapshotDataStateFormat<Property>>
    >;
    dealState?: ReturnType<
      typeof formatSnapshot<SnapshotDataStateFormat<Deal>>
    >;
    dealPropertyState?: ReturnType<
      typeof formatSnapshot<SnapshotDataStateFormat<DealProperty>>
    >;
    dealPartyRelationState?: ReturnType<
      typeof formatSnapshot<SnapshotDataStateFormat<DealPartyRelation>>
    >;
    partyRelationState?: ReturnType<
      typeof formatSnapshot<SnapshotDataStateFormat<PartyRelation>>
    >;
    losUserState?: ReturnType<
      typeof formatSnapshot<SnapshotDataStateFormat<LOSUser>>
    >;
    dealUserState?: ReturnType<
      typeof formatSnapshot<SnapshotDataStateFormat<DealUser>>
    >;
    assetState?: ReturnType<
      typeof formatSnapshot<SnapshotDataStateFormat<Asset>>
    >;
    partyAssetState?: ReturnType<
      typeof formatSnapshot<SnapshotDataStateFormat<PartyAsset>>
    >;
    statementState?: ReturnType<
      typeof formatSnapshot<SnapshotDataStateFormat<Statement>>
    >;
    taskState?: ReturnType<
      typeof formatSnapshot<SnapshotDataStateFormat<Task>>
    >;
    creditReportState?: ReturnType<
      typeof formatSnapshot<SnapshotDataStateFormat<CreditScore>>
    >;
  };
} = {};
export const useSnapshotHooks = () => {
  const dispatcher = useDispatch();
  const snapshotState = useSelector((state: RootState) => state.snapshot);
  const [getSnapshotData, getSnapshotDataResponse] =
    snapshotApi.useLazyGetSnapshotQuery();
  const [getSnapshot, getSnapshotResponse] = snapshotApi.useLazyGetQuery();

  const setSelectedSnapshot = (id: string) =>
    dispatcher(snapshotSlice.actions.selectedId({ id }));
  const selectedSnapshot = useSelector((state: RootState) => {
    return state.snapshot.selectedId
      ? state.snapshot.entities[state.snapshot.selectedId]
      : undefined;
  });

  //TODO: check if callbackRef will provide better performance

  const snapshotDataState = useCallback(
    (r: { snapshotId: string }) => {
      const snapshotData = snapshotState.entities[r.snapshotId]?.snapshot;
      if (!snapshotData) return {};
      if (r.snapshotId in cachedSnapshotIds) {
        return cachedSnapshotIds[r.snapshotId];
      }
      cachedSnapshotIds[r.snapshotId] = {
        partyState: formatSnapshot<SnapshotDataStateFormat<Party>>({
          snapshot: snapshotData,
          collection: FirebaseCollections.Party
        }),
        propertyState: formatSnapshot<SnapshotDataStateFormat<Property>>({
          snapshot: snapshotData,
          collection: FirebaseCollections.Property
        }),
        dealState: formatSnapshot<SnapshotDataStateFormat<Deal>>({
          snapshot: snapshotData,
          collection: FirebaseCollections.Deal
        }),
        dealPropertyState: formatSnapshot<
          SnapshotDataStateFormat<DealProperty>
        >({
          snapshot: snapshotData,
          collection: FirebaseCollections.DealProperty
        }),
        dealPartyRelationState: formatSnapshot<
          SnapshotDataStateFormat<DealPartyRelation>
        >({
          snapshot: snapshotData,
          collection: FirebaseCollections.DealPartyRelation
        }),
        partyRelationState: formatSnapshot<
          SnapshotDataStateFormat<PartyRelation>
        >({
          snapshot: snapshotData,
          collection: FirebaseCollections.PartyRelation
        }),
        losUserState: formatSnapshot<SnapshotDataStateFormat<LOSUser>>({
          snapshot: snapshotData,
          collection: FirebaseCollections.LOSUser
        }),
        dealUserState: formatSnapshot<SnapshotDataStateFormat<DealUser>>({
          snapshot: snapshotData,
          collection: FirebaseCollections.DealUser
        }),
        assetState: formatSnapshot<SnapshotDataStateFormat<Asset>>({
          snapshot: snapshotData,
          collection: FirebaseCollections.Asset
        }),
        partyAssetState: formatSnapshot<SnapshotDataStateFormat<PartyAsset>>({
          snapshot: snapshotData,
          collection: FirebaseCollections.PartyAsset
        }),
        statementState: formatSnapshot<SnapshotDataStateFormat<Statement>>({
          snapshot: snapshotData,
          collection: FirebaseCollections.Statement
        }),
        taskState: formatSnapshot<SnapshotDataStateFormat<Task>>({
          snapshot: snapshotData,
          collection: FirebaseCollections.Task
        }),
        creditReportState: formatSnapshot<SnapshotDataStateFormat<CreditScore>>(
          {
            snapshot: snapshotData,
            collection: FirebaseCollections.CreditScore
          }
        )
      };
      return cachedSnapshotIds[r.snapshotId];
    },
    [snapshotState]
  );
  const selectedSnapshotDataState = useMemo(() => {
    return (
      (selectedSnapshot &&
        selectedSnapshot.id &&
        snapshotDataState({ snapshotId: selectedSnapshot.id })) ||
      undefined
    );
  }, [selectedSnapshot?.snapshot]);
  return {
    selectedSnapshotDataState,
    snapshotDataState,
    selectedSnapshot,
    setSelectedSnapshot,
    snapshotState,
    getSnapshot,
    getSnapshotResponse,
    getSnapshotData,
    getSnapshotDataResponse
  };
};
