import { Avatar } from "@chakra-ui/react";
import { FeatureFlag } from "@elphi/utils";
import { connect } from "react-redux";
import elphi from "../../../assets/img/elphi/elphi.png";
import {
  BookPenIcon,
  BuildingIcon,
  DocumentIcon,
  FilterIcon,
  HomeIcon,
  PeopleIcon,
  SettingsIcon,
  ToolsIcon,
  UsersIcon
} from "../../../components/icons/";
import { EMPTY } from "../../../constants/common";
import { GateKeeper } from "../../../features/gate/GateKeeper";
import { PLATFORM_ORG_ID, useOrgHooks } from "../../../hooks/org.hooks";
import { resetUserData, updateUserData } from "../../../redux/actions";
import PathVariables from "../../../routes/pathVariables";
import { SidebarProps } from "../../../shared";
import { isCurrentLocation } from "../../../utils/route.utils";
import SidebarIcon from "./SidebarIcon";
import {
  Image,
  LogoContainer,
  NavItemsContainer,
  SidebarContainer,
  UserIcon
} from "./sidebar-styles";
const Sidebar = (props: SidebarProps) => {
  const userRolesId = props.userData?.roles?.map((v) => v.value);

  const isPlatformAdmin = userRolesId?.includes?.("platform_admin");
  const isOrgAdmin = userRolesId?.includes?.("organization_admin");
  const isAdmin = userRolesId?.includes?.("admin");
  const isAnyAdmin = isPlatformAdmin || isOrgAdmin || isAdmin;
  const { isPlatformOrg } = useOrgHooks();

  return (
    <SidebarContainer>
      <LogoContainer>
        <Image src={elphi} alt="team2" />
      </LogoContainer>
      <NavItemsContainer>
        <SidebarIcon
          userData={props.userData}
          icon={<HomeIcon />}
          id="home"
          name="Home"
          pathName={PathVariables.LoanPipeline}
          disabled={isPlatformOrg}
        />
        <SidebarIcon
          userData={props.userData}
          icon={<UsersIcon />}
          id="partiesPage"
          name="Parties Pipeline"
          pathName={PathVariables.PartiesPipeline}
          disabled={isPlatformOrg}
        />
        <SidebarIcon
          userData={props.userData}
          icon={<FilterIcon />}
          id="propertiesPage"
          name="Properties Pipeline"
          pathName={PathVariables.PropertyPipeline}
          disabled={isPlatformOrg}
        />
        {/* <SidebarIcon
          userData={props.userData}
          icon={<BookIcon />}
          id="rolodexPage"
          name="Rolodex"
          pathName={""}
          disabled={isPlatformOrg}
        /> */}
      </NavItemsContainer>
      <NavItemsContainer>
        <SidebarIcon
          userData={props.userData}
          icon={<SettingsIcon />}
          id="taskManagement"
          name="Task Management"
          pathName={PathVariables.TaskManagement}
          disabled={!isAnyAdmin}
        />
        <SidebarIcon
          userData={props.userData}
          icon={<DocumentIcon />}
          id="documentManagement"
          name="Document Management"
          pathName={PathVariables.DocumentManagement}
          disabled={!isAnyAdmin}
        />
        {/* <SidebarIcon
          userData={props.userData}
          icon={<BellPenIcon />}
          id="notificationsManagementPage"
          name="Notifications Management"
          pathName={""}
          disabled={isPlatformOrg}
        /> */}
        {/* <SidebarIcon
          userData={props.userData}
          icon={<LightningIcon />}
          id="automationWizardPage"
          name="Automation Wizard"
          pathName={""}
          disabled={isPlatformOrg}
        /> */}
        <GateKeeper gate={FeatureFlag.ESD_2788_Rolodex_Management}>
          <SidebarIcon
            userData={props.userData}
            icon={<BookPenIcon />}
            id="RolodexManagement"
            name="Rolodex Management"
            pathName={PathVariables.RolodexManagement}
            disabled={!isAnyAdmin}
          />
        </GateKeeper>
        <SidebarIcon
          userData={props.userData}
          icon={<ToolsIcon />}
          id="organizationManagementPage"
          name="Organization Management"
          pathName={PathVariables.OrganizationManagement}
          disabled={isPlatformOrg}
        />
      </NavItemsContainer>

      <NavItemsContainer>
        <SidebarIcon
          userData={props.userData}
          icon={<PeopleIcon />}
          id="userConfig"
          name="User Management"
          pathName={PathVariables.UserManagement}
          disabled={!isAdmin}
        />
      </NavItemsContainer>
      <NavItemsContainer>
        <SidebarIcon
          userData={props.userData}
          icon={<BuildingIcon />}
          id="orgManagement"
          name="Platform Management"
          pathName={PathVariables.PlatformManagement}
          disabled={!isPlatformAdmin}
          target={!isCurrentLocation("PlatformManagement") ? "_blank" : EMPTY}
          customOrgId={PLATFORM_ORG_ID}
        />
      </NavItemsContainer>

      <UserIcon onClick={props.signOutUser}>
        <Avatar
          bg="rgb(246, 224, 255)"
          color="rgb(160, 21, 215)"
          name={props.userData.name}
        />
      </UserIcon>
    </SidebarContainer>
  );
};

const mapStateToProps = (state) => ({
  userData: state.userData
});

const mapDispatchToProps = (dispatch) => ({
  updateUserData: (userData) => {
    dispatch(updateUserData(userData));
  },
  resetUserData: () => {
    dispatch(resetUserData());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
