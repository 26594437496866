import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { FieldType, LOSUser } from "@elphi/types";
import { useEffect, useState } from "react";
import { useAdminHooks } from "../../../hooks/admin.hooks";
import { useLOSUserHooks } from "../../../hooks/losuser.hooks";
import { fromFirebaseIdToLabel } from "../../../utils/common";
import FormBuilder from "../../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../../form-builder/InputBuilder";
import { useElphiToast } from "../../toast/toast.hook";
import RoleSearch from "../role-permission/RoleSearch";

const mapRole = (role: string): { label: string; value: string } => {
  return {
    label: fromFirebaseIdToLabel(role),
    value: role
  };
};

export const addUsersHook = () => {
  const { successToast, errorToast } = useElphiToast();
  const { setSelectedLOSUser } = useLOSUserHooks();
  const { addUsersHandler } = useAdminHooks();

  const onSubmit = (
    state: Partial<Omit<LOSUser, "roles"> & { roles: string[] }>
  ) => {
    const userToCreate: Partial<LOSUser> = {
      ...state,
      roles: state?.roles?.map(mapRole) || []
    };

    addUsersHandler({
      users: [userToCreate as LOSUser]
    }).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "New user added",
          description: `${state.firstName} ${state.lastName}`
        });
        setSelectedLOSUser(r.data.users[0]);
      }

      r.status === 400 &&
        errorToast({
          title: `Failed to add new user ${state.firstName} ${state.lastName}`,
          description: JSON.stringify(r.data.error)
        });
    });
  };
  return {
    onSubmit
  };
};
export const updateUsersHook = () => {
  const { successToast, errorToast } = useElphiToast();
  const { setSelectedLOSUser, updateLOSUser } = useLOSUserHooks();

  const onSubmit = (
    state: Partial<Omit<LOSUser, "roles"> & { roles: string[] }>
  ) => {
    const userToUpdate: Partial<LOSUser> = {
      ...state,
      roles: state?.roles?.map(mapRole) || []
    };
    updateLOSUser(userToUpdate as LOSUser).then((r) => {
      if (r.status === 200) {
        successToast({
          title: "user updated ",
          description: `${state.firstName} ${state.lastName}`
        });
        state?.id && setSelectedLOSUser(state.id);
      }

      r.status === 400 &&
        errorToast({
          title: `Failed to update user ${state.firstName} ${state.lastName}`,
          description: r.description || "no description, something went wrong"
        });
    });
  };
  return {
    onSubmit
  };
};

export const UserForm = (props: {
  onSubmit: (v: Partial<Omit<LOSUser, "roles"> & { roles: string[] }>) => void;
  initialState?: Partial<Omit<LOSUser, "roles"> & { roles: string[] }>;
  specs?: {
    email?: {
      isReadOnly?: boolean;
    };
  };
}) => {
  const { state, onChange, syncState } = useFormBuilderStateHandler({
    initialState:
      props.initialState || ({} as Omit<LOSUser, "roles"> & { roles: string[] })
  });

  useEffect(() => {
    syncState({
      shouldSync: !!props.initialState,
      state: props.initialState
    });
  }, [props.initialState]);
  const isValid =
    state &&
    state.firstName &&
    state.lastName &&
    state.email &&
    state.roles &&
    state.roles?.length > 0;

  console.log("state: ", state);
  return (
    <Box w={"100%"}>
      <FormBuilder
        customKey="userPageForm"
        elphiView="form"
        onChange={onChange}
        sections={[
          {
            inputs: [
              {
                label: "First Name",
                currentValue: state.firstName,
                fieldKey: ["firstName"],
                fieldType: FieldType.String,
                labelPosition: "up",
                isValid: !!state.firstName
              },
              {
                label: "Middle Name",
                currentValue: state.middleName,
                fieldKey: ["middleName"],
                fieldType: FieldType.String,
                labelPosition: "up",
                isValid: !!state.middleName
              },
              {
                label: "Last Name",
                currentValue: state.lastName,
                fieldKey: ["lastName"],
                fieldType: FieldType.String,
                labelPosition: "up",
                isValid: !!state.lastName
              },
              {
                label: "Email",
                currentValue: state.email,
                fieldKey: ["email"],
                fieldType: FieldType.String,
                labelPosition: "up",
                isValid: !!state.email,
                isReadOnly: props.specs?.email?.isReadOnly
              },
              {
                label: "Title",
                currentValue: state.title,
                fieldKey: ["title"],
                fieldType: FieldType.String,
                labelPosition: "up"
              }
            ]
          }
        ]}
        size={{
          minW: "300px"
        }}
      />
      <RoleSearch
        label="Roles"
        labelPosition="up"
        currentValue={state.roles || []}
        fieldType={FieldType.MultiSelect}
        onSelect={(v: string[]) => {
          onChange({
            fieldKey: ["roles"],
            fieldType: FieldType.Array,
            value: v
          });
        }}
      />
      <Button
        isDisabled={!isValid}
        onClick={() => {
          props.onSubmit(state);
        }}
      >
        Submit
      </Button>
    </Box>
  );
};

export const CreateUserPage = () => {
  const { onSubmit } = addUsersHook();

  return <UserForm onSubmit={onSubmit} />;
};
export const UpdateUserPage = (props: {
  user: Partial<LOSUser> & { id: string };
}) => {
  const { onSubmit } = updateUsersHook();
  const [formUser, setFormUser] = useState<
    Partial<Omit<LOSUser, "roles"> & { roles: string[] }>
  >({
    ...props.user,
    roles: props?.user?.roles?.map((v) => v.value) || []
  });

  useEffect(() => {
    setFormUser({
      ...props.user,
      roles: props?.user?.roles?.map((v) => v.value) || []
    });
  }, [props.user]);
  return (
    <UserForm
      initialState={formUser}
      onSubmit={onSubmit}
      specs={{
        email: {
          isReadOnly: true
        }
      }}
    />
  );
};
export const OperationUserModal = (props: {
  isOpen: boolean;
  onClose: () => void;
  label: string;
  component: JSX.Element;
}) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay>
        <ModalContent w="xl">
          <ModalCloseButton />
          <ModalHeader>{props.label}</ModalHeader>
          <ModalBody>
            <Box>{props.component}</Box>
          </ModalBody>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
export const UpdateUserModal = (props: {
  isOpen: boolean;
  onClose: () => void;
  user: Partial<LOSUser> & { id: string };
}) => {
  return (
    <OperationUserModal
      {...props}
      label="Update User"
      component={
        <Box>
          <UpdateUserPage user={props.user} />
        </Box>
      }
    />
  );
};

export const CreateUserModal = (props: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <OperationUserModal
      {...props}
      label="Create User"
      component={
        <Box>
          <CreateUserPage />
        </Box>
      }
    />
  );
};
