import { Box, Flex } from "@chakra-ui/react";
import { FieldType } from "@elphi/types";
import { Dictionary } from "@reduxjs/toolkit";

import { debounce } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import { AppConfig } from "../../config/appConfig";
import SearchComponent, { SearchComponentProps } from "../SearchComponent";
import { querySanitizer } from "../utils/sanitize.utils";

export type SearchApiVersion<T extends "v2" | "v1" | undefined> = {
  version: T;
};

export type SearchApiV2 = SearchApiVersion<"v2"> & {
  searchApi: (
    r: { query: string; cursor?: string | null },
    cache: boolean
  ) => any;
};
export type SearchApiV1 = SearchApiVersion<"v1" | undefined> & {
  version?: "v1";
  searchApi: (query: string, cache: boolean) => any;
};

export const SearchHandler = <T,>(
  props:
    | (SearchApiV2 | SearchApiV1) & {
        buildOption: (a: T) => {
          value: any;
          label: string;
          isDisabled?: boolean;
        };
        filter?: (a: T) => boolean;
        searchResponse: { isFetching: boolean; isLoading: boolean };
        rankedSort: (q: string) => T[];
        state: {
          entities: Dictionary<T>;
          searchCursor?: {
            query: {
              [q: string]: { hasMore: boolean; nextCursor?: string | null };
            };
          };
        };
      } & Pick<
          SearchComponentProps,
          | "fetchMore"
          | "hasMore"
          | "onSelect"
          | "currentValue"
          | "label"
          | "labelPosition"
          | "isReadOnly"
          | "isDisabled"
          | "hideSelectedOptions"
          | "customComponent"
          | "filterOption"
        > &
        Pick<Partial<SearchComponentProps>, "fieldType">
) => {
  const { searchResponse, rankedSort, buildOption } = props;
  const [query, setQuery] = useState("");

  const debounceSearch = debounce((query: string, cursor?: string | null) => {
    if (props.version === "v2") {
      !!query && query > "" && props.searchApi({ query, cursor }, true);
    } else if (!props.version || props.version === "v1") {
      !!query && query > "" && props.searchApi(query, true);
    }
  }, AppConfig.search.debounceRate);

  const debounceRef = useRef(debounceSearch);
  const options = useMemo(() => {
    const newOptions = rankedSort(query).filter((p) => !!p);
    const propsFilter = props.filter
      ? newOptions.filter(props.filter)
      : newOptions;

    const newOptionsFiltered = propsFilter.map((p) => buildOption(p!));
    //kinda of a hack to get the basic filter count of react-select
    const optionsMatchFilterLength = newOptionsFiltered.filter((i) => {
      return i.value ? i.value.match(query) : false;
    }).length;
    if (optionsMatchFilterLength < 10) {
      debounceRef.current(query);
    }
    return newOptionsFiltered;
  }, [props.state.entities, query, props.filter, props.state.searchCursor]);

  const onInputChangeCallback = useCallback((newInput: string) => {
    if (newInput && newInput > "") {
      setQuery(querySanitizer(newInput));
    }
  }, []);

  return (
    <Box w={"100%"}>
      <Flex w={"100%"}>
        <Box w="100%">
          <SearchComponent
            query={query}
            fieldType={props.fieldType || FieldType.SingleSelect}
            options={options}
            onInputChange={onInputChangeCallback}
            currentValue={props.currentValue || ""}
            onSelect={props.onSelect}
            filterOption={props?.filterOption}
            hasMore={
              props.hasMore ||
              props.state.searchCursor?.query[query]?.hasMore ||
              options.length !== 0
            }
            fetchMore={() =>
              props.fetchMore
                ? props.fetchMore()
                : debounceRef.current(
                    query,
                    props.state.searchCursor?.query[query]?.nextCursor
                  )
            }
            isLoading={searchResponse.isFetching || searchResponse.isLoading}
            label={props.label}
            labelPosition={props.labelPosition}
            isReadOnly={props.isReadOnly}
            isDisabled={props.isDisabled}
            customComponent={props.customComponent}
            hideSelectedOptions={props.hideSelectedOptions}
          />
        </Box>
      </Flex>
    </Box>
  );
};
SearchHandler.defaultProps = {
  version: "v1"
};
