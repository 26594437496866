import { AggregationType, Deal } from "@elphi/types";
import { OnChangeInput, Section } from "../../form-builder/FormBuilder";
import { dealFieldSpecs } from "../../form-builder/field-specs/deal/deal.fields";
import { buildInputs } from "../../form-builder/formBuilder.utils";
import {
  booleanValidation,
  dateValidation,
  emailValidation,
  moneyValidation,
  numberValidation,
  percentValidation,
  singleSelectValidation,
  stringValidation
} from "../../utils/validationUtils";
import {
  amortizationTypeFieldPath,
  armFinalAdjustableRateTermMonthCountFieldPath,
  armInitialFixedTermMonthCountFieldPath,
  armRepriceFrequencyTypeAggregationFieldPath,
  armRepriceLookbackTypeFieldPath,
  armRepriceRoundingMethodTypeFieldPath,
  armRepriceRoundingRatePercentTypeFieldPath,
  buyDownFeeAmountFieldPath,
  capitalStructureTypeFieldPath,
  chargentCollectionAmountFieldPath,
  constructionBudgetVerifiedMatchIndicatorFieldPath,
  defaultInterestRatePercentFieldPath,
  deferredMaintenanceAmountFieldPath,
  deferredOriginationFeeAmountFieldPath,
  downPaymentAmountFieldPath,
  eoExpirationDateFieldPath,
  eoNamedInsuredMatchIndicatorFieldPath,
  estimatedClosingDateFieldPath,
  executionTypeFieldPath,
  firstRateChangeDateFieldPath,
  floodInsuranceAgencyFullNameFieldPath,
  floodInsurancePremiumPaymentTypeFieldPath,
  gracePeriodDaysFieldPath,
  grossSpreadPercentFieldPath,
  indexSourceTypeFieldPath,
  initialMonthlyInterestOnlyPaymentAmountFieldPath,
  interestAccrualMethodTypeFieldPath,
  interestReserveDepositAmountFieldPath,
  lateFeePercentFieldPath,
  ledgerBrokerOriginationFeeAmountFieldPath,
  ledgerBrokerProcessingFeeAmountFieldPath,
  ledgerBuyUpFeeReductionAmountFieldPath,
  ledgerCondoCertificationFeeAmountFieldPath,
  ledgerContractorReviewFeeAmountFieldPath,
  ledgerCreditReportFeeAmountFieldPath,
  ledgerFeasibilityReviewFeeAmountFieldPath,
  ledgerLenderCounselFeeAmountFieldPath,
  ledgerNewYorkProcessingFeeAmountFieldPath,
  ledgerOriginationFeeAmountFieldPath,
  ledgerProcessingFeeAmountFieldPath,
  ledgerTotalBrokerFeeAmountFieldPath,
  lenderIdentifierFieldPath,
  letterOfIntentSignedDateFieldPath,
  loanAfterInterestOnlyPeriodMonthCountFieldPath,
  loanAmortizationPeriodMonthCountFieldPath,
  loanAmountVerifiedMatchIndicatorFieldPath,
  loanInterestOnlyPeriodMonthCountFieldPath,
  loanMaturityDateFieldPath,
  loanPaymentTypeFieldPath,
  loanProductTypeFieldPath,
  loanTermPeriodMonthCountFieldPath,
  monthlyHomeownersAssociationAmountFieldPath,
  monthlyPrincipalInterestPaymentAmountFieldPath,
  monthlyTaxInsurancePaymentAmountFieldPath,
  noteRatePercentFieldPath,
  paymentDueDayFieldPath,
  prepaidInterestAmountFieldPath,
  prepaymentPenaltyExpirationDateFieldPath,
  prepaymentPenaltyTypeFieldPath,
  propertyInsuranceAgencyFullNameFieldPath,
  propertyInsurancePremiumPaymentTypeFieldPath,
  recourseTypeFieldPath,
  releasePercentFieldPath,
  scheduledFirstPaymentDateFieldPath,
  settlementStatementCommentsFieldPath,
  settlementStatementMatchLedgerIndicatorFieldPath,
  titleCommitmentAllPayoffsLiensIncludedInSection1RequirementsIndicatorFieldPath,
  titleCommitmentAllSubjectPropertiesIncludedIndicatorFieldPath,
  titleCommitmentCommentsFieldPath,
  titleCommitmentLeaseholdEndorsementIndicatorFieldPath,
  titleCommitmentOwnershipFieldPath,
  titleCommitmentRequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicatorFieldPath,
  titleCommitmentTitleEffectiveDateFieldPath,
  titleCommitmentTitleExpirationDateFieldPath,
  titleCommitmentTitleInternationalExecutionIndicatorFieldPath,
  titleCommitmentTitlePolicyAmountFieldPath,
  titleCommitmentTitleVestingTypeFieldPath,
  titleCompanyAddressLineTextFieldPath,
  titleCompanyAddressUnitIdentifierFieldPath,
  titleCompanyCityNameFieldPath,
  titleCompanyClosingAgentContactPointEmailValueFieldPath,
  titleCompanyClosingAgentContactPointTelephoneValueFieldPath,
  titleCompanyClosingAgentFirstNameFieldPath,
  titleCompanyClosingAgentLastNameFieldPath,
  titleCompanyFullNameFieldPath,
  titleCompanyPostalCodeFieldPath,
  titleCompanyStateCodeFieldPath,
  titleFormsCommentsFieldPath,
  titleInsuranceUnderwriterFieldPath,
  totalBudgetAmountFieldPath,
  totalInitialInsuranceEscrowAmountFieldPath,
  totalInitialTaxEscrowAmountFieldPath,
  totalLiquidAssetAmountFieldPath,
  totalMonthlyPITIPaymentAmountFieldPath,
  totalOutstandingLoanPayoffAmountFieldPath,
  totalPurchasePriceAmountFieldPath,
  uccExpirationDateFieldPath,
  uccFiledIndicatorFieldPath,
  wireABARoutingNumberFieldPath,
  wireAccountNameFieldPath,
  wireAccountNumberFieldPath,
  wireBankNameFieldPath,
  wireReleaseDateFieldPath
} from "../field-paths/closing.fieldPaths";
import {
  cashFromBorrowerAmountFieldPath,
  cashToBorrowerAmountFieldPath,
  floorPercentFieldPath,
  fullyDrawnInterestOnlyPaymentFieldPath,
  interestReserveEscrowFieldPath,
  lenderFinanceApplicationFeeFieldPath,
  lenderFinanceAppraisalFeeFieldPath,
  lenderFinanceDocumentFeeFieldPath,
  lenderFinanceProcessingFeeFieldPath,
  lifetimeCapPercentFieldPath,
  marginFieldPath,
  marketingPromotionCodeFieldPath,
  marketingPromotionCreditAmountFieldPath,
  rateLockEndDateFieldPath,
  rateLockStartDateFieldPath,
  servicingSetupFeeFieldPath,
  totalFloodInsurancePremiumOutstandingAmountFieldPath,
  totalPropertyInsurancePremiumOutstandingAmountFieldPath
} from "../field-paths/deal.fieldPaths";
import { hideFixNFlip, hideRental30 } from "./sections.utils";

export const titleCompanySection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Title Company",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: titleInsuranceUnderwriterFieldPath,
          validation: stringValidation
        },
        { path: titleCompanyFullNameFieldPath, validation: stringValidation },
        {
          path: titleCompanyClosingAgentFirstNameFieldPath,
          validation: stringValidation
        },
        {
          path: titleCompanyClosingAgentLastNameFieldPath,
          validation: stringValidation
        },
        {
          path: titleCompanyClosingAgentContactPointTelephoneValueFieldPath,
          validation: stringValidation
        },
        {
          path: titleCompanyClosingAgentContactPointEmailValueFieldPath,
          validation: emailValidation
        },
        {
          path: titleCompanyAddressLineTextFieldPath,
          validation: stringValidation
        },
        {
          path: titleCompanyAddressUnitIdentifierFieldPath,
          validation: stringValidation
        },
        { path: titleCompanyCityNameFieldPath, validation: stringValidation },
        {
          path: titleCompanyStateCodeFieldPath,
          validation: singleSelectValidation
        },
        { path: titleCompanyPostalCodeFieldPath, validation: stringValidation }
      ]
    })
  };
};

export const loanTermsSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Loan Terms",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          isAggregation: AggregationType.ThirdParty,
          path: loanProductTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: !hideFixNFlip(state),
          isReadOnly:
            state.aggregations?.LoanProductType?.focused !== "override"
        },
        { path: amortizationTypeFieldPath, validation: singleSelectValidation },
        {
          isAggregation: AggregationType.ThirdParty,
          path: noteRatePercentFieldPath,
          isReadOnly:
            state.quote?.aggregations?.NoteRatePercent?.focused !== "override",
          validation: percentValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state.aggregations?.EstimatedClosingDate?.focused !== "override",
          path: estimatedClosingDateFieldPath,
          validation: dateValidation
        },
        { path: rateLockStartDateFieldPath, validation: dateValidation },
        { path: rateLockEndDateFieldPath, validation: dateValidation },
        { path: wireReleaseDateFieldPath, validation: dateValidation },
        {
          isAggregation: AggregationType.Aggregation,
          path: scheduledFirstPaymentDateFieldPath,
          isReadOnly:
            state.aggregations?.ScheduledFirstPaymentDate?.focused !==
            "override",
          validation: dateValidation
        },
        { path: paymentDueDayFieldPath, validation: singleSelectValidation },
        {
          isAggregation: AggregationType.Aggregation,
          path: loanMaturityDateFieldPath,
          validation: dateValidation,
          isReadOnly:
            state.aggregations?.LoanMaturityDate?.focused !== "override"
        },
        {
          path: loanTermPeriodMonthCountFieldPath,
          validation: numberValidation
        },
        {
          path: loanAmortizationPeriodMonthCountFieldPath,
          validation: numberValidation,
          isHidden: hideRental30(state)
        },
        {
          path: loanInterestOnlyPeriodMonthCountFieldPath,
          validation: numberValidation,
          isHidden: hideRental30(state)
        },
        {
          path: loanAfterInterestOnlyPeriodMonthCountFieldPath,
          validation: numberValidation,
          isHidden: hideRental30(state)
        },
        {
          path: interestAccrualMethodTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          path: loanPaymentTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.TotalMonthlyPITIPaymentAmount
              ?.focused !== "override",
          path: totalMonthlyPITIPaymentAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.MonthlyPrincipalInterestPaymentAmount
              ?.focused !== "override",
          path: monthlyPrincipalInterestPaymentAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.InitialMonthlyInterestOnlyPaymentAmount
              ?.focused !== "override",
          path: initialMonthlyInterestOnlyPaymentAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.FullyDrawnInterestOnlyPayment
              ?.focused !== "override",
          path: fullyDrawnInterestOnlyPaymentFieldPath,
          validation: moneyValidation,
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.MonthlyTaxInsurancePaymentAmount
              ?.focused !== "override",
          path: monthlyTaxInsurancePaymentAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.MonthlyHomeownersAssociationAmount
              ?.focused !== "override",
          path: monthlyHomeownersAssociationAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideRental30(state)
        },
        {
          path: executionTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.PrepaymentPenaltyType?.focused !==
            "override",
          path: prepaymentPenaltyTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          path: prepaymentPenaltyExpirationDateFieldPath,
          validation: dateValidation,
          isReadOnly:
            state.aggregations?.PrepaymentPenaltyExpirationDate?.focused !==
            "override",
          isHidden: hideRental30(state)
        },
        { path: lenderIdentifierFieldPath, validation: singleSelectValidation },
        {
          path: recourseTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideFixNFlip(state)
        }
      ]
    })
  };
};

export const loanTermsARMSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    isHidden: hideRental30(state),
    header: "Loan Terms - ARM",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          isAggregation: AggregationType.Aggregation,
          path: firstRateChangeDateFieldPath,
          validation: dateValidation,
          isReadOnly:
            state.aggregations?.FirstRateChangeDate?.focused !== "override",
          isHidden: hideRental30(state)
        },
        {
          path: indexSourceTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          path: capitalStructureTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          path: armRepriceRoundingRatePercentTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          path: armRepriceRoundingMethodTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          path: armRepriceLookbackTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          path: armInitialFixedTermMonthCountFieldPath,
          validation: numberValidation,
          isReadOnly:
            state.aggregations?.ARMInitialFixedTermMonthCount?.focused !==
            "override",
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          path: armFinalAdjustableRateTermMonthCountFieldPath,
          isReadOnly:
            state.aggregations?.ARMFinalAdjustableRateTermMonthCount
              ?.focused !== "override",
          validation: numberValidation,
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          path: armRepriceFrequencyTypeAggregationFieldPath,
          validation: singleSelectValidation,
          isReadOnly:
            state.aggregations?.ARMRepriceFrequencyType?.focused !== "override",
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: marginFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state),
          isReadOnly: state?.quote?.aggregations?.Margin?.focused !== "override"
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: floorPercentFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state),
          isReadOnly: state?.quote?.aggregations?.Margin?.focused !== "override"
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: lifetimeCapPercentFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state),
          isReadOnly:
            state?.quote?.aggregations?.LifetimeCapPercent?.focused !==
            "override"
        }
      ]
    })
  };
};

export const loanTermsCustomSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Loan Terms - Custom",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        { path: uccFiledIndicatorFieldPath, validation: booleanValidation },
        { path: uccExpirationDateFieldPath, validation: dateValidation },
        {
          path: defaultInterestRatePercentFieldPath,
          validation: percentValidation
        },
        { path: lateFeePercentFieldPath, validation: percentValidation },
        { path: releasePercentFieldPath, validation: percentValidation },
        { path: grossSpreadPercentFieldPath, validation: percentValidation },
        { path: gracePeriodDaysFieldPath, validation: numberValidation },
        { path: letterOfIntentSignedDateFieldPath, validation: dateValidation }
      ]
    })
  };
};

export const ledgerSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Ledger",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalOutstandingLoanPayoffAmount?.focused !==
            "override",
          path: totalOutstandingLoanPayoffAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalPurchasePriceAmount?.focused !==
            "override",
          path: totalPurchasePriceAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: downPaymentAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.OriginationFeeAmount?.focused !==
            "override",
          path: ledgerOriginationFeeAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.DeferredOriginationFeeAmount
              ?.focused !== "override",
          path: deferredOriginationFeeAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: ledgerCreditReportFeeAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.ProcessingFeeAmount?.focused !==
            "override",
          path: ledgerProcessingFeeAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: ledgerLenderCounselFeeAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.BuyDownFeeAmount?.focused !==
            "override",
          path: buyDownFeeAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.BuyUpFeeReductionAmount?.focused !==
            "override",
          path: ledgerBuyUpFeeReductionAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.BrokerOriginationFeeAmount?.focused !==
            "override",
          path: ledgerBrokerOriginationFeeAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.BrokerProcessingFeeAmount?.focused !==
            "override",
          path: ledgerBrokerProcessingFeeAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalBrokerFeeAmount?.focused !== "override",
          path: ledgerTotalBrokerFeeAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: ledgerContractorReviewFeeAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideFixNFlip(state)
        },
        {
          path: ledgerFeasibilityReviewFeeAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideFixNFlip(state)
        },
        {
          path: ledgerCondoCertificationFeeAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: interestReserveEscrowFieldPath,
          validation: moneyValidation,
          isReadOnly:
            state.quote?.aggregations?.InterestReserveEscrow?.focused !==
            "override"
        },
        {
          path: ledgerNewYorkProcessingFeeAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: chargentCollectionAmountFieldPath,
          validation: moneyValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          path: prepaidInterestAmountFieldPath,
          validation: moneyValidation,
          isReadOnly:
            state.aggregations?.PrepaidInterestAmount?.focused !== "override"
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: servicingSetupFeeFieldPath,
          validation: moneyValidation,
          isReadOnly:
            state.quote?.aggregations?.ServicingSetupFee?.focused !== "override"
        },
        {
          path: marketingPromotionCreditAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: marketingPromotionCodeFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: lenderFinanceProcessingFeeFieldPath,
          validation: moneyValidation,
          isReadOnly:
            state.quote?.aggregations?.LenderFinanceProcessingFee?.focused !==
            "override"
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: lenderFinanceApplicationFeeFieldPath,
          validation: moneyValidation,
          isReadOnly:
            state.quote?.aggregations?.LenderFinanceApplicationFee?.focused !==
            "override"
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: lenderFinanceDocumentFeeFieldPath,
          validation: moneyValidation,
          isReadOnly:
            state.quote?.aggregations?.LenderFinanceDocumentFee?.focused !==
            "override"
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: lenderFinanceAppraisalFeeFieldPath,
          validation: moneyValidation,
          isReadOnly:
            state.quote?.aggregations?.LenderFinanceAppraisalFee?.focused !==
            "override"
        }
      ]
    })
  };
};

export const escrowsImpoundsSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Escrows/Impounds",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: propertyInsurancePremiumPaymentTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalPropertyInsurancePremiumOutstandingAmount
              ?.focused !== "override",
          path: totalPropertyInsurancePremiumOutstandingAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: propertyInsuranceAgencyFullNameFieldPath,
          validation: stringValidation
        },
        {
          path: floodInsurancePremiumPaymentTypeFieldPath,
          validation: singleSelectValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalFloodInsurancePremiumOutstandingAmount
              ?.focused !== "override",
          path: totalFloodInsurancePremiumOutstandingAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: floodInsuranceAgencyFullNameFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalInitialInsuranceEscrowAmount?.focused !==
            "override",
          path: totalInitialInsuranceEscrowAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalInitialTaxEscrowAmount?.focused !==
            "override",
          path: totalInitialTaxEscrowAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideRental30(state)
        },
        {
          path: interestReserveDepositAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideRental30(state)
        },
        {
          path: deferredMaintenanceAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideRental30(state)
        },
        {
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state.aggregations?.TotalBudgetAmount?.focused !== "override",
          path: totalBudgetAmountFieldPath,
          validation: moneyValidation,
          isHidden: hideFixNFlip(state)
        }
      ]
    })
  };
};
export const titleCommitmentSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Title Commitment",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: titleCommitmentTitleEffectiveDateFieldPath,
          validation: dateValidation
        },
        {
          path: titleCommitmentTitleExpirationDateFieldPath,
          validation: dateValidation
        },
        {
          path: titleCommitmentOwnershipFieldPath,
          validation: singleSelectValidation
        },
        {
          path: titleCommitmentTitlePolicyAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: titleCommitmentTitleVestingTypeFieldPath,
          validation: singleSelectValidation,
          isHidden: hideRental30(state)
        },
        {
          path: titleCommitmentAllSubjectPropertiesIncludedIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: titleCommitmentAllPayoffsLiensIncludedInSection1RequirementsIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: titleCommitmentRequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: titleCommitmentTitleInternationalExecutionIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: titleCommitmentLeaseholdEndorsementIndicatorFieldPath,
          validation: booleanValidation,
          isHidden: hideRental30(state)
        },
        {
          path: titleCommitmentCommentsFieldPath,
          validation: stringValidation
        }
      ]
    })
  };
};

export const settlementStatementSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Settlement Statement",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        { path: totalLiquidAssetAmountFieldPath, validation: moneyValidation },
        {
          path: settlementStatementMatchLedgerIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: loanAmountVerifiedMatchIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: constructionBudgetVerifiedMatchIndicatorFieldPath,
          validation: booleanValidation,
          isHidden: hideFixNFlip(state)
        },
        {
          path: settlementStatementCommentsFieldPath,
          validation: stringValidation
        },
        {
          path: cashFromBorrowerAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: cashToBorrowerAmountFieldPath,
          validation: moneyValidation
        }
      ]
    })
  };
};
export const titleFormsSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Title Forms",
    inputs: buildInputs({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        { path: eoExpirationDateFieldPath, validation: dateValidation },
        {
          path: eoNamedInsuredMatchIndicatorFieldPath,
          validation: booleanValidation
        },
        { path: wireABARoutingNumberFieldPath, validation: stringValidation },
        { path: wireAccountNumberFieldPath, validation: stringValidation },
        { path: wireBankNameFieldPath, validation: stringValidation },
        { path: wireAccountNameFieldPath, validation: stringValidation },
        { path: titleFormsCommentsFieldPath, validation: stringValidation }
      ]
    })
  };
};

export const applicationClosingSections = (r: {
  state: Partial<Deal>;
  onChange?: (v: OnChangeInput) => void;
}): Section[] => {
  const { state, onChange } = r;
  return [
    titleCompanySection(state, onChange),
    loanTermsSection(state, onChange),
    loanTermsARMSection(state, onChange),
    loanTermsCustomSection(state, onChange),
    ledgerSection(state, onChange),
    escrowsImpoundsSection(state, onChange),
    titleCommitmentSection(state, onChange),
    settlementStatementSection(state, onChange),
    titleFormsSection(state, onChange)
  ];
};
