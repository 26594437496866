import { createIcon } from "@chakra-ui/react";

const customIcon = (fill?: string) =>
  createIcon({
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_773_97345)">
          <rect width="16" height="16" rx="8" fill={fill} />
          <g clip-path="url(#clip1_773_97345)">
            <path
              d="M4.66797 5.91667L8.0013 4.25L11.3346 5.91667C11.3346 8.0805 10.1745 11.1291 8.0013 11.75C5.82809 11.1291 4.66797 8.0805 4.66797 5.91667Z"
              stroke="#22543D"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <g clip-path="url(#clip2_773_97345)">
            <path
              d="M9.33465 7.16663L7.50132 8.99996L6.66797 8.16663"
              stroke="#505872"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_773_97345">
            <rect width="16" height="16" fill="white" />
          </clipPath>
          <clipPath id="clip1_773_97345">
            <rect
              width="10"
              height="10"
              fill="white"
              transform="translate(3 3)"
            />
          </clipPath>
          <clipPath id="clip2_773_97345">
            <rect
              width="4"
              height="4"
              fill="white"
              transform="translate(6 6)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const ShieldSucceededIcon = customIcon();
export const ShieldSucceededPurpleIcon = customIcon("#B794F4");
