import {
  Box,
  Center,
  Flex,
  Skeleton,
  SkeletonText,
  Tooltip
} from "@chakra-ui/react";
import {
  AutoGenerateOptions,
  ElphiEntityType,
  FieldType,
  KeyClosingTaskType,
  StatusCode,
  TaskConfiguration
} from "@elphi/types";
import { useEffect, useState } from "react";
import { printDateTime } from "../../../../firebase/firebase.utils";
import { useTaskRuleHooks } from "../../../../hooks/taskRule.hooks";
import { useTaskTemplateHooks } from "../../../../hooks/taskTemplate.hooks";
import { BaseButtonIconDelete } from "../../../button-icon/ButtonIcon";
import { TextCell } from "../../../custom/chakra/Cell";
import StyledInputBuilder, {
  useFormBuilderStateHandler
} from "../../../form-builder/InputBuilder";
import ModalContainer from "../../../modal-container/ModalContainer";
import { ItemsMenuCopyWidget } from "../../../party/table/v2/ItemsMenuCopyWidget";
import {
  SIZE_FIELD,
  TableRowSizeComponent
} from "../../../table/TableRowSizeComponent";
import { useElphiToast } from "../../../toast/toast.hook";
import { createOptionsFromEnum } from "../../../utils/formUtils";
import { DataToOrderConfigurationModal } from "../../data-to-order/DataToOrderConfigurationForm";
import { DataToVerifyConfigurationModal } from "../../data-to-verify/DataToVerifyConfigurationForm";
import TaskRuleSearch from "../../task-rule/TaskRuleSearch";
import { TaskTemplateSearch } from "../../task-template/TaskTemplateSearch";
import { useTaskConfigurationTableHooks } from "./TaskConfigurationTable";

export type TaskConfigurationRowProps = {
  index: string | number;
  taskConfigurationId: string;
  taskConfigurationHooks: ReturnType<
    typeof useTaskConfigurationTableHooks
  >["taskConfigurationHooks"];
  syncState: ReturnType<typeof useFormBuilderStateHandler>["syncState"];
  onChange: ReturnType<typeof useFormBuilderStateHandler>["onChange"];
  state: {
    [id: string]: Partial<TaskConfiguration>;
  };
  getRuleBatchResponse: { isSuccess: boolean };
  getTemplateBatchResponse: { isSuccess: boolean };
  taskRuleState: ReturnType<typeof useTaskRuleHooks>["taskRuleState"];
  taskTemplateState: ReturnType<
    typeof useTaskTemplateHooks
  >["taskTemplateState"];
};

export const TaskConfigurationRow = ({
  index,
  syncState,
  taskConfigurationId,
  taskConfigurationHooks,
  onChange,
  state,
  getRuleBatchResponse,
  getTemplateBatchResponse,
  taskRuleState,
  taskTemplateState
}: TaskConfigurationRowProps) => {
  const {
    taskConfigurationState,
    deleteConfigurationResponse,
    deleteConfiguration
  } = taskConfigurationHooks;
  const [deleteId, setDeleteId] = useState("");
  const { successToast, errorToast } = useElphiToast();

  useEffect(() => {
    setDeleteId("");
    syncState({
      state: taskConfigurationState.entities?.[taskConfigurationId],
      shouldSync: !!taskConfigurationState.entities?.[taskConfigurationId],
      statePath: () => {
        return [taskConfigurationId.toString()];
      }
    });
  }, [taskConfigurationState.entities?.[taskConfigurationId]]);

  const deleteConfigurationHandler = async (id: string) => {
    await deleteConfiguration(id).then((r) => {
      if (r.status === StatusCode.OK) {
        successToast({
          title: "Configuration Deleted",
          description: `configuration: ${r.data?.id}`
        });
      } else if (r.status === StatusCode.BadRequest) {
        errorToast({
          title: "Failed to delete configuration",
          description:
            r.data?.error?.data?.error?.payload?.description ||
            r.data?.description
        });
      }
    });
  };

  const data = taskConfigurationState.entities[taskConfigurationId]!;
  return (
    <Box>
      {data && (
        <Flex w="100%" key={index} justify="space-evenly">
          <TableRowSizeComponent
            size={SIZE_FIELD.S}
            withBorderRightWidth={false}
          >
            <TextCell>{index}</TextCell>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.M}
            withBorderRightWidth={false}
          >
            <TextCell>
              {data?.createdAt && printDateTime(data.createdAt)}
            </TextCell>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              <StyledInputBuilder
                currentValue={state?.[taskConfigurationId]?.name || data?.name}
                fieldType={FieldType.String}
                onChange={(e) => {
                  onChange({
                    fieldKey: [taskConfigurationId, "name"],
                    fieldType: FieldType.String,
                    value: e.target.value
                  });
                }}
                label=""
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              <StyledInputBuilder
                chakraStyles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: "12px"
                  })
                }}
                hideSelectedOptions={false}
                currentValue={
                  state?.[taskConfigurationId]?.autoGenerate ||
                  data?.autoGenerate
                }
                options={createOptionsFromEnum(AutoGenerateOptions)}
                fieldType={FieldType.SingleSelect}
                onChange={(e) => {
                  onChange({
                    fieldKey: [taskConfigurationId, "autoGenerate"],
                    fieldType: FieldType.SingleSelect,
                    value: e.target.value
                  });
                }}
                label=""
                isValid={true}
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              <StyledInputBuilder
                chakraStyles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: "12px"
                  })
                }}
                hideSelectedOptions={false}
                currentValue={
                  state?.[taskConfigurationId]?.taskEntity || data?.taskEntity
                }
                options={createOptionsFromEnum(ElphiEntityType)}
                fieldType={FieldType.SingleSelect}
                onChange={(e) => {
                  onChange({
                    fieldKey: [taskConfigurationId, "taskEntity"],
                    fieldType: FieldType.SingleSelect,
                    value: e.target.value
                  });
                }}
                label=""
                isValid={true}
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              <StyledInputBuilder
                chakraStyles={{
                  valueContainer: (provided) => ({
                    ...provided,
                    fontSize: "12px"
                  })
                }}
                hideSelectedOptions={false}
                currentValue={
                  state?.[taskConfigurationId]?.keyClosingTaskType ||
                  data?.keyClosingTaskType
                }
                options={createOptionsFromEnum(KeyClosingTaskType)}
                fieldType={FieldType.SingleSelect}
                onChange={(e) => {
                  onChange({
                    fieldKey: [taskConfigurationId, "keyClosingTaskType"],
                    fieldType: FieldType.SingleSelect,
                    value: e.target.value
                  });
                }}
                label=""
                isValid={true}
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              {!data.taskTemplateId ||
              getTemplateBatchResponse.isSuccess ||
              taskTemplateState.entities[data.taskTemplateId] ? (
                <TaskTemplateSearch
                  onSelect={(id) => {
                    onChange({
                      fieldKey: [taskConfigurationId, "taskTemplateId"],
                      fieldType: FieldType.Switch,
                      value: id
                    });
                  }}
                  currentValue={
                    state?.[taskConfigurationId]?.taskTemplateId ||
                    data?.taskTemplateId
                  }
                />
              ) : (
                <Skeleton height="10px">
                  <SkeletonText />
                </Skeleton>
              )}
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.L}
            withBorderRightWidth={false}
          >
            <Box>
              {!data.taskRuleTemplateId ||
              getRuleBatchResponse.isSuccess ||
              taskRuleState.entities[data.taskRuleTemplateId] ? (
                <TaskRuleSearch
                  onSelect={(id) => {
                    onChange({
                      fieldKey: [taskConfigurationId, "taskRuleTemplateId"],
                      fieldType: FieldType.Switch,
                      value: id
                    });
                  }}
                  currentValue={
                    state?.[taskConfigurationId]?.taskRuleTemplateId ||
                    data?.taskRuleTemplateId
                  }
                />
              ) : (
                <Skeleton height="10px">
                  <SkeletonText />
                </Skeleton>
              )}
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.M}
            withBorderRightWidth={false}
          >
            <Box>
              <Center>
                <DataToVerifyConfigurationModal taskConfiguration={data} />
              </Center>
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.M}
            withBorderRightWidth={false}
          >
            <Box>
              <Center>
                <DataToOrderConfigurationModal taskConfiguration={data} />
              </Center>
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.M}
            withBorderRightWidth={false}
          >
            <Box>
              <StyledInputBuilder
                currentValue={
                  state?.[taskConfigurationId]?.status || data?.status
                }
                fieldType={FieldType.Switch}
                onChange={(e) => {
                  onChange({
                    fieldKey: [taskConfigurationId, "status"],
                    fieldType: FieldType.Switch,
                    value: e.target.value
                  });
                }}
                label=""
              />
            </Box>
          </TableRowSizeComponent>
          <TableRowSizeComponent
            size={SIZE_FIELD.S}
            withBorderRightWidth={false}
          >
            <Flex alignItems={"center"}>
              <Tooltip label={"system identifiers"} placement="top">
                <Box pr="2px">
                  <ItemsMenuCopyWidget
                    items={[
                      { label: "elphi-task-configuration-id", value: data.id }
                    ]}
                  />
                </Box>
              </Tooltip>

              <BaseButtonIconDelete
                onClick={() => setDeleteId(taskConfigurationId)}
                isLoading={deleteConfigurationResponse.isLoading}
              />
            </Flex>
          </TableRowSizeComponent>
        </Flex>
      )}
      <ModalContainer
        isShow={!!deleteId && !!state?.[deleteId]}
        onCloseModal={() => setDeleteId("")}
        header={`Are you sure you want to delete ${state?.[taskConfigurationId]?.name}?`}
        submit={{
          onConfirm: () => deleteConfigurationHandler(taskConfigurationId)
        }}
      />
    </Box>
  );
};
