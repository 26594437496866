import { createIcon } from "@chakra-ui/react";

const HistoryIcon = (fill?: string) =>
  createIcon({
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 7C3.32746 4.91142 5.48292 2.54676 8.7454 2.08779C10.6777 1.81593 12.6461 2.17941 14.3539 3.12343C16.0617 4.06746 17.4165 5.54091 18.214 7.32177C19.0115 9.1026 19.2086 11.0944 18.7756 12.997C18.3426 14.8996 17.303 16.61 15.8133 17.8704C14.3237 19.1308 12.4647 19.873 10.5165 19.9851C8.5684 20.0972 6.63652 19.5732 5.01208 18.492C3.38765 17.4108 2.15862 15.831 1.51018 13.9907"
          fill={fill}
        />
        <path
          d="M1 7C3.32746 4.91142 5.48292 2.54676 8.7454 2.08779C10.6777 1.81593 12.6461 2.17941 14.3539 3.12343C16.0617 4.06746 17.4165 5.54091 18.214 7.32177C19.0115 9.1026 19.2086 11.0944 18.7756 12.997C18.3426 14.8996 17.303 16.61 15.8133 17.8704C14.3237 19.1308 12.4647 19.873 10.5165 19.9851C8.5684 20.0972 6.63652 19.5732 5.01208 18.492C3.38765 17.4108 2.15862 15.831 1.51018 13.9907"
          stroke="#2b6cb0"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 1V7H7"
          stroke="#2b6cb0"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 7V11L15 14.5"
          stroke="#2b6cb0"
          strokeWidth="1.5"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    )
  });

export const ActiveHistoryIcon = HistoryIcon("#ebf8ff");
export const NotActiveHistoryIcon = HistoryIcon();
