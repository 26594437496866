import {
  ConditionNode,
  DateDeltaFunctionNode,
  ElphiEntityType,
  ExpressionRuleNode,
  FieldNode,
  MultiplyFunctionNode,
  Operation,
  OperationNode,
  RuleNodeType,
  SINGLE_TO_MULTI_INPUT,
  TodayDateFunctionNode
} from "@elphi/types";
import { cloneDeep, findKey, get, unionBy } from "lodash";
import { InputBuilderSpecs, Section } from "../../../form-builder/FormBuilder";
import { accountAssetFieldSpecs } from "../../../form-builder/field-specs/asset/asset.fields";
import {
  dealToEntityFieldSpecs,
  dealToIndividualFieldSpecs
} from "../../../form-builder/field-specs/deal-party/deal-party.fields";
import { dealPropertyFieldSpecs } from "../../../form-builder/field-specs/deal-property/deal-property.fields";
import { dealFieldSpecs } from "../../../form-builder/field-specs/deal/deal.fields";
import {
  entityToEntityRelationFieldSpecs,
  entityToIndividualRelationFieldSpecs
} from "../../../form-builder/field-specs/party-relation/party-relation.fields";
import {
  entityPartyFieldSpecs,
  individualPartyFieldSpecs
} from "../../../form-builder/field-specs/party/party.fields";
import { propertySpecFields } from "../../../form-builder/field-specs/property/property.fields";
import { statementFieldSpecs } from "../../../form-builder/field-specs/statement/statement.fields";
import { FieldType } from "../../../form-builder/fieldFormat.types";

import { taskSpec } from "../../../form-builder/field-specs/task/task.fields";
import { fieldSelection } from "../../../utils/field-selection/builders/field-selection-rule.builder";
import { createOptionsFromEnum } from "../../../utils/formUtils";

export type FieldSpecificationType = Pick<
  InputBuilderSpecs,
  "fieldKey" | "fieldType" | "label" | "options"
>;
export enum NodeOptions {
  Condition = "Condition",
  Operation = "Operation"
}
export enum ConditionOptions {
  Or = "or",
  And = "and"
}

export enum OperationOptions {
  EQ = "==",
  GT = ">",
  LT = "<",
  GTE = "<=",
  LTE = ">=",
  NOT_EQ = "!=",
  INCLUDES = "includes",
  INCLUDES_ANY = "includes-any",
  NOT_INCLUDES = "not-includes"
}

export const MULTI_OPERATION_OPTIONS: {
  [operation in OperationOptions]?: { [singleInput in FieldType]?: FieldType };
} = {
  [OperationOptions.INCLUDES]: SINGLE_TO_MULTI_INPUT,
  [OperationOptions.INCLUDES_ANY]: SINGLE_TO_MULTI_INPUT,
  [OperationOptions.NOT_INCLUDES]: SINGLE_TO_MULTI_INPUT
};

export const ruleNodeSections = (
  state: ConditionNode | OperationNode,
  isReadOnly?: boolean
): Section[] => {
  return [
    {
      header: "",
      inputs: [
        {
          fieldType: FieldType.SingleSelect,
          fieldKey: ["type"],
          label: "type",
          isReadOnly: isReadOnly, //state.type > "",
          options: createOptionsFromEnum(NodeOptions),
          currentValue: state.type,
          isValid: state.type > ""
        }
      ]
    }
  ];
};
export const conditionNodeSection = (state: ConditionNode): Section => {
  return {
    header: "",
    inputs: [
      {
        fieldType: FieldType.SingleSelect,
        fieldKey: ["condition"],
        label: "condition",
        options: createOptionsFromEnum(ConditionOptions),
        currentValue: state.condition,
        isValid: state.condition > ""
      }
    ]
  };
};

export enum ExpressionType {
  Field = "Field",
  Value = "Value",
  Function = "Function"
}

export const valueNodeSection = (node?: ExpressionRuleNode) => {
  if (node?.type === RuleNodeType.Field) {
    if (node?.entityType === ElphiEntityType.party) {
      const entityFieldSpecs = get(entityPartyFieldSpecs, node.path);
      const individualFieldSpecs = get(individualPartyFieldSpecs, node.path);
      if (entityFieldSpecs && individualFieldSpecs) {
        const mergedFieldSpecs = mergeFieldSpecifications(
          entityFieldSpecs,
          individualFieldSpecs
        );
        if (mergedFieldSpecs) return mergedFieldSpecs;
      }
      if (entityFieldSpecs) return entityFieldSpecs;
      if (individualFieldSpecs) return individualFieldSpecs;
    } else if (node?.entityType === ElphiEntityType.deal) {
      return get(dealFieldSpecs, node.path);
    } else if (node?.entityType === ElphiEntityType.property) {
      return get(propertySpecFields, node.path);
    } else if (node?.entityType === ElphiEntityType.task) {
      return get(taskSpec, node.path);
    } else if (node?.entityType === ElphiEntityType.dealParty) {
      const dealIndividualFieldSpecs: FieldSpecificationType = get(
        dealToIndividualFieldSpecs,
        node.path
      );
      const dealEntityFieldSpecs: FieldSpecificationType = get(
        dealToEntityFieldSpecs,
        node.path
      );
      if (dealIndividualFieldSpecs && dealEntityFieldSpecs) {
        const mergedFieldSpecs = mergeFieldSpecifications(
          dealIndividualFieldSpecs,
          dealEntityFieldSpecs
        );
        if (mergedFieldSpecs) return mergedFieldSpecs;
      }
      if (dealIndividualFieldSpecs) return dealIndividualFieldSpecs;
      if (dealEntityFieldSpecs) return dealEntityFieldSpecs;
    } else if (node?.entityType === ElphiEntityType.partyRelation) {
      const entityToIndividualFieldSpecs = get(
        entityToIndividualRelationFieldSpecs,
        node.path
      );
      const entityToEntityFieldSpecs = get(
        entityToEntityRelationFieldSpecs,
        node.path
      );
      if (entityToIndividualFieldSpecs && entityToEntityFieldSpecs) {
        const mergedFieldSpecs = mergeFieldSpecifications(
          entityToIndividualFieldSpecs,
          entityToEntityFieldSpecs
        );
        if (mergedFieldSpecs) return mergedFieldSpecs;
      }
      if (entityToIndividualFieldSpecs) return entityToIndividualFieldSpecs;
      if (entityToEntityFieldSpecs) return entityToEntityFieldSpecs;
    } else if (node?.entityType === ElphiEntityType.dealProperty) {
      return get(dealPropertyFieldSpecs, node.path);
    } else if (node?.entityType === ElphiEntityType.asset) {
      //only one asset type for now, so this suffices
      return get(accountAssetFieldSpecs, node.path);
    } else if (node?.entityType === ElphiEntityType.statement) {
      return get(statementFieldSpecs, node.path);
    }
  } else if (node?.type === RuleNodeType.Function) {
    const functionFieldType = FUNCTION_TO_FIELD_TYPE_MAP?.[node?.functionType];
    if (functionFieldType) {
      return {
        fieldType: functionFieldType,
        label: "value"
      };
    }
  }
  return {
    fieldType: FieldType.String,
    label: "value"
  };
};

export const getInputBuilderSpecs = (
  operation: Operation,
  inputBuilderSpecs: InputBuilderSpecs
) => {
  if (inputBuilderSpecs?.fieldType) {
    const inputBuilder = cloneDeep(inputBuilderSpecs);
    const multiOperationOption = MULTI_OPERATION_OPTIONS?.[operation];
    if (multiOperationOption) {
      const fieldType =
        multiOperationOption?.[inputBuilder.fieldType] ||
        inputBuilder.fieldType;
      inputBuilder.fieldType = fieldType;
      return inputBuilder;
    } else {
      const fieldType =
        (findKey(
          SINGLE_TO_MULTI_INPUT,
          (mappedValue) => mappedValue === inputBuilder.fieldType
        ) as FieldType) || inputBuilder.fieldType;
      inputBuilder.fieldType = fieldType;
      return inputBuilder;
    }
  }
  return inputBuilderSpecs;
};

export const operationNodeSection = (state: OperationNode): Section => {
  return {
    header: "",
    inputs: [
      {
        fieldType: FieldType.SingleSelect,
        fieldKey: ["left", "type"],
        label: "left expression",
        options: createOptionsFromEnum(ExpressionType),
        currentValue: state?.left?.type,
        isValid: state?.left?.type > ""
      },
      ...expressionNodeSection(
        "left",
        state.left,
        getInputBuilderSpecs(state.operation, valueNodeSection(state.right))
      ),
      {
        fieldType: FieldType.SingleSelect,
        fieldKey: ["operation"],
        label: "operation",
        options: createOptionsFromEnum(OperationOptions),
        currentValue: state.operation,
        isValid: state.operation > ""
      },
      {
        fieldType: FieldType.SingleSelect,
        fieldKey: ["right", "type"],
        label: "right expression",
        options: createOptionsFromEnum(ExpressionType),
        currentValue: state?.right?.type,
        isValid: state?.right?.type > ""
      },
      ...expressionNodeSection(
        "right",
        state.right,
        getInputBuilderSpecs(state.operation, valueNodeSection(state.left))
      )
    ]
  };
};

export const fieldNodeSection = (state: FieldNode): InputBuilderSpecs => {
  return {
    fieldType: FieldType.SingleSelect,
    fieldKey: ["entityType"],
    label: "entity",
    options: createOptionsFromEnum(ElphiEntityType),
    currentValue: state?.entityType,
    isValid: state?.entityType > ""
  };
};

export const dateDeltaNodeSection = (
  side: "left" | "right",
  state: DateDeltaFunctionNode | TodayDateFunctionNode | MultiplyFunctionNode
): InputBuilderSpecs[] => {
  if (state.functionType !== "dateDelta") {
    return [];
  }
  return [
    {
      fieldType: FieldType.Number,
      fieldKey: [side, "delta"],
      label: "delta",
      currentValue: state?.delta,
      isValid: state?.delta !== undefined
    },
    {
      fieldType: FieldType.SingleSelect,
      fieldKey: [side, "period"],
      label: "period",
      options: ["d", "m", "y"].map((v) => {
        return {
          label: v,
          value: v
        };
      }),
      currentValue: state?.period,
      isValid: state?.period > ""
    },
    {
      fieldType: FieldType.SingleSelect,
      fieldKey: [side, "field", "entityType"],
      label: "entity",
      options: createOptionsFromEnum(ElphiEntityType),
      currentValue: state?.field?.entityType,
      isValid: state?.field?.entityType > ""
    },
    {
      fieldType: FieldType.SingleSelect,
      fieldKey: [side, "field", "path"],
      label: "field",
      options: fieldSelection.build(state?.field?.entityType),
      currentValue: state?.field?.path,
      isValid: state?.field?.path?.length > 0
    }
  ];
};
export const expressionNodeSection = (
  side: "left" | "right",
  state: ExpressionRuleNode,
  valueNodeFieldSpecs: {
    fieldType: FieldType;
    label: string;
    options?: {
      label: string;
      value: string;
    }[];
  }
): InputBuilderSpecs[] => {
  if (state?.type === RuleNodeType.Field) {
    return [
      {
        fieldType: FieldType.SingleSelect,
        fieldKey: [side, "entityType"],
        label: "entity",
        options: createOptionsFromEnum(ElphiEntityType),
        currentValue: state?.entityType,
        isValid: state?.entityType > ""
      },
      {
        fieldType: FieldType.SingleSelect,
        fieldKey: [side, "path"],
        label: "field",
        options: fieldSelection.build(state?.entityType),
        currentValue: state?.path,
        isValid: state?.path?.length > 0
      }
    ];
  } else if (state?.type === RuleNodeType.Value) {
    if (
      valueNodeFieldSpecs?.fieldType === FieldType.MultiSelect &&
      !!state?.value &&
      !Array.isArray(state.value)
    ) {
      const currentValue =
        typeof state.value === "string" ? state.value.split(",") : state.value;
      return [
        {
          ...valueNodeFieldSpecs,
          fieldType: valueNodeFieldSpecs?.fieldType || FieldType.String,
          fieldKey: [side, "value"],
          currentValue: currentValue,
          isValid: Array.isArray(currentValue)
            ? currentValue.length > 0
            : !!currentValue
        },
        {
          fieldType: valueNodeFieldSpecs?.fieldType || FieldType.String,
          fieldKey: [side, "valueType"],
          label: "value-type",
          isHidden: true,
          currentValue: valueNodeFieldSpecs?.fieldType || FieldType.String
        }
      ];
    }
    return [
      {
        ...valueNodeFieldSpecs,
        fieldType: valueNodeFieldSpecs?.fieldType || FieldType.String,
        fieldKey: [side, "value"],
        currentValue: state?.value,
        isValid: state?.value > ""
      },
      {
        fieldType: valueNodeFieldSpecs?.fieldType || FieldType.String,
        fieldKey: [side, "valueType"],
        label: "value-type",
        isHidden: true,
        currentValue: valueNodeFieldSpecs?.fieldType || FieldType.String
      }
    ];
  } else if (state?.type === RuleNodeType.Function) {
    return [
      {
        fieldType: FieldType.SingleSelect,
        fieldKey: [side, "functionType"],
        label: "functionType",
        options: createOptionsFromEnum(FunctionNodeType),
        currentValue: state?.functionType,
        isValid: state?.functionType > ""
      },
      ...dateDeltaNodeSection(side, state)
    ];
  }
  return [
    {
      fieldType: FieldType.String,
      fieldKey: [],
      label: "place holder",
      isHidden: true,
      currentValue: "",
      isValid: false
    }
  ] as any;
};
export enum FunctionNodeType {
  deltaDate = "dateDelta",
  todayDate = "todayDate"
}

export const FUNCTION_TO_FIELD_TYPE_MAP: {
  [key in FunctionNodeType]: FieldType;
} = {
  [FunctionNodeType.deltaDate]: FieldType.Date,
  [FunctionNodeType.todayDate]: FieldType.Date
};

export const mergeFieldSpecifications = (
  fieldSpec: FieldSpecificationType,
  fieldSpecToMerge: FieldSpecificationType
) => {
  let mergedFieldSpec: FieldSpecificationType | undefined;
  if (
    !!fieldSpec &&
    !!fieldSpecToMerge &&
    fieldSpec.fieldType === fieldSpecToMerge.fieldType
  ) {
    if (
      !!fieldSpec?.options &&
      !!fieldSpecToMerge?.options &&
      [FieldType.MultiSelect, FieldType.SingleSelect].includes(
        fieldSpec.fieldType
      ) &&
      [FieldType.MultiSelect, FieldType.SingleSelect].includes(
        fieldSpecToMerge.fieldType
      )
    ) {
      const options = unionBy(
        fieldSpec.options,
        fieldSpecToMerge.options,
        "value"
      );

      mergedFieldSpec = {
        fieldKey: fieldSpec.fieldKey,
        fieldType: fieldSpec.fieldType,
        label: fieldSpec.label,
        options: options
      };
    }
  }
  return mergedFieldSpec;
};
