import { Box, Button, Flex, Text, useCallbackRef } from "@chakra-ui/react";
import { ConditionNode, OperationNode, RuleNodeType } from "@elphi/types";
import { RuleTemplate } from "@elphi/types/entities/ruleTemplate.types";
import { v4 as uuid } from "uuid";
import elphiTheme from "../../../assets/themes/elphi.theme.default";
import { ButtonIconAdd, ButtonIconDelete } from "../../button-icon/ButtonIcon";
import Tree from "../../tree/Tree";
import {
  ElphiNode,
  NodeAction,
  TreeOperations
} from "../../tree/types/Tree.types";
import { formulaElementBuilder } from "../formulaPrinter.utils";
import CreateRuleNodeForm from "../node-form/CreateRuleNodeForm";

export type TreePrint = {
  rule: RuleNodeDataType;
  formula: string;
  ruleJsonString: string;
};

export type RuleNodeDataType = ConditionNode | OperationNode | undefined | null;

const AddNodeButton = (
  elphiTreeOperations: TreeOperations<RuleNodeDataType>
): NodeAction<RuleNodeDataType> => {
  return {
    component: ButtonIconAdd({ handleOpen: () => {} }),
    isDisabled: (node) => {
      return node.data?.type === RuleNodeType.Operation || !node.data?.type;
    },
    onClick: (node) => {
      const id = uuid();
      elphiTreeOperations.addNode(node.id, {
        children: [],
        data: {} as any,
        id,
        nodeKey: id,
        label: id.slice(0, 3)
      });
    },
    tooltip: "add node"
  };
};

const RemoveNodeButton = (
  elphiTreeOperations: TreeOperations<RuleNodeDataType>
): NodeAction<RuleNodeDataType> => {
  return {
    isDisabled: (node) => {
      return node.id === "$" || elphiTreeOperations.getRoot().id === node.id;
    },
    component: ButtonIconDelete(),
    onClick: (node) => {
      elphiTreeOperations.deleteNode(node.id);
    },
    tooltip: "remove node"
  };
};

const RuleTreeBuilder = (props: {
  selectedRule?: RuleTemplate | "" | 0;
  elphiTreeOperations: TreeOperations<RuleNodeDataType>;
  treeState: ElphiNode<RuleNodeDataType>;
  setTreeState: React.Dispatch<
    React.SetStateAction<ElphiNode<RuleNodeDataType>>
  >;
  treePrint: TreePrint;
  customComponent?: () => JSX.Element;
}) => {
  const addNodeButtonCBRef = useCallbackRef(
    (elphiTreeOperations: TreeOperations<RuleNodeDataType>) => {
      return AddNodeButton(elphiTreeOperations);
    },
    [props.elphiTreeOperations]
  );
  const removeNodeButtonCBRef = useCallbackRef(
    (elphiTreeOperations: TreeOperations<RuleNodeDataType>) => {
      return RemoveNodeButton(elphiTreeOperations);
    },
    [props.elphiTreeOperations]
  );

  const HeaderHeight = "150px";
  return (
    <Box width="100%" height={"100%"} overflow="scroll">
      <Box width="100%">
        <Box p="10px">
          <Text fontSize={"2xl"}>Formula:</Text>
          <Box p="10px">
            {formulaElementBuilder(props.treePrint.formula, [])}
          </Box>
        </Box>
      </Box>
      <Box w="100%" position="sticky" top={0} bgColor="white">
        <Flex float={"right"} h="60px">
          <Box p="10px">
            <Button
              //isDisabled={true}
              {...elphiTheme.components.light.button.secondary}
              float="right"
              onClick={() => {
                props.setTreeState({
                  id: "$",
                  children: [],
                  data: {} as any,
                  nodeKey: "$",
                  label: "$"
                });
              }}
            >
              Reset Tree
            </Button>
          </Box>
          {props?.customComponent && props.customComponent()}
        </Flex>
      </Box>
      <Box width="100%" height={`calc(100%-${HeaderHeight})`} overflow="scroll">
        <Box>
          <Tree
            handleNodeSelect={(_: ElphiNode<RuleNodeDataType>) => {}}
            treeState={props.treeState}
            customComponent={(node: ElphiNode<RuleNodeDataType>) => {
              return (
                <Box width="100%" minW="100%">
                  <CreateRuleNodeForm
                    node={node}
                    elphiTreeOperations={props.elphiTreeOperations}
                  />
                </Box>
              );
            }}
            elphiTreeOperations={props.elphiTreeOperations}
            nodeActions={[
              addNodeButtonCBRef(props.elphiTreeOperations),
              removeNodeButtonCBRef(props.elphiTreeOperations)
            ]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RuleTreeBuilder;
