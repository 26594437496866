import { Deal } from "@elphi/types";
import { ObjectKeyValidation } from "../../form-builder/field-specs/objectKeyValidation.types";

export const loanProgramTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "LoanProgramType"]
> = ["aggregations", "LoanProgramType"];

export const totalPropertyCountFieldPath: ObjectKeyValidation<
  Deal,
  ["TotalPropertyCount"]
> = ["TotalPropertyCount"];

export const channelFieldPath: ObjectKeyValidation<Deal, ["Channel"]> = [
  "Channel"
];

export const employeeLoanIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["EmployeeLoanIndicator"]
> = ["EmployeeLoanIndicator"];
export const chargentTransactionIdentifierFieldPath: ObjectKeyValidation<
  Deal,
  ["ChargentTransactionIdentifier"]
> = ["ChargentTransactionIdentifier"];
export const chargentGatewayIdentifierFieldPath: ObjectKeyValidation<
  Deal,
  ["ChargentGatewayIdentifier"]
> = ["ChargentGatewayIdentifier"];

export const chargentCollectionAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["ChargentCollectionAmount"]
> = ["ChargentCollectionAmount"];

export const valuationFeesCollectedBySalesIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["ValuationFeesCollectedBySalesIndicator"]
> = ["ValuationFeesCollectedBySalesIndicator"];

export const totalPurchasePriceAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalPurchasePriceAmount"]
> = ["aggregations", "TotalPurchasePriceAmount"];

export const downPaymentAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["DownPaymentAmount"]
> = ["DownPaymentAmount"];

export const totalBudgetAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalBudgetAmount"]
> = ["aggregations", "TotalBudgetAmount"];

export const totalAsIsAppraisedValueAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalAsIsAppraisedValueAmount"]
> = ["aggregations", "TotalAsIsAppraisedValueAmount"];

export const totalSubjectToAppraisedValueAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalSubjectToAppraisedValueAmount"]
> = ["aggregations", "TotalSubjectToAppraisedValueAmount"];

export const totalLTVRatePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalLTVRatePercent"]
> = ["aggregations", "TotalLTVRatePercent"];

export const totalLTARVRatePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalLTARVRatePercent"]
> = ["aggregations", "TotalLTARVRatePercent"];

export const requestedLoanAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "RequestedLoanAmount"]
> = ["aggregations", "RequestedLoanAmount"];

export const initialLTVRatePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["InitialLTVRatePercent"]
> = ["InitialLTVRatePercent"];

export const totalOutstandingLoanPayoffAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalOutstandingLoanPayoffAmount"]
> = ["aggregations", "TotalOutstandingLoanPayoffAmount"];

export const initialAdvancedFundAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["InitialAdvancedFundAmount"]
> = ["InitialAdvancedFundAmount"];
export const totalLoanFeesAndClosingCostAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["TotalLoanFeesAndClosingCostAmount"]
> = ["TotalLoanFeesAndClosingCostAmount"];
export const debtServiceCoverageRatioPercentFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "DebtServiceCoverageRatioPercent"]
> = ["quote", "aggregations", "DebtServiceCoverageRatioPercent"];
export const loanProductTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "LoanProductType"]
> = ["aggregations", "LoanProductType"];
export const amortizationTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["AmortizationType"]
> = ["AmortizationType"];

export const noteRatePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "NoteRatePercent"]
> = ["quote", "aggregations", "NoteRatePercent"];
export const estimatedClosingDateFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "EstimatedClosingDate"]
> = ["aggregations", "EstimatedClosingDate"];
export const wireReleaseDateFieldPath: ObjectKeyValidation<
  Deal,
  ["WireReleaseDate"]
> = ["WireReleaseDate"];
export const scheduledFirstPaymentDateFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "ScheduledFirstPaymentDate"]
> = ["aggregations", "ScheduledFirstPaymentDate"];
export const paymentDueDayFieldPath: ObjectKeyValidation<
  Deal,
  ["PaymentDueDay"]
> = ["PaymentDueDay"];
export const loanMaturityDateFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "LoanMaturityDate"]
> = ["aggregations", "LoanMaturityDate"];
export const loanAmortizationPeriodMonthCountFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanAmortizationPeriodMonthCount"]
> = ["LoanAmortizationPeriodMonthCount"];
export const loanTermPeriodMonthCountFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanTermPeriodMonthCount"]
> = ["LoanTermPeriodMonthCount"];

export const loanAfterInterestOnlyPeriodMonthCountFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanAfterInterestOnlyPeriodMonthCount"]
> = ["LoanAfterInterestOnlyPeriodMonthCount"];
export const interestAccrualMethodTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["InterestAccrualMethodType"]
> = ["InterestAccrualMethodType"];
export const loanPaymentTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanPaymentType"]
> = ["LoanPaymentType"];
export const totalMonthlyPITIPaymentAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "TotalMonthlyPITIPaymentAmount"]
> = ["quote", "aggregations", "TotalMonthlyPITIPaymentAmount"];
export const initialMonthlyInterestOnlyPaymentAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "InitialMonthlyInterestOnlyPaymentAmount"]
> = ["quote", "aggregations", "InitialMonthlyInterestOnlyPaymentAmount"];
export const monthlyPrincipalInterestPaymentAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "MonthlyPrincipalInterestPaymentAmount"]
> = ["quote", "aggregations", "MonthlyPrincipalInterestPaymentAmount"];

export const monthlyTaxInsurancePaymentAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "MonthlyTaxInsurancePaymentAmount"]
> = ["quote", "aggregations", "MonthlyTaxInsurancePaymentAmount"];

export const monthlyHomeownersAssociationAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "MonthlyHomeownersAssociationAmount"]
> = ["quote", "aggregations", "MonthlyHomeownersAssociationAmount"];

export const executionTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["ExecutionType"]
> = ["ExecutionType"];

export const prepaymentPenaltyTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "PrepaymentPenaltyType"]
> = ["quote", "aggregations", "PrepaymentPenaltyType"];

export const prepaymentPenaltyExpirationDateFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "PrepaymentPenaltyExpirationDate"]
> = ["aggregations", "PrepaymentPenaltyExpirationDate"];

export const recourseTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["RecourseType"]
> = ["RecourseType"];

export const firstRateChangeDateFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "FirstRateChangeDate"]
> = ["aggregations", "FirstRateChangeDate"];

export const indexSourceTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["IndexSourceType"]
> = ["IndexSourceType"];
export const capitalStructureTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["CapitalStructureType"]
> = ["CapitalStructureType"];

export const marginRatePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["MarginRatePercent"]
> = ["MarginRatePercent"];
export const floorRatePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["FloorRatePercent"]
> = ["FloorRatePercent"];
export const lifetimeCapRatePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["LifetimeCapRatePercent"]
> = ["LifetimeCapRatePercent"];

export const armRepriceRoundingRatePercentTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["ARMRepriceRoundingRatePercentType"]
> = ["ARMRepriceRoundingRatePercentType"];

export const armRepriceRoundingMethodTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["ARMRepriceRoundingMethodType"]
> = ["ARMRepriceRoundingMethodType"];

export const armRepriceLookbackTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["ARMRepriceLookbackType"]
> = ["ARMRepriceLookbackType"];

export const armInitialFixedTermMonthCountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "ARMInitialFixedTermMonthCount"]
> = ["aggregations", "ARMInitialFixedTermMonthCount"];

export const armFinalAdjustableRateTermMonthCountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "ARMFinalAdjustableRateTermMonthCount"]
> = ["aggregations", "ARMFinalAdjustableRateTermMonthCount"];
// export const armRepriceFrequencyTypeFieldPath: ObjectKeyValidation<
//   Deal,
//   ["aggregations", "ARMRepriceFrequencyType", "calculated"]
// > = ["aggregations", "ARMRepriceFrequencyType", "calculated"];

export const armRepriceFrequencyTypeAggregationFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "ARMRepriceFrequencyType"]
> = ["aggregations", "ARMRepriceFrequencyType"];
export const uccFiledIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["UCCFiledIndicator"]
> = ["UCCFiledIndicator"];
export const uccExpirationDateFieldPath: ObjectKeyValidation<
  Deal,
  ["UCCExpirationDate"]
> = ["UCCExpirationDate"];
export const defaultInterestRatePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["DefaultInterestRatePercent"]
> = ["DefaultInterestRatePercent"];
export const lateFeePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["LateFeePercent"]
> = ["LateFeePercent"];
export const releasePercentFieldPath: ObjectKeyValidation<
  Deal,
  ["ReleasePercent"]
> = ["ReleasePercent"];
export const grossSpreadPercentFieldPath: ObjectKeyValidation<
  Deal,
  ["GrossSpreadPercent"]
> = ["GrossSpreadPercent"];
export const gracePeriodDaysFieldPath: ObjectKeyValidation<
  Deal,
  ["GracePeriodDays"]
> = ["GracePeriodDays"];
export const letterOfIntentSignedDateFieldPath: ObjectKeyValidation<
  Deal,
  ["LetterOfIntentSignedDate"]
> = ["LetterOfIntentSignedDate"];
export const lenderIdentifierFieldPath: ObjectKeyValidation<
  Deal,
  ["LenderIdentifier"]
> = ["LenderIdentifier"];
export const lenderEntityTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "EntityType"]
> = ["Lender", "EntityType"];
export const addressLineTextFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "AddressLineText"]
> = ["Lender", "AddressLineText"];
export const addressUnitIdentifierFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "AddressUnitIdentifier"]
> = ["Lender", "AddressUnitIdentifier"];
export const cityNameFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "CityName"]
> = ["Lender", "CityName"];

export const countyNameFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "CountyName"]
> = ["Lender", "CountyName"];
export const formationStateFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "FormationState"]
> = ["Lender", "FormationState"];
export const stateCodeFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "StateCode"]
> = ["Lender", "StateCode"];
export const postalFieldPath: ObjectKeyValidation<
  Deal,
  ["Lender", "PostalCode"]
> = ["Lender", "PostalCode"];
export const fullNameFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyManagement", "FullName"]
> = ["PropertyManagement", "FullName"];

export const representativeFullNameFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyManagement", "RepresentativeFullName"]
> = ["PropertyManagement", "RepresentativeFullName"];
export const fullAddressNameFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyManagement", "FullAddressName"]
> = ["PropertyManagement", "FullAddressName"];
export const contactPointTelephoneValueFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyManagement", "ContactPointTelephoneValue"]
> = ["PropertyManagement", "ContactPointTelephoneValue"];
export const contactPointEmailValueFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyManagement", "ContactPointEmailValue"]
> = ["PropertyManagement", "ContactPointEmailValue"];
export const propertyManagementCommentsFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyManagement", "PropertyManagementComments"]
> = ["PropertyManagement", "PropertyManagementComments"];
export const titleInsuranceUnderwriterFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "TitleInsuranceUnderwriter"]
> = ["TitleCompany", "TitleInsuranceUnderwriter"];
export const titleCompanyFullNameFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "FullName"]
> = ["TitleCompany", "FullName"];
export const titleCompanyClosingAgentFirstNameFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "ClosingAgentFirstName"]
> = ["TitleCompany", "ClosingAgentFirstName"];
export const titleCompanyClosingAgentLastNameFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "ClosingAgentLastName"]
> = ["TitleCompany", "ClosingAgentLastName"];
export const titleCompanyClosingAgentContactPointTelephoneValueFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "ClosingAgentContactPointTelephoneValue"]
> = ["TitleCompany", "ClosingAgentContactPointTelephoneValue"];
export const titleCompanyClosingAgentContactPointEmailValueFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "ClosingAgentContactPointEmailValue"]
> = ["TitleCompany", "ClosingAgentContactPointEmailValue"];
export const titleCompanyAddressLineTextFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "AddressLineText"]
> = ["TitleCompany", "AddressLineText"];
export const titleCompanyAddressUnitIdentifierFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "AddressUnitIdentifier"]
> = ["TitleCompany", "AddressUnitIdentifier"];
export const titleCompanyCityNameFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "CityName"]
> = ["TitleCompany", "CityName"];
export const titleCompanyStateCodeFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "StateCode"]
> = ["TitleCompany", "StateCode"];
export const titleCompanyPostalCodeFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "PostalCode"]
> = ["TitleCompany", "PostalCode"];
export const loanInterestOnlyPeriodMonthCountFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanInterestOnlyPeriodMonthCount"]
> = ["LoanInterestOnlyPeriodMonthCount"];

export const ledgerOriginationFeeAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "OriginationFeeAmount"]
> = ["quote", "aggregations", "OriginationFeeAmount"];

export const deferredOriginationFeeAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "DeferredOriginationFeeAmount"]
> = ["quote", "aggregations", "DeferredOriginationFeeAmount"];

export const ledgerCreditReportFeeAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["Ledger", "CreditReportFeeAmount"]
> = ["Ledger", "CreditReportFeeAmount"];

export const ledgerProcessingFeeAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "ProcessingFeeAmount"]
> = ["quote", "aggregations", "ProcessingFeeAmount"];

export const ledgerLenderCounselFeeAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["Ledger", "LenderCounselFeeAmount"]
> = ["Ledger", "LenderCounselFeeAmount"];

export const buyDownFeeAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "BuyDownFeeAmount"]
> = ["quote", "aggregations", "BuyDownFeeAmount"];

export const ledgerBuyUpFeeReductionAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "BuyUpFeeReductionAmount"]
> = ["quote", "aggregations", "BuyUpFeeReductionAmount"];

export const ledgerBrokerOriginationFeeAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "BrokerOriginationFeeAmount"]
> = ["quote", "aggregations", "BrokerOriginationFeeAmount"];

export const ledgerBrokerProcessingFeeAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["quote", "aggregations", "BrokerProcessingFeeAmount"]
> = ["quote", "aggregations", "BrokerProcessingFeeAmount"];

export const ledgerTotalBrokerFeeAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalBrokerFeeAmount"]
> = ["aggregations", "TotalBrokerFeeAmount"];

export const ledgerContractorReviewFeeAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["Ledger", "ContractorReviewFeeAmount"]
> = ["Ledger", "ContractorReviewFeeAmount"];

export const ledgerFeasibilityReviewFeeAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["Ledger", "FeasibilityReviewFeeAmount"]
> = ["Ledger", "FeasibilityReviewFeeAmount"];

export const ledgerCondoCertificationFeeAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["Ledger", "CondoCertificationFeeAmount"]
> = ["Ledger", "CondoCertificationFeeAmount"];

export const ledgerInterestReserveAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["Ledger", "InterestReserveAmount"]
> = ["Ledger", "InterestReserveAmount"];

export const ledgerNewYorkProcessingFeeAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["Ledger", "NewYorkProcessingFeeAmount"]
> = ["Ledger", "NewYorkProcessingFeeAmount"];

export const prepaidInterestAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "PrepaidInterestAmount"]
> = ["aggregations", "PrepaidInterestAmount"];

export const propertyInsurancePremiumPaymentTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyInsurancePremiumPaymentType"]
> = ["PropertyInsurancePremiumPaymentType"];

export const floodInsurancePremiumPaymentTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["FloodInsurancePremiumPaymentType"]
> = ["FloodInsurancePremiumPaymentType"];

export const totalInitialInsuranceEscrowAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalInitialInsuranceEscrowAmount"]
> = ["aggregations", "TotalInitialInsuranceEscrowAmount"];

export const totalInitialTaxEscrowAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "TotalInitialTaxEscrowAmount"]
> = ["aggregations", "TotalInitialTaxEscrowAmount"];

export const propertyInsuranceAgencyFullNameFieldPath: ObjectKeyValidation<
  Deal,
  ["PropertyInsuranceAgency", "FullName"]
> = ["PropertyInsuranceAgency", "FullName"];

export const floodInsuranceAgencyFullNameFieldPath: ObjectKeyValidation<
  Deal,
  ["FloodInsuranceAgency", "FullName"]
> = ["FloodInsuranceAgency", "FullName"];

export const interestReserveDepositAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["InterestReserveDepositAmount"]
> = ["InterestReserveDepositAmount"];

export const deferredMaintenanceAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["DeferredMaintenanceAmount"]
> = ["DeferredMaintenanceAmount"];

export const titleCommitmentTitleEffectiveDateFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCommitment", "TitleEffectiveDate"]
> = ["TitleCommitment", "TitleEffectiveDate"];
export const titleCommitmentTitleExpirationDateFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCommitment", "TitleExpirationDate"]
> = ["TitleCommitment", "TitleExpirationDate"];
export const titleCommitmentOwnershipFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCommitment", "PropertyRightsOwnershipType"]
> = ["TitleCommitment", "PropertyRightsOwnershipType"];

export const titleCommitmentTitlePolicyAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCommitment", "TitlePolicyAmount"]
> = ["TitleCommitment", "TitlePolicyAmount"];

export const titleCommitmentTitleVestingTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCommitment", "TitleVestingType"]
> = ["TitleCommitment", "TitleVestingType"];

export const titleCommitmentAllSubjectPropertiesIncludedIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCommitment", "AllSubjectPropertiesIncludedIndicator"]
> = ["TitleCommitment", "AllSubjectPropertiesIncludedIndicator"];

export const titleCommitmentAllPayoffsLiensIncludedInSection1RequirementsIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCommitment", "AllPayoffsLiensIncludedInSection1RequirementsIndicator"]
> = [
  "TitleCommitment",
  "AllPayoffsLiensIncludedInSection1RequirementsIndicator"
];

export const titleCommitmentRequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  [
    "TitleCommitment",
    "RequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator"
  ]
> = [
  "TitleCommitment",
  "RequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator"
];

export const titleCommitmentTitleInternationalExecutionIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCommitment", "TitleInternationalExecutionIndicator"]
> = ["TitleCommitment", "TitleInternationalExecutionIndicator"];

export const titleCommitmentLeaseholdEndorsementIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCommitment", "LeaseholdEndorsementIndicator"]
> = ["TitleCommitment", "LeaseholdEndorsementIndicator"];

export const titleCommitmentCommentsFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCommitment", "TitleCommitmentComments"]
> = ["TitleCommitment", "TitleCommitmentComments"];

export const totalLiquidAssetAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["TotalLiquidAssetAmount"]
> = ["TotalLiquidAssetAmount"];

export const settlementStatementMatchLedgerIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["SettlementStatementMatchLedgerIndicator"]
> = ["SettlementStatementMatchLedgerIndicator"];

export const loanAmountVerifiedMatchIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanAmountVerifiedMatchIndicator"]
> = ["LoanAmountVerifiedMatchIndicator"];

export const constructionBudgetVerifiedMatchIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["ConstructionBudgetVerifiedMatchIndicator"]
> = ["ConstructionBudgetVerifiedMatchIndicator"];
export const settlementStatementCommentsFieldPath: ObjectKeyValidation<
  Deal,
  ["SettlementStatementComments"]
> = ["SettlementStatementComments"];
export const eoExpirationDateFieldPath: ObjectKeyValidation<
  Deal,
  ["EOExpirationDate"]
> = ["EOExpirationDate"];

export const eoNamedInsuredMatchIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["EONamedInsuredMatchIndicator"]
> = ["EONamedInsuredMatchIndicator"];

export const wireABARoutingNumberFieldPath: ObjectKeyValidation<
  Deal,
  ["WireABARoutingNumber"]
> = ["WireABARoutingNumber"];

export const wireAccountNumberFieldPath: ObjectKeyValidation<
  Deal,
  ["WireAccountNumber"]
> = ["WireAccountNumber"];

export const wireBankNameFieldPath: ObjectKeyValidation<
  Deal,
  ["WireBankName"]
> = ["WireBankName"];

export const wireAccountNameFieldPath: ObjectKeyValidation<
  Deal,
  ["WireAccountName"]
> = ["WireAccountName"];

export const titleFormsCommentsFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleFormsComments"]
> = ["TitleFormsComments"];
