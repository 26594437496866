import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import { FileIcon } from "../../../file-uploader/Icons";
import { IntegrationCenterTaskCard } from "../integrationCenter.types";

export const TaskCard = (props: {
  taskCard: IntegrationCenterTaskCard;
  bgColor: string;
}) => {
  const { taskCard, bgColor } = props;
  return (
    <Box
      bgColor={bgColor}
      borderWidth={1}
      borderColor="{gray.500}"
      borderRadius={"8px"}
      boxShadow="md"
      p={4}
    >
      <Flex alignItems={"left"}>
        <Text fontSize={"14px"} fontWeight="semibold" mr={2}>
          {taskCard.taskName}
        </Text>
        <Spacer />
        <Box hidden={!taskCard.containFiles}>
          <FileIcon w={8} h={8} />
        </Box>
      </Flex>
      <Flex alignItems="left" direction="column">
        <Text fontSize="14px" fontWeight="400">
          {taskCard.entityInfo}
        </Text>
        <Spacer />
        <Text fontSize="12px" fontWeight="400">
          Vendor Status: {taskCard.vendorStatus}
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="right">
        <Text
          mr={2}
          fontSize="12px"
          fontWeight={"bold"}
          color="red.500"
          hidden={!taskCard?.stepRequired}
        >
          STEP REQUIRED!
        </Text>
        <Spacer />
        <Text fontSize="14px" color="gray.500">
          Modified: {taskCard.modifiedAt}
        </Text>
      </Flex>
    </Box>
  );
};
