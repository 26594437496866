import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { ReactNode } from "react";

export const AuditLogModal = (props: {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
}) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent minWidth={"1000px"}>
        <ModalHeader>Audit Log - {props.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{props.children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
