import {
  ElphiEntityType,
  ElphiTrxStatus,
  KeyClosingTaskType,
  LabelValue
} from "@elphi/types";
import { EntityId } from "@reduxjs/toolkit";
import { ReactNode } from "react";
import useTaskHooks from "../../../hooks/task.hooks";

export type IntegrationCenterTaskCard = {
  id: string;
  isReadyToOrder: boolean;
  taskStatus: string | undefined;
  taskName: string;
  orderStatus: string;
  vendorStatus: string;
  entityInfo: string;
  entityType: ElphiEntityType;
  elphiStatus: ElphiTrxStatus;
  checklistOf: string[];
  kct: KeyClosingTaskType;
  modifiedAt: string;
  stepRequired?: boolean;
  containFiles: boolean;
};

export type IntegrationTaskBoardProps = {
  filteredCards: IntegrationCenterTaskCard[];
  taskCards: IntegrationCenterTaskCard[];
  selectedTaskId: EntityId | undefined;
  isLoading: boolean;
  setSelected: ReturnType<typeof useTaskHooks>["setSelectedTask"];
};

export enum IntegrationBoardFilterType {
  Menu = "menu",
  CheckboxGroup = "checkboxGroup"
}

export type IntegrationCenterFilterProps = {
  title: string;
  options: LabelValue[] | LabelValue<ReactNode, boolean>[];
  selected: string[];
  onChange: (selected: string[]) => void;
  filterType: IntegrationBoardFilterType;
};

export type IntegrationTaskColumnProps = {
  elphiTrxStatus: ElphiTrxStatus;
  taskCards: IntegrationCenterTaskCard[];
  isLoading: boolean;
  setSelected: ReturnType<typeof useTaskHooks>["setSelectedTask"];
  selectedTask?: EntityId;
  totalCards: number;
};

export type IntegrationBoardFilterProps = {
  filterOptions: Partial<IntegrationBoardFilterOptions>;
  state: Record<keyof IntegrationTaskBoardFilters, string[]>;
  onChange: (filterName: string, selected: string[]) => void;
};

export type IntegrationTaskBoardFilters = Omit<
  IntegrationCenterTaskCard,
  "id" | "orderStatus"
>;

export type IntegrationBoardFilterOptions = Record<
  keyof IntegrationTaskBoardFilters,
  { title: string; options: LabelValue[] | LabelValue<ReactNode, boolean>[] }
>;

export type BulkOrderConfirmModalProps = {
  isLoading: boolean;
  isShow: boolean;
  onClickOrder: () => void;
  onCloseModal: () => void;
  filteredCards: IntegrationCenterTaskCard[];
  taskCards: IntegrationCenterTaskCard[];
  onSelectedStatuses: (selected: ElphiTrxStatus[]) => void;
  selectedStatuses: ElphiTrxStatus[];
};
