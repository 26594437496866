import { BaseEntity, FirebaseCollections } from "../../db";
import { Flatten } from "../../utils/flatten";
import { BaseFieldGroup, postClosingFields } from "./field.group.types";
import { PermissionField } from "./permission.types";

export type RoleGroup = BaseEntity<{
  name: string;
  roles: {
    [roleId: string]: {
      fields: BaseFieldGroup<
        FirebaseCollections,
        Flatten<object, PermissionField>
      >;
    };
  };
}>;
export const postClosingGroup: RoleGroup = {
  id: "postClosingGroup",
  index: "",
  createdAt: "",
  modifiedAt: "",
  name: "postClosingGroup",
  roles: {
    post_closing_specialist: {
      fields: postClosingFields
    },
    post_closing_senior_specialist: {
      fields: postClosingFields
    },
    post_closing_director: {
      fields: postClosingFields
    },
    post_closing_manager: {
      fields: postClosingFields
    }
  }
};
