import { Box } from "@chakra-ui/react";
import { Deal, Property, PropertyFields } from "@elphi/types";
import { useEffect } from "react";
import { AppConfig } from "../../config/appConfig";
import { usePropertyFormHandler } from "../../hooks/propertyform.hooks";
import { NavigationPath } from "../../shared/types/navigation.types";
import { sectionsAfterTables } from "../application/sections/property.sections";
import FormBuilder, { OnChangeInput } from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";

const SectionsAfterTables = (props: {
  dealState?: Deal;
  selectedProperty?: Property;
  snapshotId?: string;
  navigationPath?: NavigationPath;
}) => {
  const { selectedProperty, navigationPath } = props;
  const { updatePropertyHandler } = usePropertyFormHandler();

  const { onChange, state, syncState } = useFormBuilderStateHandler({
    initialState: { properties: {} },
    callback: props.snapshotId ? undefined : updatePropertyHandler,
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  useEffect(() => {
    if (selectedProperty && !props.snapshotId) {
      syncState({
        shouldSync: !!selectedProperty,
        state: selectedProperty,
        statePath: () => {
          if (selectedProperty) {
            return ["properties", selectedProperty.id];
          }
        }
      });
    }
  }, [selectedProperty, props.snapshotId]);

  const propertyState =
    ((selectedProperty &&
      state.properties[selectedProperty.id]) as PropertyFields) ||
    ({} as PropertyFields);

  const propertyOnChangeBatch = (v: OnChangeInput) => {
    selectedProperty &&
      !props.snapshotId &&
      onChange({
        fieldType: v.fieldType,
        fieldKey: ["properties", selectedProperty.id, ...v.fieldKey],
        value: v.value
      });
  };

  return (
    <Box h="100%" w="100%">
      {selectedProperty && (
        <Box h="100%" w="100%">
          <Box bgColor="white" h="100%" w="100%">
            <FormBuilder
              customKey="sectionsAfterTables"
              onChange={propertyOnChangeBatch}
              sections={sectionsAfterTables({
                state: propertyState,
                dealState: props.dealState,
                selectedPropertyId: selectedProperty && selectedProperty.id,
                onChange: propertyOnChangeBatch,
                navigationPath: navigationPath
              })}
              navigationPath={navigationPath}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default SectionsAfterTables;
