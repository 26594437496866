import { Box, Button, Center, Flex, Text, useBoolean } from "@chakra-ui/react";
import { useLocation } from "react-router";

import { SelectOrganizationModal } from "../../components/platform/tabs/organization/SelectOrganization";
import { useBrowserHooks } from "../../hooks/browser.hooks";
import { useOrgHooks } from "../../hooks/org.hooks";
import customizedRoutes from "../../routes/index";
import { RoutingProps } from "../../shared";
const Header: React.FC = () => {
  const location = useLocation();
  const { setTabTitle } = useBrowserHooks();
  const pathname = location.pathname;
  const [showSelectOrg, { off, on }] = useBoolean();
  const { selectedOrgId } = useOrgHooks();
  const headerLabel =
    customizedRoutes(["platform_admin", "organization_admin", "admin"]).find(
      (prop: RoutingProps) => pathname.includes(prop.path)
    )?.name ||
    location.state?.title ||
    pathname;
  const headerTitle = `${selectedOrgId} | ${headerLabel}`;
  setTabTitle(headerTitle);
  return (
    <Box
      w="100%"
      bgGradient="linear(203deg, rgb(103, 97, 79) 0%, rgba(2, 0, 36,0.85) 50%, rgb(103, 97, 79) 100%)"
    >
      <Flex justifyContent={"space-between"} w="100%" p="10px">
        <Box>
          <Center>
            <Text fontSize={"28px"} fontWeight="bold" color="white">
              {headerTitle}
            </Text>
          </Center>
        </Box>
        <Box>
          <Button bgColor="white" size="xs" onClick={on}>
            My Organizations
          </Button>
        </Box>
      </Flex>
      <SelectOrganizationModal
        isOpen={showSelectOrg}
        onClose={off}
        op="new-tab"
      />
    </Box>
  );
  // return (
  //   <Navbar
  //     light
  //     expand="md"
  //     style={{
  //       // paddingLeft: "10px",
  //       // height: "50px",
  //       minHeight: "50px",
  //       background:
  //         "linear-gradient(203deg, rgb(103, 97, 79) 0%, rgba(2, 0, 36,0.85) 50%, rgb(103, 97, 79) 100%)",
  //       zIndex: 150
  //     }}
  //   >
  //     <NavbarBrand
  //       style={{
  //         color: "white",
  //         fontWeight: "bold",
  //         fontSize: "28px",
  //         height: "100%"
  //       }}
  //     >
  //       <Flex justifyContent={"space-between"}>
  //         <Box>
  //           <Text>
  //             {selectedOrg ? selectedOrg?.name : ""} | {headerLabel}
  //           </Text>
  //         </Box>
  //         <Box p="10px">
  //           <Button onClick={on}>Select Organization</Button>
  //         </Box>
  //       </Flex>
  //       <SelectOrganizationModal isOpen={showSelectOrg} onClose={off} />
  //     </NavbarBrand>
  //     <NavbarToggler />
  //   </Navbar>
  // );
};

export default Header;
