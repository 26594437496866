import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { ElphiEntityType, ElphiSnapshotEntity } from "@elphi/types";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";

import { useLocation, useNavigate, useParams } from "react-router";
import { WorksheetHeaderContainer } from "../../components/application/WorksheetHeader";
import WorksheetSidebar from "../../components/work-sheet/WorksheetSidebar";
import useDealHooks from "../../hooks/deal.hooks";
import { useOrgHooks } from "../../hooks/org.hooks";
import { useQueryParamsHooks } from "../../hooks/queryParams.hooks";
import { useSnapshotHooks } from "../../hooks/snapshot.hooks";
import { NestedMortgageWorksheet } from "../../routes/pathVariables";
import { LocationState, UserData } from "../../shared";
import { WorksheetTab } from "./worksheet.types";

export const DisplayScreen = (props: {
  dealId: string;
  selectedSnapshot?: ElphiSnapshotEntity;
}) => {
  const { dealId, selectedSnapshot } = props;
  const currentSnapshotId =
    selectedSnapshot?.entityType === ElphiEntityType.deal &&
    selectedSnapshot?.dealId === dealId
      ? selectedSnapshot.id
      : undefined;

  return <Outlet context={{ dealId, snapshotId: currentSnapshotId }} />;
};

const WorksheetLayoutContainer = () => {
  const navigate = useNavigate();
  const { dealId } = useParams();
  const { selectedSnapshot } = useSnapshotHooks();
  const currentSnapshotId =
    selectedSnapshot?.entityType === ElphiEntityType.deal &&
    selectedSnapshot?.dealId === dealId
      ? selectedSnapshot?.id
      : undefined;
  const { setSelectedDeal } = useDealHooks();
  const params = useQueryParamsHooks();
  const queryParamTab = params.get("tab");
  useEffect(() => {
    dealId && setSelectedDeal(dealId);
  }, [dealId]);
  if (!dealId) throw Error("missing dealId");
  const location = useLocation();
  const locationState = location?.state as LocationState;

  const [currentTab, setCurrentTab] = useState<WorksheetTab>(
    (queryParamTab as WorksheetTab) ||
      (locationState?.title as WorksheetTab) ||
      WorksheetTab.Application
  );

  const {} = useOrgHooks();

  useEffect(() => {
    if (!locationState?.title) {
      navigate("", {
        state: { title: WorksheetTab.Application },
        replace: true
      });
    }
  });

  useEffect(() => {
    setCurrentTab(
      (locationState?.title as WorksheetTab) || WorksheetTab.Application
    );
  }, [locationState?.title]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const SIDEBAR_WIDTH = "260px";
  const HEADER_HEIGHT = "200px";
  const toggleWidth = isOpen ? SIDEBAR_WIDTH : "40px";
  return (
    <Flex h="100%" w="100%">
      <Box h="100%" w={toggleWidth} maxW={toggleWidth} minW={toggleWidth}>
        <WorksheetSidebar
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          setCurrentTab={(tab: WorksheetTab) => setCurrentTab(tab)}
          currentTab={currentTab}
          dealId={dealId}
          snapshotId={currentSnapshotId}
          tabs={[
            {
              value: WorksheetTab.Application,
              label: WorksheetTab.Application,
              path: NestedMortgageWorksheet.Application
            },
            {
              value: WorksheetTab.Checklist,
              label: WorksheetTab.Checklist,
              path: NestedMortgageWorksheet.Checklist
            },
            {
              value: WorksheetTab.Portfolio,
              label: WorksheetTab.Portfolio,
              path: NestedMortgageWorksheet.Portfolio
            },
            {
              value: WorksheetTab.Closing,
              label: WorksheetTab.Closing,
              path: NestedMortgageWorksheet.Closing
            }
          ]}
        />
      </Box>
      <Box
        h={`calc(100% - ${HEADER_HEIGHT})`}
        w={`calc(100% - ${toggleWidth})`}
      >
        <Box p="15px">
          <WorksheetHeaderContainer dealId={dealId} />
        </Box>
        <DisplayScreen dealId={dealId} selectedSnapshot={selectedSnapshot} />
      </Box>
    </Flex>
  );
};

const mapDispatchToProps = () => ({});

const mapStateToProps = (state: {
  userData: UserData;
  permissions: string[];
}) => ({
  userData: state.userData,
  permissions: state.permissions
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorksheetLayoutContainer);
