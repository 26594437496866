import { ChevronDownIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  Tooltip
} from "@chakra-ui/react";
import { LabelValue } from "@elphi/types";
import { EntityState } from "@reduxjs/toolkit";
import { debounce, isEmpty, slice, take } from "lodash";
import { ReactNode, useEffect, useRef, useState } from "react";
import { ElphiPaginationList } from "../../components/elphi-list/ElphiList";
import { SearchApiV2 } from "../../components/search/SearchHandler";
import { EMPTY } from "../../constants/common";

export enum MenuOptionsType {
  Menu = "menu",
  CheckboxGroup = "checkboxGroup"
}

export type MenuOptionsProps = {
  customKey: string;
  title: string;
  options: LabelValue[];
  onChange: (selected: string[]) => void;
  selected?: string[];
  RightSearchElement?: ReactNode;
  type?: MenuOptionsType;
  showSelectClearAll?: boolean;
  showClearAll?: boolean;
  showSelectAll?: boolean;
  showSearchBar?: boolean;
  isDisabled?: boolean;
  isUncheckedDisabled?: boolean;
  isSelectAllDisabled?: boolean;
  uncheckedTooltipLabel?: ReactNode;
  buttonTooltipLabel?: string;
  onOpen?: (filterKey: string) => void;
  onClose?: (filterKey: string) => void;
  isSingle?: boolean;
  clearSearchOnSelect?: boolean;
  state?: EntityState<Object>;
  maxHeight?: number | string;
  scrollHeight?: number | string;
  showDisabledTooltip?: boolean;
  listContainerWidth?: number | string;
  searchBy?: "value" | "label";
} & Partial<SearchApiV2>;
const CHUNK_LENGTH = 20;
export const MenuOptions = (props: MenuOptionsProps) => {
  const {
    onOpen,
    onClose,
    onChange,
    searchApi,
    customKey,
    title,
    options,
    selected,
    RightSearchElement,
    isDisabled,
    uncheckedTooltipLabel,
    isSingle,
    state,
    maxHeight = "400px",
    scrollHeight = "400px",
    listContainerWidth = "400px",
    buttonTooltipLabel,
    isUncheckedDisabled = false,
    isSelectAllDisabled = false,
    type = MenuOptionsType.Menu,
    showSelectClearAll = false,
    showClearAll = false,
    showSelectAll = false,
    showSearchBar = false,
    showDisabledTooltip = false,
    clearSearchOnSelect = false,
    searchBy
  } = props;
  const [selectedItems, setSelectedItems] = useState(selected);
  const [currentOptions, setCurrentOptions] = useState<LabelValue[]>(options);
  const [filteredOptions, setFilteredOptions] = useState(
    take(options, CHUNK_LENGTH)
  );
  const [hasMore, setHasMore] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState(EMPTY);
  const [isMenuHovered, setIsMenuHovered] = useState(false);

  useEffect(() => {
    setSelectedItems(selected);
  }, []);

  useEffect(() => {
    if (!selected?.length) setSelectedItems([]);
  }, [selected]);

  useEffect(() => {
    setCurrentOptions(options);
  }, [options]);

  const handleSelectChange = (value: string) => {
    if (clearSearchOnSelect && searchValue) {
      setSearchValue("");
      setFilteredOptions(take(currentOptions, CHUNK_LENGTH));
    }
    const updatedSelectedItems = isSingle
      ? selectedItems && selectedItems.includes(value)
        ? []
        : [value]
      : selectedItems
      ? selectedItems.includes(value)
        ? selectedItems.filter((option) => option !== value)
        : [...selectedItems, value]
      : [value];
    setSelectedItems(updatedSelectedItems);
    onChange(updatedSelectedItems);
  };

  const onSelectAll = () => {
    const arrayOfValues = (
      isEmpty(filteredOptions) ? currentOptions : filteredOptions
    ).map((obj) => obj.value);
    setSelectedItems(arrayOfValues);
    onChange(arrayOfValues);
  };

  const onClearAll = () => {
    if (selectedItems?.length) {
      setSelectedItems([]);
      onChange([]);
    }
  };

  const onSearch = async (query) => {
    setSearchValue(query);

    if (query) {
      const searchRes = await searchApi?.({ query }, true);
      const entities = {
        ...(state?.entities || []),
        ...(searchRes?.data?.results?.entities || [])
      };
      const labelValueObject =
        Object.values(entities)?.map((obj: { name: string }) => ({
          label: obj.name,
          value: obj.name
        })) || [];
      const mergeOptions = [...labelValueObject, ...options];

      const lowerCaseQuery = query.toLowerCase();

      let filteredArray = mergeOptions.filter(
        (item) =>
          (!searchBy &&
            `${item.label} ${item.value}`
              .toLowerCase()
              .includes(lowerCaseQuery)) ||
          (searchBy === "value" &&
            item.value.toLowerCase().includes(lowerCaseQuery)) ||
          (searchBy === "label" &&
            item.label.toLowerCase().includes(lowerCaseQuery))
      );

      if (!searchBy) {
        filteredArray = filteredArray.sort((a, b) =>
          `${a.label} ${a.value}`.localeCompare(`${b.label} ${b.value}`)
        );
      }

      if (filteredArray.length > 0) {
        setFilteredOptions(take(filteredArray, CHUNK_LENGTH));
      } else {
        setFilteredOptions(take(currentOptions, CHUNK_LENGTH));
      }
    } else {
      setFilteredOptions(take(currentOptions, CHUNK_LENGTH));
    }
  };

  const singleSelected = isSingle && !!selectedItems?.length;

  const fetchMoreData = () => {
    if (filteredOptions.length >= currentOptions.length) {
      setHasMore(false);
      return;
    }

    const itemsLength = filteredOptions.length;
    setFilteredOptions(
      filteredOptions.concat(
        slice(currentOptions, itemsLength, itemsLength + CHUNK_LENGTH)
      )
    );
  };

  const onCloseMenu = () => {
    onClose?.(customKey);
  };

  const onOpenMenu = () => {
    onOpen?.(customKey);
    setSearchValue(EMPTY);
    setFilteredOptions(take(currentOptions, CHUNK_LENGTH));
  };

  switch (type) {
    case MenuOptionsType.Menu:
      return (
        <Menu
          closeOnSelect={false}
          key={customKey}
          onOpen={onOpenMenu}
          onClose={onCloseMenu}
          id={customKey}
        >
          <Tooltip
            aria-label={`${customKey}-filter-dropdown`}
            label={buttonTooltipLabel}
            placement={"right-end"}
            isOpen={showDisabledTooltip ? undefined : isMenuHovered}
          >
            <MenuButton
              isDisabled={isDisabled}
              as={Button}
              background={"transparent"}
              fontSize={14}
              fontWeight={"medium"}
              rightIcon={<ChevronDownIcon w={4} h={4} />}
              onMouseEnter={() => setIsMenuHovered(true)}
              onMouseLeave={() => setIsMenuHovered(false)}
            >
              {title}
              {!isEmpty(selectedItems) && (
                <NotificationBadge count={selectedItems?.length} />
              )}
            </MenuButton>
          </Tooltip>
          <MenuList zIndex={5}>
            {(showSelectClearAll || showSearchBar || RightSearchElement) && (
              <MenuOptionHeader>
                <Flex w={"100%"} flexDir={"row"}>
                  {showSearchBar && (
                    <SearchBar
                      onSearch={onSearch}
                      value={searchValue}
                      customKey={customKey}
                    />
                  )}
                  {RightSearchElement}
                </Flex>
                {(showSelectClearAll || showClearAll || showSelectAll) &&
                  !isSingle && (
                    <SelectClearAll
                      isSelectAllDisabled={isSelectAllDisabled}
                      onSelectAll={onSelectAll}
                      onClearAll={onClearAll}
                      showSelectClearAll={showSelectClearAll}
                      showClearAll={showClearAll || showSelectClearAll}
                      showSelectAll={showSelectAll || showSelectClearAll}
                    />
                  )}
              </MenuOptionHeader>
            )}
            <Box overflow={"auto"} maxH={maxHeight}>
              <MenuOptionGroup defaultValue={[]} type="checkbox">
                <Box ref={containerRef} w={listContainerWidth}>
                  <ElphiPaginationList
                    tableName={"menuOptions"}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    height={
                      filteredOptions.length >= CHUNK_LENGTH
                        ? scrollHeight
                        : undefined
                    }
                    items={filteredOptions}
                    options={{
                      showWatching: false,
                      showLoader: false,
                      showEndMessage: false
                    }}
                    pageSize={20}
                    rowStyle={{ borderWidth: 0, p: 0 }}
                    headerStyle={{ p: 0 }}
                    rowBuilder={(option, i) => (
                      <CheckboxItemOption
                        key={i}
                        value={option.value}
                        isChecked={
                          selectedItems?.includes(option.value) || undefined
                        }
                        isUncheckedDisabled={
                          isUncheckedDisabled || singleSelected
                        }
                        onChange={handleSelectChange}
                        uncheckedTooltipLabel={
                          selectedItems?.includes(option.value)
                            ? undefined
                            : singleSelected &&
                              selectedItems?.[0] !== option.value
                            ? "Currently supporting filtering by only one at a time"
                            : !isSingle && uncheckedTooltipLabel
                        }
                      >
                        {option.label}
                      </CheckboxItemOption>
                    )}
                  />
                </Box>
              </MenuOptionGroup>
            </Box>
          </MenuList>
        </Menu>
      );

    case MenuOptionsType.CheckboxGroup:
      return (
        <CheckboxGroup
          value={selectedItems}
          onChange={(values: string[]) => onChange(values)}
        >
          {currentOptions.map((option, i) => (
            <Checkbox
              mr={1}
              key={i}
              value={option.value}
              onChange={() => handleSelectChange(option.value)}
            >
              {option.label}
            </Checkbox>
          ))}
        </CheckboxGroup>
      );

    default:
      return <></>;
  }
};

const MenuOptionHeader = ({ children }) => (
  <Flex margin={"10px"} flexDir={"column"}>
    {children}
  </Flex>
);

const SelectClearAll = ({
  onSelectAll,
  onClearAll,
  isSelectAllDisabled,
  showSelectClearAll,
  showClearAll,
  showSelectAll
}) => (
  <Flex mt="15px">
    {showSelectAll && (
      <Button
        id="menu-options-select-all"
        onClick={onSelectAll}
        disabled={isSelectAllDisabled}
        backgroundColor={"transparent"}
        size={"14px"}
        padding={"5px"}
      >
        <Text color={"blue.600"} fontSize={"12px"}>
          Select all
        </Text>
      </Button>
    )}
    {showSelectClearAll && (
      <Text
        ml="5px"
        mr="5px"
        color={"blue.600"}
        paddingTop={"2px"}
        fontSize={"12px"}
      >
        |
      </Text>
    )}
    {showClearAll && (
      <Button
        id="menu-options-clear-all"
        onClick={onClearAll}
        backgroundColor={"transparent"}
        size={"14px"}
        padding={"5px"}
      >
        <Text color={"blue.600"} fontSize={"12px"}>
          Clear all
        </Text>
      </Button>
    )}
  </Flex>
);

const SearchBar = ({ onSearch, value, customKey }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState(value || EMPTY);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    const debouncedSearch = debounce(onSearch, 800);
    inputRef?.current?.focus();
    debouncedSearch(searchValue);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchValue, onSearch]);

  const onChange = (e) => {
    const inputValue = e.target.value;
    setSearchValue(inputValue);
  };

  return (
    <Box w={"100%"}>
      <InputGroup borderRadius={5}>
        <InputLeftElement pointerEvents="none">
          <Search2Icon color="gray.600" />
        </InputLeftElement>
        <Input
          id={`menu-options-search-bar-${customKey}`}
          type="text"
          value={searchValue}
          placeholder="Search"
          border="1px solid #949494"
          onChange={onChange}
          ref={inputRef}
        />
      </InputGroup>
    </Box>
  );
};

type CustomMenuItemOptionProps = {
  children: React.ReactNode;
  value: string;
  onChange: (value: string) => void;
  isChecked?: boolean;
  isUncheckedDisabled?: boolean;
  uncheckedTooltipLabel?: React.ReactNode;
};

const CheckboxItemOption = (props: CustomMenuItemOptionProps) => {
  const {
    children,
    value,
    isChecked,
    onChange,
    isUncheckedDisabled,
    uncheckedTooltipLabel
  } = props;
  const handleToggle = () => onChange(value);
  return (
    <Tooltip
      isDisabled={!isUncheckedDisabled}
      hasArrow
      label={uncheckedTooltipLabel}
      placement="right"
    >
      <MenuItemOption
        id={value}
        value={value}
        onClick={handleToggle}
        isDisabled={!isChecked && isUncheckedDisabled}
      >
        <Checkbox isChecked={isChecked} pointerEvents="none" ml={"-20px"}>
          {children}
        </Checkbox>
      </MenuItemOption>
    </Tooltip>
  );
};

const NotificationBadge = ({ count }) => {
  return (
    <Box display="inline-block">
      <Badge
        bgColor={"blue.500"}
        color={"white"}
        borderRadius="full"
        ml={2}
        px={2}
        py={"1"}
        fontWeight={"normal"}
      >
        {count}
      </Badge>
    </Box>
  );
};
