import { CalendarIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, InputGroup, InputRightElement } from "@chakra-ui/react";
import { ReactNode } from "react";
import "react-datepicker/dist/react-datepicker.css";

export type DatePickerContainerProps = {
  children?: ReactNode;
  isSelected?: boolean;
  showClear?: boolean;
  isClearable?: boolean;
  onClear?: () => void;
};

export const DatePickerContainer = ({
  children,
  isSelected,
  showClear,
  isClearable = false,
  onClear
}: DatePickerContainerProps) => {
  const handleClear = () => {
    if (onClear) {
      onClear();
    }
  };

  return (
    <Box zIndex={10}>
      <InputGroup>
        <InputRightElement
          children={
            <>
              {isClearable && showClear && (
                <CloseIcon
                  w={"10px"}
                  h={"10px"}
                  onClick={handleClear}
                  mr="5px"
                />
              )}
              <CalendarIcon
                color={isSelected ? "blue.600" : "black"}
                mr={isClearable ? "25px" : "unset"}
              />
            </>
          }
        />
        {children}
      </InputGroup>
    </Box>
  );
};
