import { DealMilestoneType } from "../deal.enums.types";
import { PermissionField } from "./permission.types";
import { postClosingGroup, RoleGroup } from "./role.group.types";

export type MilestoneGroupPermission = {
  [P in DealMilestoneType]?: {
    defaultPermissions: PermissionField;
    roleGroup: { [key: string]: RoleGroup };
  };
};

export const milestonesPermission: MilestoneGroupPermission = {
  "Close of Escrow": {
    defaultPermissions: {
      read: true,
      write: false
    },
    roleGroup: { postClosingGroup: postClosingGroup }
  },
  "File Complete": {
    defaultPermissions: {
      read: true,
      write: false
    },
    roleGroup: {}
  }
};
