import { Type } from "@sinclair/typebox";
import { ArrayField, NumberField, StringField } from "./utils.typebox";

export const IdsModel = Type.Object({
  ids: ArrayField(StringField)
});

export const WhereFilterOp = Type.Union([
  Type.Literal("<"),
  Type.Literal("<="),
  Type.Literal("=="),
  Type.Literal("!="),
  Type.Literal(">="),
  Type.Literal(">"),
  Type.Literal("array-contains"),
  Type.Literal("in"),
  Type.Literal("not-in"),
  Type.Literal("array-contains-any")
]);

export const OrderDirection = Type.Union([
  Type.Literal("desc"),
  Type.Literal("asc")
]);
export const PaginationOptions = Type.Object({
  order: Type.Optional(OrderDirection),
  filter: Type.Optional(
    Type.Object({
      fieldPath: StringField,
      opStr: WhereFilterOp,
      value: Type.Any()
    })
  )
});
export const BasePaginationRequest = Type.Object({
  cursor: StringField,
  limit: NumberField,
  options: Type.Optional(PaginationOptions)
});
