//#region
//#endregion
import { Box, ChakraProvider } from "@chakra-ui/react";
import LogRocket from "logrocket";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/fonts/Heebo/heebo.css";
import "./assets/fonts/Inter/inter.css";
import { chakraTheme } from "./assets/themes/chakra.theme";
import ElphiSpinner from "./common-components/loading-spinner/components/ElphiSpinner";
import { MissingChunkError } from "./error/MissingChunkError";
import { SomethingWentWrong } from "./error/SomethingWentWrong";
import { App } from "./features/main/components/App";
import { StasigUserProvider } from "./providers/StatsigProvider";
import { persistor, store } from "./redux/store";
import ProvideAuth from "./utils/authUtils";
import { ElphiDomain } from "./utils/envUtils";

const logRocketProjectId =
  window.location.hostname === ElphiDomain.Prod
    ? "7kl0k3/elphi-prod"
    : window.location.hostname === ElphiDomain.POC
    ? "7kl0k3/elphi-poc"
    : "7kl0k3/elphi";
if (window.location.hostname !== "localhost") {
  LogRocket.init(logRocketProjectId, {
    network: {
      requestSanitizer: (request) => {
        request.headers["Authorization"] = "[removed by elphi]";
        request.headers["authorization"] = "[removed by elphi]";
        request.body = "[removed by elphi]";
        return request;
      },
      responseSanitizer: (response) => {
        response.headers["Authorization"] = "[removed by elphi]";
        response.headers["authorization"] = "[removed by elphi]";
        if (response.status === 200 || response.status === 201) {
          response.body = "[removed by elphi]";
        }
        return response;
      }
    }
  });
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

root.render(
  <Provider store={store}>
    <PersistGate loading={<ElphiSpinner />} persistor={persistor}>
      <ProvideAuth>
        <ChakraProvider theme={chakraTheme}>
          <ErrorBoundary
            fallbackRender={({ error }) => {
              console.log(error);
              return (
                <Box>
                  {error.message?.includes("Loading chunk") ? (
                    <MissingChunkError />
                  ) : (
                    <SomethingWentWrong />
                  )}
                </Box>
              );
            }}
          >
            <StasigUserProvider>
              <App />
            </StasigUserProvider>
          </ErrorBoundary>
        </ChakraProvider>
      </ProvideAuth>
    </PersistGate>
  </Provider>
);

//tag
