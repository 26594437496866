import { LOSUser } from "@elphi/types";
import { useSelector } from "react-redux";
import { RootState, store } from "../redux/store";
import { losUserApi } from "../redux/v2/los-user/losUser.service";
import { losUserSlice } from "../redux/v2/los-user/losUser.slice";
import { compareStrings } from "../utils/searchUtils";

export const useLOSUserHooks = () => {
  const [getLOSUser, getLOSUserResponse] = losUserApi.useLazyGetQuery();
  const [updateLOSUserApi] = losUserApi.useUpdateMutation();
  const [updateLOSStatusApi, updateLOSStatusApiResponse] =
    losUserApi.useLosStatusMutation();
  const [updateLOSUserStampApi, updateLOSUserStampApiResponse] =
    losUserApi.useLazyStampQuery();
  const [createLOSUserApi, createLOSUserApiResponse] =
    losUserApi.useCreateMutation();
  const [getUsers, getUsersResponse] = losUserApi.useLazyGetUserBatchQuery();
  const [searchUsers, searchResponse] = losUserApi.useLazySearchQuery();
  const setSelectedLOSUser = (id: string) =>
    store.dispatch(losUserSlice.actions.selectedId({ id }));

  const [getLOSUserDeals, getLOSUserDealsResponse] =
    losUserApi.useLazyUserDealsQuery();

  const [getPaginateUsers, getPaginateUsersResponse] =
    losUserApi.useLazyPaginateQuery();

  const selectedLOSUser = useSelector(
    (state: RootState) =>
      state.losUser.selectedId &&
      state.losUser.entities[state.losUser.selectedId]
  );
  const losUserState = useSelector((state: RootState) => state.losUser);

  const createLOSUser = async (newLOSUser: LOSUser) => {
    const response = await createLOSUserApi(newLOSUser);
    if ("data" in response) {
      getLOSUser(response.data.id, true);
      setSelectedLOSUser(response.data.id);
      return {
        status: 200,
        id: response.data.id
      };
    } else {
      console.log(response.error);
      return {
        status: 400,
        description: (response.error as any).data.description
      };
    }
  };
  const updateLOSUser = async (
    updateLOSUser: { id: string } & Partial<LOSUser>
  ) => {
    const response = await updateLOSUserApi(updateLOSUser);
    if ("data" in response) {
      return {
        status: 200,
        id: response.data.id
      };
    } else {
      console.log(response.error);
      return {
        status: 400,
        description: (response.error as any).data.description
      };
    }
  };

  const rankedSort = (query: string) => {
    return Object.values(losUserState.entities).sort((a, b) => {
      const losUserAName = a?.name || "";
      const losUserAFirstName = a?.firstName || "";
      const losUserAMiddleName = a?.middleName || "";
      const losUserALastName = a?.lastName || "";

      const losUserBName = b?.name || "";
      const losUserBFirstName = b?.firstName || "";
      const losUserBMiddleName = b?.middleName || "";
      const losUserBLastName = b?.lastName || "";

      const rankA =
        compareStrings(query, losUserAName) * 40 +
        compareStrings(query, losUserAFirstName) * 20 +
        compareStrings(query, losUserAMiddleName) * 20 +
        compareStrings(query, losUserALastName) * 20;

      const rankB =
        compareStrings(query, losUserBName) * 40 +
        compareStrings(query, losUserBFirstName) * 20 +
        compareStrings(query, losUserBMiddleName) * 20 +
        compareStrings(query, losUserBLastName) * 20;
      if (rankA < rankB) {
        return 1;
      } else {
        return -1;
      }
    });
  };

  return {
    updateLOSStatusApi,
    updateLOSStatusApiResponse,
    updateLOSUserStampApi,
    updateLOSUserStampApiResponse,
    getLOSUser,
    getLOSUserResponse,
    getLOSUserDeals,
    getLOSUserDealsResponse,
    createLOSUser,
    createLOSUserApiResponse,
    updateLOSUser,
    setSelectedLOSUser,
    selectedLOSUser,
    losUserState,
    getUsers,
    getUsersResponse,
    searchUsers,
    searchResponse,
    rankedSort,
    getPaginateUsers,
    getPaginateUsersResponse
  };
};
