import { useEffect } from "react";
import { auth } from "../firebase/firebaseConfig";
import { dealApi } from "../redux/v2/deal";
import useDealHooks from "./deal.hooks";
import { useDealPropertyRelationHooks } from "./dealPropertyRelation.hooks";
import { useDealPartyRelationHooks } from "./dealpartyrelation.hooks";
import { usePartyHooks } from "./party.hooks";

export const worksheetHeaderLiveStateContainerHooks = () => {
  const [getDealProperties, dealPropertiesResponse] =
    dealApi.useLazyDealPropertiesQuery();
  const [getDeal, dealResponse] = dealApi.useLazyGetQuery();
  const [getDealParties, dealPartiesResponse] =
    dealApi.useLazyDealPartiesQuery();

  const { dealState, selectedIdString: dealId } = useDealHooks();
  const { dealPropertyRelationState } = useDealPropertyRelationHooks();
  const { partyState } = usePartyHooks();
  const { dealPartyRelationState } = useDealPartyRelationHooks();

  useEffect(() => {
    if (auth.currentUser && dealId) {
      getDealProperties([dealId], true);
      getDeal(dealId, true);
      getDealParties([dealId], true);
    }
  }, [auth.currentUser, dealId]);

  return {
    dealState,
    dealPropertyRelationState,
    dealPartyRelationState,
    partyState,
    dealPropertiesResponse,
    dealResponse,
    dealPartiesResponse
  };
};
