import { AbsoluteCenter, Box, Button, Divider } from "@chakra-ui/react";
import { ReactElement } from "react";

export const EventLoadButton = (props: {
  text: string;
  isDisabled: boolean;
  onClick: () => void;
  icon: ReactElement;
}) => {
  const { text, isDisabled, onClick, icon } = props;
  return (
    <Box position="relative">
      <Divider />
      <AbsoluteCenter borderRadius={10} bg={"white"} px="1">
        <Button
          h={6}
          boxShadow={"md"}
          borderRadius={10}
          bgColor={"gray.100"}
          fontSize={12}
          leftIcon={icon}
          onClick={onClick}
          disabled={isDisabled}
        >
          {text}
        </Button>
      </AbsoluteCenter>
    </Box>
  );
};
