import { Task } from "@elphi/types";
import { EntityId, EntityState } from "@reduxjs/toolkit";
import { concat } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { responseHandler } from "../apis/rtk/response.handler";
import { RTKResponse } from "../apis/rtk/response.types";
import { RootState, store } from "../redux/store";
import { taskApi } from "../redux/v2/task/task.service";
import { taskSlice } from "../redux/v2/task/task.slice";
import { getListFromDictionary } from "../utils/batchUtils";

export const useTaskHooks = () => {
  const [getTask, getTaskResponse] = taskApi.useLazyGetQuery();
  const [updateBatchApi] = taskApi.useBatchUpdateMutation();
  const [updateTaskApi, updateTaskResponseApi] = taskApi.useUpdateMutation();
  const [createTaskApi, createTaskApiResponse] = taskApi.useCreateMutation();
  const [createTaskFolderApi, createTaskFolderApiResponse] =
    taskApi.useCreateTaskFolderMutation();
  const [uploadTaskFileApi, uploadTaskFileApiResponse] =
    taskApi.useUploadTaskFileMutation();
  const [uploadMultipleTaskFilesApi, uploadMultipleTaskFilesApiResponse] =
    taskApi.useUploadMultipleTaskFilesMutation();

  const [deleteTaskFileApi, deleteTaskFileApiResponse] =
    taskApi.useDeleteTaskFileMutation();
  const [getDownloadUrlApi, getDownloadUrlApiResponse] =
    taskApi.useGetDownloadUrlMutation();

  const [generateManualTasksApi, generateManualTasksApiResponse] =
    taskApi.useGenerateManualTasksMutation();
  const [generateTasksApi, generateTasksApiResponse] =
    taskApi.useGenerateTasksMutation();

  const setSelectedTask = (id: string) =>
    store.dispatch(taskSlice.actions.selectedId({ id }));

  const setFilteredTask = (ids: string[]) =>
    store.dispatch(taskSlice.actions.filteredIds({ ids }));

  const selectedTask = useSelector(
    (state: RootState) =>
      state.task.selectedId && state.task.entities[state.task.selectedId]
  );

  const taskState = useSelector((state: RootState) => state.task);

  const createTask = async (newTask: Task) => {
    const response = await createTaskApi(newTask);
    return responseHandler(response);
  };
  const updateTask = async (newTask: { id: string } & Partial<Task>) => {
    const response = await updateTaskApi(newTask);
    return responseHandler(response);
  };

  const updateBatch = async (r: {
    tasks: ({ id: string } & Partial<Task>)[];
  }) => {
    const response = await updateBatchApi(r);
    return responseHandler(
      response as RTKResponse<{
        batch: string[];
      }>
    );
  };

  const generateTasks = async (r: { dealId?: string; partyId?: string }) => {
    return await generateTasksApi(r).then(responseHandler);
  };
  const generateManualTasks = async (r: {
    dealId: string[];
    partyId: string[];
    propertyId: string[];
    assetId: string[];
    statementId: string[];
    taskConfigurationId: string[];
  }) => {
    return await generateManualTasksApi(r).then(responseHandler);
  };
  const updateTasksHandler = async (
    data:
      | {
          [id: string]: {
            id: string;
          } & Partial<Task>;
        }
      | undefined
  ) => {
    if (!data) return;
    const tasks = getListFromDictionary(data);
    if (!tasks.length) return;
    return await updateBatch({ tasks } as {
      tasks: ({
        id: string;
      } & Partial<Task>)[];
    });
  };
  return {
    updateTasksHandler,
    getTask,
    getTaskResponse,
    createTask,
    createTaskApiResponse,
    updateTask,
    updateTaskResponseApi,
    updateBatch,
    setSelectedTask,
    setFilteredTask,
    selectedTask,
    taskState,
    generateTasks,
    generateTasksApiResponse,
    uploadTaskFileApiResponse,
    uploadTaskFileApi,
    uploadMultipleTaskFilesApi,
    uploadMultipleTaskFilesApiResponse,
    deleteTaskFileApi,
    deleteTaskFileApiResponse,
    getDownloadUrlApi,
    getDownloadUrlApiResponse,
    createTaskFolderApi,
    createTaskFolderApiResponse,
    generateManualTasks,
    generateManualTasksApi,
    generateManualTasksApiResponse
  };
};

// enum CursorFocusType {
//   Deal = "deal",
//   Property = "property",
//   Party = "party"
// }

export const useTaskPagination = (paginationApi: {
  entityState: EntityState<Task>;
  dealId: string | EntityId;
  propertyIds: string[] | EntityId[];
  partyIds: string[] | EntityId[];
  options?: {
    limit: number;
  };
}) => {
  const limit = paginationApi.options?.limit || 10;
  const entityState = paginationApi.entityState;
  const [pageIndex, setPageIndex] = useState(-1);
  // const [lastIndex, setLastIndex] = useState(pageIndex);

  const [getEntityTasks, entityTasksResponse] =
    taskApi.useLazyEntityTasksQuery();
  const [cursorTracker] = useState<number[]>([]);
  const [taskCursor] = useState<number>(0);
  // const [propertiesCursor, setPropertiesCursor] = useState<number>(0);
  // const [partiesCursor, setPartiesCursor] = useState<number>(0);
  // const [cursorFocusType, setCursorFocusType] = useState<CursorFocusType>(
  //   CursorFocusType.Deal
  // );

  const relationshipIds = useMemo(() => {
    return concat(
      paginationApi.dealId,
      paginationApi.propertyIds,
      paginationApi.partyIds
    );
  }, [paginationApi.dealId, paginationApi.partyIds, paginationApi.propertyIds]);

  const taskState = useMemo(() => {
    if (relationshipIds.length !== 0 && entityState.ids.length !== 0) {
      return entityState.ids
        .filter((id) =>
          relationshipIds.includes(entityState?.entities?.[id]?.entityId ?? "")
        )
        .map((id) => entityState?.entities?.[id])
        .sort((a, b) => (a?.index && b?.index && a?.index > b?.index ? 1 : -1));
    }
    return [];
  }, [relationshipIds, entityState]);

  const pageDataMemo = useMemo(() => {
    return taskState.slice(taskCursor, taskCursor + limit);
  }, [taskState, taskCursor]);

  const next = useCallback(() => {
    // if (cursorFocusType === CursorFocusType.Deal) {
    //   getEntityTasks(
    //     {
    //       ids: [paginationApi.dealId]
    //     },
    //     true
    //   ).then(() => {
    //     // switch focus type to property after deal-tasks are fetched
    //     setCursorFocusType(CursorFocusType.Property);
    //   });
    // } else if (cursorFocusType === CursorFocusType.Property) {
    //   if (
    //     lastIndex < pageIndex &&
    //     paginationApi.propertyIds.length !== 0 &&
    //     (paginationApi.propertyIds.length < limit ||
    //       propertiesCursor < paginationApi.propertyIds.length)
    //   ) {
    //     setLastIndex(pageIndex);
    //     const propertySliceIds = paginationApi.propertyIds.slice(
    //       propertiesCursor,
    //       propertiesCursor + limit
    //     );
    //     setPropertiesCursor((prevCursor) => prevCursor + limit);
    //     getEntityTasks(
    //       {
    //         ids: propertySliceIds
    //       },
    //       true
    //     ).then(() => {
    //       // switch focus type to party after property-tasks are fetched
    //       if (paginationApi.propertyIds.slice(propertiesCursor).length === 0) {
    //         setCursorFocusType(CursorFocusType.Party);
    //       }
    //     });
    //   }
    // } else if (cursorFocusType === CursorFocusType.Party) {
    //   if (
    //     lastIndex < pageIndex &&
    //     paginationApi.partyIds.length !== 0 &&
    //     (paginationApi.partyIds.length < limit ||
    //       partiesCursor < paginationApi.partyIds.length)
    //   ) {
    //     setLastIndex(pageIndex);
    //     const partySliceIds = paginationApi.partyIds.slice(
    //       partiesCursor,
    //       partiesCursor + limit
    //     );
    //     setPartiesCursor((prevCursor) => prevCursor + limit);
    //     getEntityTasks(
    //       {
    //         ids: partySliceIds
    //       },
    //       true
    //     );
    //   }
    // }
    // // fetching deal-tasks should not update cursor
    // if (cursorFocusType !== CursorFocusType.Deal) {
    //   setCursorTracker((prevTracker) => {
    //     return [
    //       ...prevTracker,
    //       (prevTracker?.[prevTracker?.length - 1] ?? 0) + limit
    //     ];
    //   });
    //   setTaskCursor((prevCursor) => {
    //     return prevCursor + limit;
    //   });
    // }
    getEntityTasks({ ids: relationshipIds }, true);
    setPageIndex((prevIndex) => prevIndex + 1);
  }, [relationshipIds]);

  const prev = () => {
    if (pageIndex < 0) return;
    // setTaskCursor((currentCursor) => currentCursor - limit);
    // setCursorTracker((currentTracker) => currentTracker.slice(0, -1));
    // setPageIndex((currentIndex) => currentIndex - 1);
  };

  const reset = () => {
    // const reset = 0;
    // setPageIndex(() => reset);
    // setCursorTracker(() => [reset]);
    // setTaskCursor(() => reset);
  };

  return {
    next,
    prev,
    reset,
    pageIndex,
    pageData: pageDataMemo,
    cursorTracker,
    pageResponse: entityTasksResponse
  };
};

export default useTaskHooks;
