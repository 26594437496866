import {
  ElphiEntityType,
  ElphiTrxStatus,
  KeyClosingTaskType,
  LabelValue,
  TaskStatusType
} from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { EntityId } from "@reduxjs/toolkit";
import { intersection, map, uniq } from "lodash";
import { EMPTY } from "../../../../constants/common";
import { fromFirebaseIdToLabel } from "../../../../utils/common";
import {
  createOptionsFromEnum,
  createOptionsFromSelectedEnum
} from "../../../utils/formUtils";
import {
  IntegrationBoardFilterOptions,
  IntegrationCenterTaskCard,
  IntegrationTaskBoardFilters
} from "../integrationCenter.types";

export const selectFilters = (
  keys: Array<keyof IntegrationTaskBoardFilters>,
  optionFilters: IntegrationBoardFilterOptions
) => {
  const selectedFilters: Partial<IntegrationBoardFilterOptions> = {};
  keys.forEach((key) => {
    if (optionFilters[key]) {
      selectedFilters[key] = optionFilters[key];
    }
  });
  return selectedFilters;
};

export const filterData = (
  data: IntegrationCenterTaskCard[],
  filters: Record<keyof IntegrationTaskBoardFilters, string[]>,
  selectedId?: EntityId
) => {
  return data.filter((item) => {
    return Object.keys(filters).every((filterKey) => {
      if (filters[filterKey].length === 0) {
        return true;
      }
      if (item.id === selectedId) {
        return true;
      }
      return !Array.isArray(item[filterKey])
        ? filters[filterKey].includes(item[filterKey])
        : intersection(filters[filterKey], item[filterKey]).length > 0;
    });
  });
};

export const buildOptionFilters = (taskCard: IntegrationCenterTaskCard[]) => {
  const optionFilters: IntegrationBoardFilterOptions = {
    isReadyToOrder: {
      title: "Ready To Order?",
      options: createBooleanOptions()
    },
    stepRequired: { title: "Step Required?", options: createBooleanOptions() },
    elphiStatus: {
      title: "Integration Status",
      options: createOptionsFromEnum(ElphiTrxStatus)
    },
    containFiles: { title: EMPTY, options: createBooleanOptions() },
    kct: { title: "KCT", options: createOptionsFromEnum(KeyClosingTaskType) },
    taskStatus: {
      title: "Task Status",
      options: createOptionsFromEnum(TaskStatusType)
    },
    vendorStatus: {
      title: "Order Status",
      options: createOptionsFromKey(taskCard, "vendorStatus")
    },
    taskName: {
      title: "Task Name",
      options: createOptionsFromKey(taskCard, "taskName")
    },
    entityInfo: {
      title: "Entity Information",
      options: createOptionsFromKey(taskCard, "entityInfo")
    },
    entityType: {
      title: "Entity Type",
      options: createOptionsFromSelectedEnum(ElphiEntityType, [
        "deal",
        "property"
      ])
    },
    modifiedAt: {
      title: "Modified At",
      options: createOptionsFromKey(taskCard, "modifiedAt")
    },
    checklistOf: {
      title: "Role",
      options: createOptionsFromKey(
        taskCard,
        "checklistOf",
        fromFirebaseIdToLabel
      )
    }
  };

  return optionFilters;
};

const createOptionsFromKey = (
  items: IntegrationCenterTaskCard[],
  key: keyof IntegrationTaskBoardFilters,
  mapLabel?: (v: string) => string
) => {
  const uniqueValues = uniq(map(items, key).flat()).filter(removeEmpty);
  const result = uniqueValues.map(
    (val): LabelValue => ({
      label: mapLabel ? mapLabel(String(val)) : String(val),
      value: String(val)
    })
  );
  return result;
};

const createBooleanOptions = () => {
  const filter: LabelValue<string, boolean>[] = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
  ];
  return filter;
};

export const initializedFilterState: Record<
  keyof IntegrationTaskBoardFilters,
  string[]
> = {
  checklistOf: [],
  containFiles: [],
  elphiStatus: [],
  entityInfo: [],
  entityType: [],
  isReadyToOrder: [],
  kct: [],
  modifiedAt: [],
  stepRequired: [],
  taskName: [],
  taskStatus: [],
  vendorStatus: []
};

export const selectedTopFilters: Array<keyof IntegrationTaskBoardFilters> = [
  "isReadyToOrder",
  "stepRequired",
  "elphiStatus"
];

export const selectedBottomFilters: Array<keyof IntegrationTaskBoardFilters> = [
  "taskStatus",
  "vendorStatus",
  "taskName",
  "entityInfo",
  "entityType",
  "checklistOf",
  "kct",
  "modifiedAt"
];
