import { Box, Button, Center, Flex, Text } from "@chakra-ui/react";
import {
  FieldType,
  RolodexConfiguration,
  RolodexConfigurationFieldType
} from "@elphi/types";
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import elphiTheme from "../../../../../../assets/themes/elphi.theme.default";
import { EMPTY } from "../../../../../../constants/common";
import { useRolodexConfiguration } from "../../../../../../hooks/rolodexConfiguration.hooks";
import {
  BaseButtonIconAdd,
  BaseButtonIconDelete
} from "../../../../../button-icon/ButtonIcon";
import StyledInputBuilder from "../../../../../form-builder/InputBuilder";
import ModalContainer from "../../../../../modal-container/ModalContainer";
import { configurationFieldOptions } from "../utils/configuration.utils";

export const SelectFieldsModal = (props: {
  isShow: boolean;
  onClose: () => void;
  data: RolodexConfiguration;
  type: RolodexConfigurationFieldType;
}) => {
  const { isShow, onClose, type, data } = props;

  const { updateFieldsConfiguration, updateSystemResponse } =
    useRolodexConfiguration();

  const [inputs, setInputs] = useState<
    { id: string; input: { fieldPath: string } }[]
  >([]);

  useEffect(() => {
    setInputs(
      Object.entries(data?.fields?.[type] || {}).map(([id, entry]) => {
        return {
          id,
          input: entry
        };
      })
    );
  }, [data?.fields?.[type]]);

  const options = configurationFieldOptions[type];

  const handleOnChange = (r: { id: string; value: string }) => {
    setInputs((prev) =>
      prev.map((input) =>
        input.id === r.id
          ? {
              ...input,
              input: {
                fieldPath: r.value
              }
            }
          : input
      )
    );
  };

  const handleAddClick = () => {
    setInputs((prev) => [
      ...prev,
      {
        id: uuid(),
        input: {
          fieldPath: EMPTY
        }
      }
    ]);
  };

  const handleDeleteClick = (id: string) => {
    setInputs((prev) => prev.filter((input) => input.id !== id));
  };

  const handleSaveClick = () => {
    updateFieldsConfiguration({
      id: data.id,
      fields: {
        ...data.fields,
        [type]: inputs.reduce((acc, obj) => {
          acc[obj.id] = obj.input;
          return acc;
        }, {})
      }
    }).finally(() => {
      onClose();
    });
  };

  return (
    <ModalContainer
      isShow={isShow}
      onCloseModal={onClose}
      header={
        <>
          <Box mt={2}>
            <Text textAlign="center">name : {data.name}</Text>
          </Box>
          <Box mt={2}>
            <Text textAlign="center">{type}</Text>
          </Box>
        </>
      }
      body={
        <>
          {inputs.map((value, index) => (
            <Flex key={index} w="100%">
              <Box w="100%" p="10px">
                <StyledInputBuilder
                  currentValue={value.input.fieldPath}
                  fieldType={FieldType.SingleSelect}
                  options={options}
                  onChange={(e) =>
                    handleOnChange({ id: value.id, value: e.target.value })
                  }
                />
              </Box>
              <Box p="10px">
                <BaseButtonIconDelete
                  onClick={() => handleDeleteClick(value.id)}
                />
              </Box>
            </Flex>
          ))}
          <Box>
            <Center>
              <BaseButtonIconAdd onClick={handleAddClick} isDisabled={false} />
            </Center>
          </Box>
        </>
      }
      footer={
        <>
          <Flex>
            <Box p="5px">
              <Button
                isLoading={updateSystemResponse.isLoading}
                onClick={handleSaveClick}
                float="right"
                {...elphiTheme.components.light.button.primary}
              >
                Save
              </Button>
            </Box>
            <Box p="5px">
              <Button
                onClick={onClose}
                float="right"
                {...elphiTheme.components.light.button.primary}
              >
                Cancel
              </Button>
            </Box>
          </Flex>
        </>
      }
    />
  );
};
