import { RepeatIcon } from "@chakra-ui/icons";
import { Box, Button, Flex } from "@chakra-ui/react";
import { FirebaseCollections } from "@elphi/types";
import { Unsubscribe, onSnapshot } from "firebase/firestore";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { FilterMenuOptions } from "../../../../common-components/menu-options/FilterMenuOptions";
import { useFilterMenuHook } from "../../../../common-components/menu-options/filter-menu-hook";
import firebase, { auth, firestore } from "../../../../firebase/firebaseConfig";
import {
  PAGE_SIZE_LIMIT,
  useAllFieldEventsHooks
} from "../../../../hooks/allEvents.hooks";
import { useFirstMountHook } from "../../../../hooks/firstMount.hook";
import { useLOSUserHooks } from "../../../../hooks/losuser.hooks";
import { useOrgHooks } from "../../../../hooks/org.hooks";
import { FieldAuditLogBoxProps } from "../../auditLogBox.types";
import { EventList } from "../list/EventList";
import { EventLoadButton } from "../list/EventLoadButton";
import { allEventFilters } from "../list/eventList.utils";
import { buildFieldId, buildFieldPaths } from "./allEvents.utils";

export const FieldAllEventsTab = (props: FieldAuditLogBoxProps) => {
  const { selectedOrgId } = useOrgHooks();
  const { getPaginateUsers } = useLOSUserHooks();
  const filterMenuHook = useFilterMenuHook();
  const { clearFilters } = filterMenuHook;
  const {
    filteredAllEvents,
    currentFilter,
    currentHasNew,
    setCurrentHasNew,
    setCurrentFilter,
    nextPage,
    hasMore,
    isLoading,
    setCurrentCursor,
    userOptions,
    selectedFieldId,
    selectedEntityId,
    setSelectedEntityField,
    currentCursor,
    resetFilters
  } = useAllFieldEventsHooks();
  const isFirstMount = useFirstMountHook();

  useEffect(() => {
    getPaginateUsers(
      {
        limit: 600,
        options: {}
      },
      true
    );
    const fieldPaths = buildFieldPaths({
      path: props.path,
      aggregationType: props.aggregationType
    });

    setSelectedEntityField({
      entityId: props.entityId,
      fieldId: buildFieldId({ fieldPaths })
    });
    setCurrentFilter({
      fields: fieldPaths
    });
    setCurrentCursor(undefined);

    return () => {
      resetFilters();
    };
  }, []);

  useEffect(() => {
    const fieldPaths = buildFieldPaths({
      path: props.path,
      aggregationType: props.aggregationType
    });
    if (
      selectedFieldId === buildFieldId({ fieldPaths }) &&
      selectedEntityId === props.entityId &&
      !currentCursor
    ) {
      nextPage();
      setCurrentHasNew(true);
    }
  }, [selectedFieldId, selectedEntityId, currentCursor]);

  useEffect(() => {
    if (isFirstMount) {
      return;
    }
    nextPage();
  }, [currentFilter.users?.length]);

  useEffect(() => {
    if (
      currentHasNew ||
      !selectedOrgId ||
      !currentFilter.fields ||
      !props.entityId
    ) {
      return;
    }

    const dateNow = firebase.firestore.Timestamp.now().toDate();
    const auditQuery = firestore
      .context({
        orgId: selectedOrgId.toString(),
        user: {
          id: auth.currentUser?.uid
        }
      })
      .collection(FirebaseCollections.AuditEvent)
      .where("entityId", "==", props.entityId)
      .where("createdAt", ">=", dateNow);

    const unsubscribes: Unsubscribe[] = [];

    currentFilter.fields.forEach((path) => {
      const withPathQuery = auditQuery.where("fieldPath", "==", path);

      const unsubscribe = onSnapshot(withPathQuery, (snapshot) => {
        const isAnyNewAudit = snapshot
          .docChanges()
          .some((change) => change.type === "added");
        if (isAnyNewAudit) {
          setCurrentHasNew(true);
          unsubscribe();
        }
      });
      unsubscribes.push(unsubscribe);
    });

    return () => {
      unsubscribes?.forEach((u) => u());
    };
  }, [
    currentHasNew,
    selectedOrgId,
    currentFilter.fields?.length,
    props.entityId
  ]);

  const handleReloadClick = () => {
    setCurrentHasNew(false);
    nextPage({
      preferCacheValue: false
    });
  };

  const handleClearClick = () => {
    clearFilters();
    setCurrentFilter({
      ...currentFilter,
      users: []
    });
  };

  return (
    <Box>
      <Box>
        <Flex minWidth="max-content" alignItems="center" gap={2} mb={4}>
          <FilterMenuOptions
            customKey={"userFilter"}
            maxHeight={"300px"}
            scrollHeight={"300px"}
            filterMenuHook={filterMenuHook}
            title={allEventFilters.users}
            options={userOptions}
            showSearchBar={true}
            onChange={(items: string[]) => {
              setCurrentFilter({ ...currentFilter, users: items });
            }}
            selected={currentFilter?.users}
            showClearAll
          />
          <Button
            mr={1}
            background={"transparent"}
            fontSize={14}
            fontWeight={"normal"}
            onClick={handleClearClick}
            isDisabled={isEmpty(currentFilter?.users)}
          >
            Clear filters
          </Button>
          {/* {filteredAllEvents.length} */}
        </Flex>
        <EventLoadButton
          text={"Load New Events"}
          onClick={handleReloadClick}
          isDisabled={isLoading || !currentHasNew}
          icon={<RepeatIcon w={4} h={4} />}
        />
      </Box>
      <Box>
        <EventList
          data={filteredAllEvents}
          hasMore={hasMore}
          isLoading={isLoading}
          next={nextPage}
          height={415}
          limit={PAGE_SIZE_LIMIT}
        />
      </Box>
    </Box>
  );
};
