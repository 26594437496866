import { AggregationType, Deal } from "@elphi/types";
import { OnChangeInput, Section } from "../../form-builder/FormBuilder";
import { dealFieldSpecs } from "../../form-builder/field-specs/deal/deal.fields";
import { buildInputs } from "../../form-builder/formBuilder.utils";

import {
  booleanValidation,
  dateValidation,
  emailValidation,
  moneyValidation,
  multiSelectValidation,
  numberValidation,
  percentValidation,
  singleSelectValidation,
  stringValidation
} from "../../utils/validationUtils";
import {
  applicationRequestedLoanAmountFieldPath,
  arvAdjusterFieldPath,
  associatedPropertiesDescriptionFieldPath,
  blendedLTCAdjusterFieldPath,
  buildingEnvelopeExpandedIndicatorFieldPath,
  buildingPermitsExpectedDateFieldPath,
  buildingPermitsIndicatorFieldPath,
  constructionBudgetRemainingAmountFieldPath,
  creditMemoApprovedDate,
  creditMemoDraftedDate,
  currentLotMarketValueAmountFieldPath,
  developmentStrategyFieldPath,
  exceptionApprovedBy,
  exceptionCommentary,
  exceptionType,
  exitStrategyFieldPath,
  externalApprovalDate,
  externalSubmissionDate,
  hoaContactEmailFieldPath,
  hoaContactNameFieldPath,
  hoaContactPhoneFieldPath,
  interestReserveEscrowOverrideAmountFieldPath,
  interestReserveEscrowOverrideMonthCountFieldPath,
  internalApprovalDate,
  internalL1CRefinanceIndicatorFieldPath,
  internalSubmissionDate,
  lOIAcceptedDate,
  lOIIssuedDate,
  lenderPropertyInsuranceProviderIndicatorFieldPath,
  lenderTitleProviderIndicatorFieldPath,
  lienDollarAmountFieldPath,
  limaOriginationFeeToBorrowerPercentFieldPath,
  limaRateToBorrowerPercentFieldPath,
  loadBearingWallsRemoveIntendedIndicatorFieldPath,
  lotStatusFieldPath,
  lotUtilitiesIndicatorFieldPath,
  lotZonedParcelIdIndicatorFieldPath,
  ltvEligibilityFieldPath,
  occupancyPath,
  occupationIndicatorFieldPath,
  policySection,
  pricingEngineMaxBlendedLoanToCostPercentFieldPath,
  pricingEngineMaxLoanToAfterRepairValuePercentFieldPath,
  pricingEngineMaxPurchaseLoanToCostPercentFieldPath,
  pricingEngineNoteRateOverridePercentFieldPath,
  pricingEngineTotalBlendedLoanToCostOverridePercentFieldPath,
  pricingEngineTotalLoanToAfterRepairValueOverridePercentFieldPath,
  pricingEngineTotalPurchaseLoanToCostOverridePercentFieldPath,
  pricingEngineWarningsFieldPath,
  processingFeeOverrideFieldPath,
  projectAnticipatedCompletionTimelineFieldPath,
  propertyLeasePurchaseOptionIndicatorFieldPath,
  propertyRentReadyConditionIndicatorFieldPath,
  propertyTypeChangingIndicatorFieldPath,
  propertyZoningChangesIndicatorFieldPath,
  purchaseLTCAdjusterFieldPath,
  quoteIdFieldPath,
  sellerRelationshipIndicatorFieldPath,
  squareFootageAddedIndicatorFieldPath,
  subdividePropertyPartialReleasesIndicatorFieldPath,
  targetCloseDateFieldPath,
  validationErrorsFieldPath,
  verifiableConstructionCompletedAmountFieldPath
} from "../field-paths/deal.fieldPaths";
import {
  feeLLPAsFieldPath,
  pricingEngineOverridesFieldPath,
  programDirectorApprovalIndicatorFieldPath,
  programDirectorCommentsFieldPath,
  rateLLPAsFieldPath,
  underwriterApprovalCommentsFieldPath,
  underwriterApprovalStatusFieldPath
} from "../field-paths/dealUnderwriting.fieldPaths";
import { hideFixNFlip } from "./sections.utils";
export const exceptionSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Exceptions",
    inputs: buildInputs<Partial<Deal>>({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: exceptionType,
          validation: multiSelectValidation
        },
        {
          path: policySection,
          validation: multiSelectValidation
        },
        {
          path: exceptionCommentary,
          validation: stringValidation
        },
        {
          path: exceptionApprovedBy,
          validation: singleSelectValidation
        }
      ]
    })
  };
};

export const creditReviewCommitteeApprovalSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Credit Review Committee Approval",
    inputs: buildInputs<Partial<Deal>>({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: creditMemoDraftedDate,
          validation: dateValidation
        },
        {
          path: creditMemoApprovedDate,
          validation: dateValidation
        },
        {
          path: internalSubmissionDate,
          validation: dateValidation
        },
        {
          path: internalApprovalDate,
          validation: dateValidation
        },
        {
          path: externalSubmissionDate,
          validation: dateValidation
        },
        {
          path: externalApprovalDate,
          validation: dateValidation
        },
        {
          path: lOIIssuedDate,
          validation: dateValidation
        },
        {
          path: lOIAcceptedDate,
          validation: dateValidation
        }
      ]
    })
  };
};

export const underwritingDealApprovalSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Underwriting Deal Approval",
    inputs: buildInputs<Partial<Deal>>({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: underwriterApprovalStatusFieldPath,
          validation: singleSelectValidation
        },
        {
          path: underwriterApprovalCommentsFieldPath,
          validation: stringValidation
        },
        {
          path: programDirectorApprovalIndicatorFieldPath,
          validation: stringValidation
        },
        {
          path: programDirectorCommentsFieldPath,
          validation: stringValidation
        }
      ]
    })
  };
};

export const pricingEngineOverridesAndLLPAsSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Pricing Engine Overrides and LLPAs",
    inputs: buildInputs<Partial<Deal>>({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.QuoteId?.focused !== "override",
          path: quoteIdFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.ValidationErrors?.focused !==
            "override",
          path: validationErrorsFieldPath,
          validation: stringValidation
        },
        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.LtvEligibility?.focused !== "override",
          path: ltvEligibilityFieldPath,
          validation: stringValidation
        },

        {
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.PricingEngineWarnings?.focused !==
            "override",
          path: pricingEngineWarningsFieldPath,
          validation: stringValidation
        },
        {
          path: pricingEngineOverridesFieldPath,
          validation: stringValidation,
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.PricingEngineOverrides?.focused !==
            "override"
        },
        {
          path: feeLLPAsFieldPath,
          validation: stringValidation,
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.FeeLLPAs?.focused !== "override"
        },
        {
          path: rateLLPAsFieldPath,
          validation: stringValidation,
          isAggregation: AggregationType.ThirdParty,
          isReadOnly:
            state?.quote?.aggregations?.RateLLPAs?.focused !== "override"
        },
        {
          path: internalL1CRefinanceIndicatorFieldPath,
          validation: booleanValidation,
          isAggregation: AggregationType.Aggregation,
          isReadOnly:
            state?.quote?.aggregations?.InternalL1CRefinanceIndicator
              ?.focused !== "override"
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: limaRateToBorrowerPercentFieldPath,
          validation: percentValidation,
          isReadOnly:
            state.quote?.aggregations?.LimaRateToBorrowerPercent?.focused !==
            "override"
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: limaOriginationFeeToBorrowerPercentFieldPath,
          validation: percentValidation,
          isReadOnly:
            state.quote?.aggregations?.LimaOriginationFeeToBorrowerPercent
              ?.focused !== "override"
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: pricingEngineTotalLoanToAfterRepairValueOverridePercentFieldPath,
          validation: percentValidation,
          isReadOnly:
            state.quote?.aggregations
              ?.PricingEngineTotalLoanToAfterRepairValueOverridePercent
              ?.focused !== "override",
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: pricingEngineTotalBlendedLoanToCostOverridePercentFieldPath,
          validation: percentValidation,
          isReadOnly:
            state.quote?.aggregations
              ?.PricingEngineTotalBlendedLoanToCostOverridePercent?.focused !==
            "override",
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: pricingEngineTotalPurchaseLoanToCostOverridePercentFieldPath,
          validation: percentValidation,
          isReadOnly:
            state.quote?.aggregations
              ?.PricingEngineTotalPurchaseLoanToCostOverridePercent?.focused !==
            "override",
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: pricingEngineNoteRateOverridePercentFieldPath,
          validation: percentValidation,
          isReadOnly:
            state.quote?.aggregations?.PricingEngineNoteRateOverridePercent
              ?.focused !== "override"
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: pricingEngineMaxLoanToAfterRepairValuePercentFieldPath,
          validation: percentValidation,
          isReadOnly:
            state.quote?.aggregations
              ?.PricingEngineMaxLoanToAfterRepairValuePercent?.focused !==
            "override",
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: pricingEngineMaxBlendedLoanToCostPercentFieldPath,
          validation: percentValidation,
          isReadOnly:
            state.quote?.aggregations?.PricingEngineMaxBlendedLoanToCostPercent
              ?.focused !== "override",
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: pricingEngineMaxPurchaseLoanToCostPercentFieldPath,
          validation: percentValidation,
          isReadOnly:
            state.quote?.aggregations?.PricingEngineMaxPurchaseLoanToCostPercent
              ?.focused !== "override",
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: blendedLTCAdjusterFieldPath,
          validation: numberValidation,
          isReadOnly:
            state.quote?.aggregations?.BlendedLTCAdjuster?.focused !==
            "override",
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: purchaseLTCAdjusterFieldPath,
          validation: numberValidation,
          isReadOnly:
            state.quote?.aggregations?.PurchaseLTCAdjuster?.focused !==
            "override",
          isHidden: hideFixNFlip(state)
        },
        {
          isAggregation: AggregationType.ThirdParty,
          path: arvAdjusterFieldPath,
          validation: numberValidation,
          isReadOnly:
            state.quote?.aggregations?.ARVAdjuster?.focused !== "override",
          isHidden: hideFixNFlip(state)
        },
        {
          path: interestReserveEscrowOverrideMonthCountFieldPath,
          validation: numberValidation
        },
        {
          path: interestReserveEscrowOverrideAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: processingFeeOverrideFieldPath,
          validation: moneyValidation
        }
      ]
    })
  };
};

export const miscellaneousApplicationInformationSection = (
  state: Partial<Deal>,
  onChange?: (v: OnChangeInput) => void
): Section => {
  return {
    header: "Miscellaneous Application Information",
    inputs: buildInputs<Partial<Deal>>({
      state,
      onChange,
      fieldSpecs: dealFieldSpecs,
      specs: [
        {
          path: exitStrategyFieldPath,
          validation: stringValidation
        },
        {
          path: targetCloseDateFieldPath,
          validation: dateValidation
        },
        {
          path: applicationRequestedLoanAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: projectAnticipatedCompletionTimelineFieldPath,
          validation: stringValidation
        },
        {
          path: constructionBudgetRemainingAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: verifiableConstructionCompletedAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: lienDollarAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: propertyTypeChangingIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: propertyZoningChangesIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: lenderPropertyInsuranceProviderIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: lenderTitleProviderIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: hoaContactNameFieldPath,
          validation: stringValidation
        },
        {
          path: hoaContactPhoneFieldPath,
          validation: stringValidation
        },
        {
          path: hoaContactEmailFieldPath,
          validation: emailValidation
        },
        {
          path: occupancyPath,
          validation: percentValidation
        },
        {
          path: subdividePropertyPartialReleasesIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: propertyRentReadyConditionIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: propertyLeasePurchaseOptionIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: occupationIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: sellerRelationshipIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: associatedPropertiesDescriptionFieldPath,
          validation: stringValidation
        },
        {
          path: developmentStrategyFieldPath,
          validation: stringValidation
        },
        {
          path: lotStatusFieldPath,
          validation: stringValidation
        },
        {
          path: currentLotMarketValueAmountFieldPath,
          validation: moneyValidation
        },
        {
          path: lotZonedParcelIdIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: buildingPermitsIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: buildingPermitsExpectedDateFieldPath,
          validation: dateValidation
        },
        {
          path: lotUtilitiesIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: buildingEnvelopeExpandedIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: loadBearingWallsRemoveIntendedIndicatorFieldPath,
          validation: booleanValidation
        },
        {
          path: squareFootageAddedIndicatorFieldPath,
          validation: booleanValidation
        }
      ]
    })
  };
};

export const applicationDealUnderwritingSections = (r: {
  state: Partial<Deal>;
  onChange: (v: OnChangeInput) => void;
}): Section[] => {
  const { state, onChange } = r;
  return [
    underwritingDealApprovalSection(state, onChange),
    creditReviewCommitteeApprovalSection(state, onChange),
    pricingEngineOverridesAndLLPAsSection(state, onChange),
    exceptionSection(state, onChange),
    miscellaneousApplicationInformationSection(state, onChange)
  ];
};
