import {
  ARMRepriceFrequencyType,
  ARMRepriceLookbackType,
  ARMRepriceRoundingMethodType,
  ARMRepriceRoundingRatePercentType,
  AggregationFocusType,
  AmortizationType,
  BooleanSelectType,
  CapitalStructureType,
  ChannelType,
  CollateralPackageStatusType,
  Deal,
  DealExceptionApprovedBy,
  DealMilestoneType,
  DealOperationsDepartmentType,
  DebtStatusType,
  ExceptionPolicy,
  ExceptionType,
  ExecutionType,
  FixNFlipNBridgePlusTierType,
  FloodInsurancePremiumPaymentType,
  FundingShieldStatusType,
  IndexSourceType,
  IntegrationType,
  InterestAccrualMethodType,
  LenderEntityType,
  LenderIdentifierType,
  LoanPaymentType,
  LoanProductType,
  LoanProgramType,
  LoanPurposeType,
  LoanTermPeriodMonthCount,
  PaymentDueDayType,
  PrepaymentPenaltyType,
  PricingEngineExceptionStatusType,
  PropertyInsurancePremiumPaymentType,
  PropertyManagerType,
  PropertyModelType,
  PropertyRightsOwnershipType,
  RealEstateProgramType,
  RecourseType,
  ShippingCompanyType,
  StateName,
  TitleVestingType,
  UnderwriterApprovalStatusType,
  UserRoleType,
  WarehouseBankStatusType,
  WarehouseBankType
} from "@elphi/types";
import { QuoteType } from "@elphi/types/entities/deal.quote.types";
import { FundingShieldTrxStatus } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate/funding-shield/fundingShield.types";
import { WireInsuranceVendorType } from "@elphi/types/entities/task-types/integrations/wire-insurance-certificate/wireInsuranceVendor.types";
import LOSUserSearch from "../../../los-user/UserSearch";
import {
  booleanOptions,
  createOptionsFromEnum,
  createOptionsFromEnumAllowUndefined
} from "../../../utils/formUtils";
import { mapEnumValues } from "../../../utils/general.utils";
import { createCustomComponentConfig } from "../../FormBuilder";
import { FieldType } from "../../fieldFormat.types";
import { addressFieldSpecs } from "../address/address.fields";
import { baseEntityFormFieldSpecs } from "../baseEntity.fields";
import { EntityFormFieldSpecs, SpecsBaseOmit } from "../fields.types";
import { createSpecWithFieldMeta } from "../utils/fieldMeta.utils";

export type DealFieldSpecs = EntityFormFieldSpecs<
  SpecsBaseOmit<Omit<Deal, "DealMetadata" | "IntegrationMetadata">> & {
    DealMetadata: { milestone: Deal["DealMetadata"]["milestone"] };
  }
>;

export const dealFieldBaseSpecs: DealFieldSpecs = {
  ...baseEntityFormFieldSpecs,
  DealMetadata: {
    milestone: {
      fieldKey: ["DealMetadata", "milestone"],
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromEnum(DealMilestoneType),
      label: "milestone"
    }
  },
  aggregations: {
    Integrations: {
      [IntegrationType.WireInsuranceCertificate]: {
        [WireInsuranceVendorType.FundingShield]: {
          TransactionId: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.WireInsuranceCertificate,
                WireInsuranceVendorType.FundingShield,
                "TransactionId",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Funding Shield Transaction ID"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.WireInsuranceCertificate,
                WireInsuranceVendorType.FundingShield,
                "TransactionId",
                "focused"
              ],
              fieldType: FieldType.String,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.WireInsuranceCertificate,
                WireInsuranceVendorType.FundingShield,
                "TransactionId",
                "override"
              ],
              fieldType: FieldType.String,
              label: "Funding Shield Transaction ID"
            }
          },
          TransactionStatus: {
            thirdParty: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.WireInsuranceCertificate,
                WireInsuranceVendorType.FundingShield,
                "TransactionStatus",
                "thirdParty"
              ],
              fieldType: FieldType.String,
              label: "Order Status"
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.WireInsuranceCertificate,
                WireInsuranceVendorType.FundingShield,
                "TransactionStatus",
                "focused"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Focus",
              options: mapEnumValues(FundingShieldTrxStatus)
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.WireInsuranceCertificate,
                WireInsuranceVendorType.FundingShield,
                "TransactionStatus",
                "override"
              ],
              fieldType: FieldType.SingleSelect,
              label: "Order Status",
              options: mapEnumValues(FundingShieldTrxStatus)
            }
          },
          StateNYIndicator: {
            calculated: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.WireInsuranceCertificate,
                WireInsuranceVendorType.FundingShield,
                "StateNYIndicator",
                "calculated"
              ],
              fieldType: FieldType.Boolean,
              label: "Is the deal in the state of NY?",
              options: mapEnumValues(BooleanSelectType)
            },
            focused: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.WireInsuranceCertificate,
                WireInsuranceVendorType.FundingShield,
                "StateNYIndicator",
                "focused"
              ],
              fieldType: FieldType.Boolean,
              label: "Focus"
            },
            override: {
              fieldKey: [
                "aggregations",
                "Integrations",
                IntegrationType.WireInsuranceCertificate,
                WireInsuranceVendorType.FundingShield,
                "StateNYIndicator",
                "override"
              ],
              fieldType: FieldType.Boolean,
              label: "Is the deal in the state of NY?",
              options: mapEnumValues(BooleanSelectType)
            }
          }
        }
      }
    },
    TotalBrokerFeeAmount: {
      calculated: {
        fieldType: FieldType.Money,
        label: "Total Broker Fee",
        fieldKey: ["aggregations", "TotalBrokerFeeAmount", "calculated"]
      },
      override: {
        fieldType: FieldType.Money,
        label: "Total Broker Fee",
        fieldKey: ["aggregations", "TotalBrokerFeeAmount", "override"]
      },
      focused: {
        fieldKey: ["aggregations", "TotalBrokerFeeAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    PrepaymentPenaltyExpirationDate: {
      calculated: {
        fieldType: FieldType.Date,
        label: "Prepayment Penalty Expiration Date",
        fieldKey: [
          "aggregations",
          "PrepaymentPenaltyExpirationDate",
          "calculated"
        ]
      },
      override: {
        fieldType: FieldType.Date,
        label: "Prepayment Penalty Expiration Date",
        fieldKey: [
          "aggregations",
          "PrepaymentPenaltyExpirationDate",
          "override"
        ]
      },
      focused: {
        fieldKey: [
          "aggregations",
          "PrepaymentPenaltyExpirationDate",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    FirstRateChangeDate: {
      calculated: {
        fieldType: FieldType.Date,
        label: "First Rate Change Date",
        fieldKey: ["aggregations", "FirstRateChangeDate", "calculated"]
      },
      override: {
        fieldType: FieldType.Date,
        label: "First Rate Change Date",
        fieldKey: ["aggregations", "FirstRateChangeDate", "override"]
      },
      focused: {
        fieldKey: ["aggregations", "FirstRateChangeDate", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    ARMFinalAdjustableRateTermMonthCount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "ARMFinalAdjustableRateTermMonthCount",
          "calculated"
        ],
        fieldType: FieldType.Number,
        label: "ARM Final Adjustable Rate Term (Months)"
      },
      override: {
        fieldKey: [
          "aggregations",
          "ARMFinalAdjustableRateTermMonthCount",
          "override"
        ],
        fieldType: FieldType.Number,
        label: "ARM Final Adjustable Rate Term (Months)"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "ARMFinalAdjustableRateTermMonthCount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    ARMInitialFixedTermMonthCount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "ARMInitialFixedTermMonthCount",
          "calculated"
        ],
        fieldType: FieldType.Number,
        label: "ARM Initial Fixed Term (Months)"
      },
      override: {
        fieldKey: ["aggregations", "ARMInitialFixedTermMonthCount", "override"],
        fieldType: FieldType.Number,
        label: "ARM Initial Fixed Term (Months)"
      },
      focused: {
        fieldKey: ["aggregations", "ARMInitialFixedTermMonthCount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    ARMRepriceFrequencyType: {
      calculated: {
        fieldKey: ["aggregations", "ARMRepriceFrequencyType", "calculated"],
        fieldType: FieldType.SingleSelect,
        label: "ARM Reprice Frequency",
        options: createOptionsFromEnum(ARMRepriceFrequencyType)
      },
      override: {
        fieldKey: ["aggregations", "ARMRepriceFrequencyType", "override"],
        fieldType: FieldType.SingleSelect,
        label: "ARM Reprice Frequency",
        options: createOptionsFromEnum(ARMRepriceFrequencyType)
      },
      focused: {
        fieldKey: ["aggregations", "ARMRepriceFrequencyType", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    LastPaymentDueDate: {
      calculated: {
        fieldKey: ["aggregations", "LastPaymentDueDate", "calculated"],
        fieldType: FieldType.Date,
        label: "MISSINGLABEL"
      },
      override: {
        fieldKey: ["aggregations", "LastPaymentDueDate", "override"],
        fieldType: FieldType.Date,
        label: "MISSINGLABEL"
      },
      focused: {
        fieldKey: ["aggregations", "LastPaymentDueDate", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    LoanMaturityDate: {
      calculated: {
        fieldKey: ["aggregations", "LoanMaturityDate", "calculated"],
        fieldType: FieldType.Date,
        label: "Maturity Date"
      },
      override: {
        fieldKey: ["aggregations", "LoanMaturityDate", "override"],
        fieldType: FieldType.Date,
        label: "Maturity Date"
      },
      focused: {
        fieldKey: ["aggregations", "LoanMaturityDate", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    ScheduledFirstPaymentDate: {
      calculated: {
        fieldKey: ["aggregations", "ScheduledFirstPaymentDate", "calculated"],
        fieldType: FieldType.Date,
        label: "First Payment Due Date"
      },
      override: {
        fieldKey: ["aggregations", "ScheduledFirstPaymentDate", "override"],
        fieldType: FieldType.Date,
        label: "First Payment Due Date"
      },
      focused: {
        fieldKey: ["aggregations", "ScheduledFirstPaymentDate", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalLTVRatePercent: {
      calculated: {
        fieldKey: ["aggregations", "TotalLTVRatePercent", "calculated"],
        fieldType: FieldType.Percentage,
        label: "Total Loan to Value"
      },
      override: {
        fieldKey: ["aggregations", "TotalLTVRatePercent", "override"],
        fieldType: FieldType.Percentage,
        label: "Total Loan to Value"
      },
      focused: {
        fieldKey: ["aggregations", "TotalLTVRatePercent", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalLTARVRatePercent: {
      calculated: {
        fieldKey: ["aggregations", "TotalLTARVRatePercent", "calculated"],
        fieldType: FieldType.Percentage,
        label: "Total Loan to After Repair Value"
      },
      override: {
        fieldKey: ["aggregations", "TotalLTARVRatePercent", "override"],
        fieldType: FieldType.Percentage,
        label: "Total Loan to After Repair Value"
      },
      focused: {
        fieldKey: ["aggregations", "TotalLTARVRatePercent", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalHOAFeesAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalHOAFeesAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Annual HOA Fees"
      },
      override: {
        fieldKey: ["aggregations", "TotalHOAFeesAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Annual HOA Fees"
      },
      focused: {
        fieldKey: ["aggregations", "TotalHOAFeesAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalInitialInsuranceEscrowAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalInitialInsuranceEscrowAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Initial Insurance Escrow"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalInitialInsuranceEscrowAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Initial Insurance Escrow"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "TotalInitialInsuranceEscrowAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalAsIsAppraisedValueAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalAsIsAppraisedValueAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total As-Is Appraised Value"
      },
      override: {
        fieldKey: ["aggregations", "TotalAsIsAppraisedValueAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total As-Is Appraised Value"
      },
      focused: {
        fieldKey: ["aggregations", "TotalAsIsAppraisedValueAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalBudgetAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalBudgetAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Budget"
      },
      override: {
        fieldKey: ["aggregations", "TotalBudgetAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Budget"
      },
      focused: {
        fieldKey: ["aggregations", "TotalBudgetAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalFloodInsurancePremiumAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalFloodInsurancePremiumAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Flood Insurance Premium Amount"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalFloodInsurancePremiumAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Flood Insurance Premium Amount"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "TotalFloodInsurancePremiumAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalInitialTaxEscrowAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalInitialTaxEscrowAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Initial Tax Escrow"
      },
      override: {
        fieldKey: ["aggregations", "TotalInitialTaxEscrowAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Initial Tax Escrow"
      },
      focused: {
        fieldKey: ["aggregations", "TotalInitialTaxEscrowAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalOutstandingLoanPayoffAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalOutstandingLoanPayoffAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Outstanding Mortgage Payoff"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalOutstandingLoanPayoffAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Outstanding Mortgage Payoff"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "TotalOutstandingLoanPayoffAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalPropertyInsurancePremiumAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalPropertyInsurancePremiumAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Property Insurance Premium Amount"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalPropertyInsurancePremiumAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Property Insurance Premium Amount"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "TotalPropertyInsurancePremiumAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalPurchasePriceAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalPurchasePriceAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Purchase Price"
      },
      override: {
        fieldKey: ["aggregations", "TotalPurchasePriceAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Purchase Price"
      },
      focused: {
        fieldKey: ["aggregations", "TotalPurchasePriceAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalSubjectToAppraisedValueAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalSubjectToAppraisedValueAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Subject-To Appraised Value"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalSubjectToAppraisedValueAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Subject-To Appraised Value"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "TotalSubjectToAppraisedValueAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalTaxAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalTaxAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Annual Tax Amount"
      },
      override: {
        fieldKey: ["aggregations", "TotalTaxAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Annual Tax Amount"
      },
      focused: {
        fieldKey: ["aggregations", "TotalTaxAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalCostAmount: {
      calculated: {
        fieldKey: ["aggregations", "TotalCostAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Costs"
      },
      override: {
        fieldKey: ["aggregations", "TotalCostAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Costs"
      },
      focused: {
        fieldKey: ["aggregations", "TotalCostAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalFloodInsurancePremiumOutstandingAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalFloodInsurancePremiumOutstandingAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Flood Insurance Premium Amount Outstanding"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalFloodInsurancePremiumOutstandingAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Flood Insurance Premium Amount Outstanding"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "TotalFloodInsurancePremiumOutstandingAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    RequestedLoanAmount: {
      calculated: {
        fieldKey: ["aggregations", "RequestedLoanAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Loan Amount"
      },
      focused: {
        fieldKey: ["aggregations", "RequestedLoanAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      },
      override: {
        fieldKey: ["aggregations", "RequestedLoanAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Loan Amount"
      }
    },
    TotalMonthlyMarketRent: {
      calculated: {
        fieldKey: ["aggregations", "TotalMonthlyMarketRent", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Monthly Market Rent"
      },
      override: {
        fieldKey: ["aggregations", "TotalMonthlyMarketRent", "override"],
        fieldType: FieldType.Money,
        label: "Total Monthly Market Rent"
      },
      focused: {
        fieldKey: ["aggregations", "TotalMonthlyMarketRent", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    NetWireAmount: {
      calculated: {
        fieldKey: ["aggregations", "NetWireAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Total Net Wire Amount"
      },
      override: {
        fieldKey: ["aggregations", "NetWireAmount", "override"],
        fieldType: FieldType.Money,
        label: "Total Net Wire Amount"
      },
      focused: {
        fieldKey: ["aggregations", "NetWireAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalPropertyInsurancePremiumOutstandingAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalPropertyInsurancePremiumOutstandingAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Property Insurance Premium Amount Outstanding"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalPropertyInsurancePremiumOutstandingAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Property Insurance Premium Amount Outstanding"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "TotalPropertyInsurancePremiumOutstandingAmount",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },

    PrepaidInterestAmount: {
      calculated: {
        fieldKey: ["aggregations", "PrepaidInterestAmount", "calculated"],
        fieldType: FieldType.Money,
        label: "Prepaid Interest Amount"
      },
      override: {
        fieldKey: ["aggregations", "PrepaidInterestAmount", "override"],
        fieldType: FieldType.Money,
        label: "Prepaid Interest Amount"
      },
      focused: {
        fieldKey: ["aggregations", "PrepaidInterestAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalNumberOfProperties: {
      calculated: {
        fieldKey: ["aggregations", "TotalNumberOfProperties", "calculated"],
        fieldType: FieldType.Number,
        label: "Number of Properties"
      },
      override: {
        fieldKey: ["aggregations", "TotalNumberOfProperties", "override"],
        fieldType: FieldType.Number,
        label: "Number of Properties"
      },
      focused: {
        fieldKey: ["aggregations", "TotalNumberOfProperties", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalAdjustedMonthlyRentAmount: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalAdjustedMonthlyRentAmount",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Properties Adjusted Rental Income"
      },
      override: {
        fieldKey: [
          "aggregations",
          "TotalAdjustedMonthlyRentAmount",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Total Properties Adjusted Rental Income"
      },
      focused: {
        fieldKey: ["aggregations", "TotalAdjustedMonthlyRentAmount", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    TotalAnnualCapitalExpenditure: {
      calculated: {
        fieldKey: [
          "aggregations",
          "TotalAnnualCapitalExpenditure",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Total Annual Capital Expenditure"
      },
      override: {
        fieldKey: ["aggregations", "TotalAnnualCapitalExpenditure", "override"],
        fieldType: FieldType.Money,
        label: "Total Annual Capital Expenditure"
      },
      focused: {
        fieldKey: ["aggregations", "TotalAnnualCapitalExpenditure", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    MonthlyPaymentOfCapitalExpenditure: {
      calculated: {
        fieldKey: [
          "aggregations",
          "MonthlyPaymentOfCapitalExpenditure",
          "calculated"
        ],
        fieldType: FieldType.Money,
        label: "Monthly Payment of Capital Expenditure"
      },
      override: {
        fieldKey: [
          "aggregations",
          "MonthlyPaymentOfCapitalExpenditure",
          "override"
        ],
        fieldType: FieldType.Money,
        label: "Monthly Payment of Capital Expenditure"
      },
      focused: {
        fieldKey: [
          "aggregations",
          "MonthlyPaymentOfCapitalExpenditure",
          "focused"
        ],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    // third party field path changes
    LoanProductType: {
      thirdParty: {
        fieldKey: ["aggregations", "LoanProductType", "thirdParty"],
        fieldType: FieldType.SingleSelect,
        label: "Loan Product",
        options: createOptionsFromEnum(LoanProductType)
      },
      override: {
        fieldKey: ["aggregations", "LoanProductType", "override"],
        fieldType: FieldType.SingleSelect,
        label: "Loan Product",
        options: createOptionsFromEnum(LoanProductType)
      },
      focused: {
        fieldKey: ["aggregations", "LoanProductType", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    EstimatedClosingDate: {
      thirdParty: {
        fieldKey: ["aggregations", "EstimatedClosingDate", "thirdParty"],
        fieldType: FieldType.Date,
        label: "Closing Date"
      },
      override: {
        fieldKey: ["aggregations", "EstimatedClosingDate", "override"],
        fieldType: FieldType.Date,
        label: "Closing Date"
      },
      focused: {
        fieldKey: ["aggregations", "EstimatedClosingDate", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    LoanProgramType: {
      thirdParty: {
        fieldKey: ["aggregations", "LoanProgramType", "thirdParty"],
        fieldType: FieldType.SingleSelect,
        label: "Loan Program",
        options: createOptionsFromEnum(LoanProgramType)
      },
      override: {
        fieldKey: ["aggregations", "LoanProgramType", "override"],
        fieldType: FieldType.SingleSelect,
        label: "Loan Program",
        options: createOptionsFromEnum(LoanProgramType)
      },
      focused: {
        fieldKey: ["aggregations", "LoanProgramType", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    },
    LastDayOfClosingMonth: {
      calculated: {
        fieldKey: ["aggregations", "LastDayOfClosingMonth", "calculated"],
        fieldType: FieldType.Date,
        label: "Last day of Closing Month (for per-diem)"
      },
      override: {
        fieldKey: ["aggregations", "LastDayOfClosingMonth", "override"],
        fieldType: FieldType.Date,
        label: "Last day of Closing Month (for per-diem)"
      },
      focused: {
        fieldKey: ["aggregations", "LastDayOfClosingMonth", "focused"],
        fieldType: FieldType.SingleSelect,
        label: "Focus",
        options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
      }
    }
  },
  quote: {
    quoteType: {
      fieldKey: ["quote", "quoteType"],
      label: "Quote Type",
      fieldType: FieldType.SingleSelect,
      options: createOptionsFromEnum(QuoteType)
    },
    aggregations: {
      QuoteId: {
        thirdParty: {
          fieldKey: ["quote", "aggregations", "QuoteId", "thirdParty"],
          label: "Quote ID",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "QuoteId", "override"],
          label: "Quote ID",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "QuoteId", "focused"],
          label: "Quote ID",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      // SHARED GET
      OriginationFeeAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Origination Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "OriginationFeeAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Origination Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "OriginationFeeAmount",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "OriginationFeeAmount",
            "focused"
          ],
          label: "Origination Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      ProcessingFeeAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Processing Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "ProcessingFeeAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Processing Fee",
          fieldKey: ["quote", "aggregations", "ProcessingFeeAmount", "override"]
        },
        focused: {
          fieldKey: ["quote", "aggregations", "ProcessingFeeAmount", "focused"],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      BrokerOriginationFeeAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Broker Origination Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "BrokerOriginationFeeAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Broker Origination Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "BrokerOriginationFeeAmount",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "BrokerOriginationFeeAmount",
            "focused"
          ],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      ServicingSetupFee: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Servicing Setup Fee",
          fieldKey: ["quote", "aggregations", "ServicingSetupFee", "thirdParty"]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Servicing Setup Fee",
          fieldKey: ["quote", "aggregations", "ServicingSetupFee", "override"]
        },
        focused: {
          fieldKey: ["quote", "aggregations", "ServicingSetupFee", "focused"],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LenderFinanceProcessingFee: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Lender Finance Processing Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceProcessingFee",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Lender Finance Processing Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceProcessingFee",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceProcessingFee",
            "focused"
          ],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LenderFinanceApplicationFee: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Lender Finance Application Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceApplicationFee",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Lender Finance Application Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceApplicationFee",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceApplicationFee",
            "focused"
          ],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LimaRateToBorrowerPercent: {
        thirdParty: {
          fieldType: FieldType.Percentage,
          label: "Lender Finance Interest Rate Spread",
          fieldKey: [
            "quote",
            "aggregations",
            "LimaRateToBorrowerPercent",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Percentage,
          label: "Lender Finance Interest Rate Spread",
          fieldKey: [
            "quote",
            "aggregations",
            "LimaRateToBorrowerPercent",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "LimaRateToBorrowerPercent",
            "focused"
          ],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LimaOriginationFeeToBorrowerPercent: {
        thirdParty: {
          fieldType: FieldType.Percentage,
          label: "Lender Finance Origination Fee Spread",
          fieldKey: [
            "quote",
            "aggregations",
            "LimaOriginationFeeToBorrowerPercent",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Percentage,
          label: "Lender Finance Origination Fee Spread",
          fieldKey: [
            "quote",
            "aggregations",
            "LimaOriginationFeeToBorrowerPercent",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "LimaOriginationFeeToBorrowerPercent",
            "focused"
          ],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LenderFinanceDocumentFee: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Lender Finance Document Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceDocumentFee",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Lender Finance Document Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceDocumentFee",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceDocumentFee",
            "focused"
          ],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LenderFinanceAppraisalFee: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Lender Finance Appraisal Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceAppraisalFee",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Lender Finance Appraisal Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceAppraisalFee",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "LenderFinanceAppraisalFee",
            "focused"
          ],
          label: "Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineWarnings: {
        thirdParty: {
          fieldType: FieldType.RichText,
          label: "Pricing Engine Warnings",
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineWarnings",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.RichText,
          label: "Pricing Engine Warnings",
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineWarnings",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineWarnings",
            "focused"
          ],
          label: "Pricing Engine Warnings",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      BrokerProcessingFeeAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Broker Processing Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "BrokerProcessingFeeAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Broker Processing Fee",
          fieldKey: [
            "quote",
            "aggregations",
            "BrokerProcessingFeeAmount",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "BrokerProcessingFeeAmount",
            "focused"
          ],
          label: "Broker Processing Fee",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      FeeLLPAs: {
        thirdParty: {
          fieldType: FieldType.RichText,
          label: "Fee LLPAs",
          fieldKey: ["quote", "aggregations", "FeeLLPAs", "thirdParty"]
        },
        override: {
          fieldType: FieldType.RichText,
          label: "Fee LLPAs",
          fieldKey: ["quote", "aggregations", "FeeLLPAs", "override"]
        },
        focused: {
          fieldKey: ["quote", "aggregations", "FeeLLPAs", "focused"],
          label: "Fee LLPAs",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      RateLLPAs: {
        thirdParty: {
          fieldType: FieldType.RichText,
          label: "Rate LLPAs",
          fieldKey: ["quote", "aggregations", "RateLLPAs", "thirdParty"]
        },
        override: {
          fieldType: FieldType.RichText,
          label: "Rate LLPAs",
          fieldKey: ["quote", "aggregations", "RateLLPAs", "override"]
        },
        focused: {
          fieldKey: ["quote", "aggregations", "RateLLPAs", "focused"],
          label: "Rate LLPAs",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineOverrides: {
        thirdParty: {
          fieldType: FieldType.RichText,
          label: "Pricing Engine Overrides",
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineOverrides",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.RichText,
          label: "Pricing Engine Overrides",
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineOverrides",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineOverrides",
            "focused"
          ],
          label: "Pricing Engine Overrides",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      ValidationErrors: {
        thirdParty: {
          fieldType: FieldType.RichText,
          label: "Pricing Engine Validation Errors",
          fieldKey: ["quote", "aggregations", "ValidationErrors", "thirdParty"]
        },
        override: {
          fieldType: FieldType.RichText,
          label: "Pricing Engine Validation Errors",
          fieldKey: ["quote", "aggregations", "ValidationErrors", "override"]
        },
        focused: {
          fieldKey: ["quote", "aggregations", "ValidationErrors", "focused"],
          label: "Pricing Engine Validation Errors",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      // RENTAL GET
      LtvEligibility: {
        thirdParty: {
          fieldType: FieldType.String,
          label: "LTV Eligibility",
          fieldKey: ["quote", "aggregations", "LtvEligibility", "thirdParty"]
        },
        override: {
          fieldType: FieldType.String,
          label: "LTV Eligibility",
          fieldKey: ["quote", "aggregations", "LtvEligibility", "override"]
        },
        focused: {
          fieldKey: ["quote", "aggregations", "LtvEligibility", "focused"],
          label: "LTV Eligibility",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      InterestReserveEscrow: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Interest Reserve Escrow",
          fieldKey: [
            "quote",
            "aggregations",
            "InterestReserveEscrow",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Interest Reserve Escrow",
          fieldKey: [
            "quote",
            "aggregations",
            "InterestReserveEscrow",
            "override"
          ]
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "InterestReserveEscrow",
            "focused"
          ],
          label: "Interest Reserve Amount",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PrepaymentPenaltyType: {
        thirdParty: {
          fieldType: FieldType.SingleSelect,
          label: "Prepayment Penalty",
          fieldKey: [
            "quote",
            "aggregations",
            "PrepaymentPenaltyType",
            "thirdParty"
          ],
          options: createOptionsFromEnum(PrepaymentPenaltyType)
        },
        override: {
          fieldType: FieldType.SingleSelect,
          label: "Prepayment Penalty",
          fieldKey: [
            "quote",
            "aggregations",
            "PrepaymentPenaltyType",
            "override"
          ],
          options: createOptionsFromEnum(PrepaymentPenaltyType)
        },
        focused: {
          label: "Prepayment Penalty",
          fieldKey: [
            "quote",
            "aggregations",
            "PrepaymentPenaltyType",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      NoteRatePercent: {
        thirdParty: {
          fieldType: FieldType.PercentageThreeDecimal,
          label: "Note Rate",
          fieldKey: ["quote", "aggregations", "NoteRatePercent", "thirdParty"]
        },
        override: {
          fieldType: FieldType.PercentageThreeDecimal,
          label: "Note Rate",
          fieldKey: ["quote", "aggregations", "NoteRatePercent", "override"]
        },
        focused: {
          label: "Note Rate",
          fieldKey: ["quote", "aggregations", "NoteRatePercent", "focused"],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      MonthlyPrincipalInterestPaymentAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Monthly Principal & Interest Payment",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyPrincipalInterestPaymentAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Monthly Principal & Interest Payment",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyPrincipalInterestPaymentAmount",
            "override"
          ]
        },
        focused: {
          label: "",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyPrincipalInterestPaymentAmount",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      MonthlyTaxInsurancePaymentAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Monthly Payment of Taxes & Insurance",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyTaxInsurancePaymentAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Monthly Payment of Taxes & Insurance",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyTaxInsurancePaymentAmount",
            "override"
          ]
        },
        focused: {
          label: "Monthly Payment of Taxes & Insurance",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyTaxInsurancePaymentAmount",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      MonthlyHomeownersAssociationAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Monthly Payment of HOA Dues to HOA",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyHomeownersAssociationAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Monthly Payment of HOA Dues to HOA",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyHomeownersAssociationAmount",
            "override"
          ]
        },
        focused: {
          label: "Monthly Payment of HOA Dues to HOA",
          fieldKey: [
            "quote",
            "aggregations",
            "MonthlyHomeownersAssociationAmount",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      TotalMonthlyPITIPaymentAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Total Monthly Payment (PITI)",
          fieldKey: [
            "quote",
            "aggregations",
            "TotalMonthlyPITIPaymentAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Total Monthly Payment (PITI)",
          fieldKey: [
            "quote",
            "aggregations",
            "TotalMonthlyPITIPaymentAmount",
            "override"
          ]
        },
        focused: {
          label: "Total Monthly Payment (PITI)",
          fieldKey: [
            "quote",
            "aggregations",
            "TotalMonthlyPITIPaymentAmount",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      DebtServiceCoverageRatioPercent: {
        thirdParty: {
          fieldType: FieldType.PercentageThreeDecimal,
          label: "Total Rent to Debt Service Coverage Ratio",
          fieldKey: [
            "quote",
            "aggregations",
            "DebtServiceCoverageRatioPercent",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.PercentageThreeDecimal,
          label: "Total Rent to Debt Service Coverage Ratio",
          fieldKey: [
            "quote",
            "aggregations",
            "DebtServiceCoverageRatioPercent",
            "override"
          ]
        },
        focused: {
          label: "Total Rent to Debt Service Coverage Ratio",
          fieldKey: [
            "quote",
            "aggregations",
            "DebtServiceCoverageRatioPercent",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      BuyDownFeeAmount: {
        thirdParty: {
          fieldType: FieldType.Money,
          label: "Buy Down Fee",
          fieldKey: ["quote", "aggregations", "BuyDownFeeAmount", "thirdParty"]
        },
        override: {
          fieldType: FieldType.Money,
          label: "Buy Down Fee",
          fieldKey: ["quote", "aggregations", "BuyDownFeeAmount", "override"]
        },
        focused: {
          label: "N/A",
          fieldKey: ["quote", "aggregations", "BuyDownFeeAmount", "focused"],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      BuyUpFeeReductionAmount: {
        thirdParty: {
          fieldType: FieldType.SignedMoney,
          label: "Buy Up Fee Reduction",
          fieldKey: [
            "quote",
            "aggregations",
            "BuyUpFeeReductionAmount",
            "thirdParty"
          ]
        },
        override: {
          fieldType: FieldType.SignedMoney,
          label: "Buy Up Fee Reduction",
          fieldKey: [
            "quote",
            "aggregations",
            "BuyUpFeeReductionAmount",
            "override"
          ]
        },
        focused: {
          label: "N/A",
          fieldKey: [
            "quote",
            "aggregations",
            "BuyUpFeeReductionAmount",
            "focused"
          ],
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      // Rental POST
      UnderPropertyValuationAmount: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "UnderPropertyValuationAmount",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "UnderPropertyValuationAmount",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "UnderPropertyValuationAmount",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PrimaryBorrowerCreditScore: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PrimaryBorrowerCreditScore",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Number
        },

        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PrimaryBorrowerCreditScore",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PrimaryBorrowerCreditScore",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      StateNY: {
        calculated: {
          fieldKey: ["quote", "aggregations", "StateNY", "calculated"],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "StateNY", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "StateNY", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      InternalL1CRefinanceIndicator: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "InternalL1CRefinanceIndicator",
            "calculated"
          ],
          label: "Is L1C Refinance?",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "InternalL1CRefinanceIndicator",
            "override"
          ],
          label: "Is L1C Refinance?",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "InternalL1CRefinanceIndicator",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      BorrowingEntity: {
        calculated: {
          fieldKey: ["quote", "aggregations", "BorrowingEntity", "calculated"],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "BorrowingEntity", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "BorrowingEntity", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      OverPropertyValuationAmount: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "OverPropertyValuationAmount",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "OverPropertyValuationAmount",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "OverPropertyValuationAmount",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      UnderPropertyValuationTotalCost: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "UnderPropertyValuationTotalCost",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "UnderPropertyValuationTotalCost",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "UnderPropertyValuationTotalCost",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      TotalValuationOrPurchasePriceAmount: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "TotalValuationOrPurchasePriceAmount",
            "calculated"
          ],
          label: "Total Lesser of Purchase Price and As-Is Valuation",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "TotalValuationOrPurchasePriceAmount",
            "override"
          ],
          label: "Total Lesser of Purchase Price and As-Is Valuation",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "TotalValuationOrPurchasePriceAmount",
            "focused"
          ],
          label: "Total Lesser of Purchase Price and As-Is Valuation",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyTypeCondoIndicator: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyTypeCondoIndicator",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyTypeCondoIndicator",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyTypeCondoIndicator",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyValuationAmountUnderIndicator: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyValuationAmountUnderIndicator",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyValuationAmountUnderIndicator",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyValuationAmountUnderIndicator",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      USCitizenshipIndicator: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "USCitizenshipIndicator",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "USCitizenshipIndicator",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "USCitizenshipIndicator",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      SeasonalRental: {
        calculated: {
          fieldKey: ["quote", "aggregations", "SeasonalRental", "calculated"],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        override: {
          fieldKey: ["quote", "aggregations", "SeasonalRental", "override"],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        focused: {
          fieldKey: ["quote", "aggregations", "SeasonalRental", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyTypeMultifamilyIndicator: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyTypeMultifamilyIndicator",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyTypeMultifamilyIndicator",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyTypeMultifamilyIndicator",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      InitialMonthlyInterestOnlyPaymentAmount: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "InitialMonthlyInterestOnlyPaymentAmount",
            "thirdParty"
          ],
          label: "Initial Monthly Interest Only Payment",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "InitialMonthlyInterestOnlyPaymentAmount",
            "override"
          ],
          label: "Initial Monthly Interest Only Payment",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "InitialMonthlyInterestOnlyPaymentAmount",
            "focused"
          ],
          label: "Initial Monthly Interest Only Payment",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineLTARVRatePercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineLTARVRatePercent",
            "thirdParty"
          ],
          label: "Pricing Engine Loan to After Repair Value",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineLTARVRatePercent",
            "override"
          ],
          label: "Pricing Engine Loan to After Repair Value",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineLTARVRatePercent",
            "focused"
          ],
          label: "Pricing Engine Loan to After Repair Value",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      FullyDrawnInterestOnlyPayment: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "FullyDrawnInterestOnlyPayment",
            "thirdParty"
          ],
          label: "Fully Drawn Interest Only Payment",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "FullyDrawnInterestOnlyPayment",
            "override"
          ],
          label: "Fully Drawn Interest Only Payment",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "FullyDrawnInterestOnlyPayment",
            "focused"
          ],
          label: "Fully Drawn Interest Only Payment",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },

      PrimaryGuarantor: {
        calculated: {
          fieldKey: ["quote", "aggregations", "PrimaryGuarantor", "calculated"],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PrimaryGuarantor", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PrimaryGuarantor", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      SumTotalStatementQualifyingBalance: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "SumTotalStatementQualifyingBalance",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "SumTotalStatementQualifyingBalance",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "SumTotalStatementQualifyingBalance",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      AltTier: {
        calculated: {
          fieldKey: ["quote", "aggregations", "AltTier", "calculated"],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        override: {
          fieldKey: ["quote", "aggregations", "AltTier", "override"],
          label: "N/A",
          fieldType: FieldType.Boolean,
          options: booleanOptions
        },
        focused: {
          fieldKey: ["quote", "aggregations", "AltTier", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LesserOfLotOrPurchase: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "LesserOfLotOrPurchase",
            "calculated"
          ],
          label: "Total Property Value Basis",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "LesserOfLotOrPurchase",
            "override"
          ],
          label: "Total Property Value Basis",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "LesserOfLotOrPurchase",
            "focused"
          ],
          label: "Total Property Value Basis",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      DebtStatus: {
        calculated: {
          fieldKey: ["quote", "aggregations", "DebtStatus", "calculated"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(DebtStatusType)
        },
        override: {
          fieldKey: ["quote", "aggregations", "DebtStatus", "override"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(DebtStatusType)
        },
        focused: {
          fieldKey: ["quote", "aggregations", "DebtStatus", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyAddressLineText: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyAddressLineText",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyAddressLineText",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyAddressLineText",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyCityName: {
        calculated: {
          fieldKey: ["quote", "aggregations", "PropertyCityName", "calculated"],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PropertyCityName", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PropertyCityName", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyStateCode: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyStateCode",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PropertyStateCode", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PropertyStateCode", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyPostalCode: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "PropertyPostalCode",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PropertyPostalCode", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PropertyPostalCode", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PropertyType: {
        calculated: {
          fieldKey: ["quote", "aggregations", "PropertyType", "calculated"],
          label: "N/A",
          fieldType: FieldType.String
        },
        override: {
          fieldKey: ["quote", "aggregations", "PropertyType", "override"],
          label: "N/A",
          fieldType: FieldType.String
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PropertyType", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      OriginalPurchaseDate: {
        calculated: {
          fieldKey: [
            "quote",
            "aggregations",
            "OriginalPurchaseDate",
            "calculated"
          ],
          label: "N/A",
          fieldType: FieldType.Date
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "OriginalPurchaseDate",
            "override"
          ],
          label: "N/A",
          fieldType: FieldType.Date
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "OriginalPurchaseDate",
            "focused"
          ],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      BorrowerTier: {
        calculated: {
          fieldKey: ["quote", "aggregations", "BorrowerTier", "calculated"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnum(FixNFlipNBridgePlusTierType)
        },
        override: {
          fieldKey: ["quote", "aggregations", "BorrowerTier", "override"],
          label: "N/A",
          fieldType: FieldType.String,
          options: createOptionsFromEnum(FixNFlipNBridgePlusTierType)
        },
        focused: {
          fieldKey: ["quote", "aggregations", "BorrowerTier", "focused"],
          label: "N/A",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineTotalLoanToAfterRepairValueOverridePercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalLoanToAfterRepairValueOverridePercent",
            "thirdParty"
          ],
          label: "Pricing Engine Total Loan to After Repair Value Override (%)",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalLoanToAfterRepairValueOverridePercent",
            "override"
          ],
          label: "Pricing Engine Total Loan to After Repair Value Override (%)",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalLoanToAfterRepairValueOverridePercent",
            "focused"
          ],
          label: "Pricing Engine Total Loan to After Repair Value Override (%)",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineTotalBlendedLoanToCostOverridePercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalBlendedLoanToCostOverridePercent",
            "thirdParty"
          ],
          label: "Pricing Engine Total Blended Loan to Cost Override (%)",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalBlendedLoanToCostOverridePercent",
            "override"
          ],
          label: "Pricing Engine Total Blended Loan to Cost Override (%)",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalBlendedLoanToCostOverridePercent",
            "focused"
          ],
          label: "Pricing Engine Total Blended Loan to Cost Override (%)",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineTotalPurchaseLoanToCostOverridePercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalPurchaseLoanToCostOverridePercent",
            "thirdParty"
          ],
          label: "Pricing Engine Total Purchase Loan to Cost Override (%)",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalPurchaseLoanToCostOverridePercent",
            "override"
          ],
          label: "Pricing Engine Total Purchase Loan to Cost Override (%)",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineTotalPurchaseLoanToCostOverridePercent",
            "focused"
          ],
          label: "Pricing Engine Total Purchase Loan to Cost Override (%)",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineNoteRateOverridePercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineNoteRateOverridePercent",
            "thirdParty"
          ],
          label: "Pricing Engine Note Rate Override",
          fieldType: FieldType.PercentageThreeDecimal
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineNoteRateOverridePercent",
            "override"
          ],
          label: "Pricing Engine Note Rate Override",
          fieldType: FieldType.PercentageThreeDecimal
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineNoteRateOverridePercent",
            "focused"
          ],
          label: "Pricing Engine Note Rate Override",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineMaxLoanToAfterRepairValuePercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxLoanToAfterRepairValuePercent",
            "thirdParty"
          ],
          label: "Pricing Engine Max Loan to After Repair Value",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxLoanToAfterRepairValuePercent",
            "override"
          ],
          label: "Pricing Engine Max Loan to After Repair Value",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxLoanToAfterRepairValuePercent",
            "focused"
          ],
          label: "Pricing Engine Max Loan to After Repair Value",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineMaxBlendedLoanToCostPercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxBlendedLoanToCostPercent",
            "thirdParty"
          ],
          label: "Pricing Engine Max Blended Loan to Cost",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxBlendedLoanToCostPercent",
            "override"
          ],
          label: "Pricing Engine Max Blended Loan to Cost",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxBlendedLoanToCostPercent",
            "focused"
          ],
          label: "Pricing Engine Max Blended Loan to Cost",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PricingEngineMaxPurchaseLoanToCostPercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxPurchaseLoanToCostPercent",
            "thirdParty"
          ],
          label: "Pricing Engine Max Purchase Loan to Cost",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxPurchaseLoanToCostPercent",
            "override"
          ],
          label: "Pricing Engine Max Purchase Loan to Cost",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "PricingEngineMaxPurchaseLoanToCostPercent",
            "focused"
          ],
          label: "Pricing Engine Max Purchase Loan to Cost",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      Margin: {
        thirdParty: {
          fieldKey: ["quote", "aggregations", "Margin", "thirdParty"],
          label: "Margin",
          fieldType: FieldType.PercentageThreeDecimal
        },
        override: {
          fieldKey: ["quote", "aggregations", "Margin", "override"],
          label: "Margin",
          fieldType: FieldType.PercentageThreeDecimal
        },
        focused: {
          fieldKey: ["quote", "aggregations", "Margin", "focused"],
          label: "Margin",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      FloorPercent: {
        thirdParty: {
          fieldKey: ["quote", "aggregations", "FloorPercent", "thirdParty"],
          label: "Floor",
          fieldType: FieldType.PercentageThreeDecimal
        },
        override: {
          fieldKey: ["quote", "aggregations", "FloorPercent", "override"],
          label: "Floor",
          fieldType: FieldType.PercentageThreeDecimal
        },
        focused: {
          fieldKey: ["quote", "aggregations", "FloorPercent", "focused"],
          label: "Floor",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      LifetimeCapPercent: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "LifetimeCapPercent",
            "thirdParty"
          ],
          label: "Lifetime Cap",
          fieldType: FieldType.Percentage
        },
        override: {
          fieldKey: ["quote", "aggregations", "LifetimeCapPercent", "override"],
          label: "Lifetime Cap",
          fieldType: FieldType.Percentage
        },
        focused: {
          fieldKey: ["quote", "aggregations", "LifetimeCapPercent", "focused"],
          label: "Lifetime Cap",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      DeferredOriginationFeeAmount: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "DeferredOriginationFeeAmount",
            "thirdParty"
          ],
          label: "Deferred Origination Fee (DO NOT OVERRIDE)",
          fieldType: FieldType.Money
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "DeferredOriginationFeeAmount",
            "override"
          ],
          label: "Deferred Origination Fee (DO NOT OVERRIDE)",
          fieldType: FieldType.Money
        },
        focused: {
          fieldKey: [
            "quote",
            "aggregations",
            "DeferredOriginationFeeAmount",
            "focused"
          ],
          label: "Deferred Origination Fee (DO NOT OVERRIDE)",
          fieldType: FieldType.Money
        }
      },
      BlendedLTCAdjuster: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "BlendedLTCAdjuster",
            "thirdParty"
          ],
          label: "Blended LTC Adjuster",
          fieldType: FieldType.Integer
        },
        override: {
          fieldKey: ["quote", "aggregations", "BlendedLTCAdjuster", "override"],
          label: "Blended LTC Adjuster",
          fieldType: FieldType.Integer
        },
        focused: {
          fieldKey: ["quote", "aggregations", "BlendedLTCAdjuster", "focused"],
          label: "Blended LTC Adjuster",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      PurchaseLTCAdjuster: {
        thirdParty: {
          fieldKey: [
            "quote",
            "aggregations",
            "PurchaseLTCAdjuster",
            "thirdParty"
          ],
          label: "Purchase LTC Adjuster",
          fieldType: FieldType.Integer
        },
        override: {
          fieldKey: [
            "quote",
            "aggregations",
            "PurchaseLTCAdjuster",
            "override"
          ],
          label: "Purchase LTC Adjuster",
          fieldType: FieldType.Integer
        },
        focused: {
          fieldKey: ["quote", "aggregations", "PurchaseLTCAdjuster", "focused"],
          label: "Purchase LTC Adjuster",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      },
      ARVAdjuster: {
        thirdParty: {
          fieldKey: ["quote", "aggregations", "ARVAdjuster", "thirdParty"],
          label: "ARV Adjuster",
          fieldType: FieldType.Integer
        },
        override: {
          fieldKey: ["quote", "aggregations", "ARVAdjuster", "override"],
          label: "ARV Adjuster",
          fieldType: FieldType.Integer
        },
        focused: {
          fieldKey: ["quote", "aggregations", "ARVAdjuster", "focused"],
          label: "ARV Adjuster",
          fieldType: FieldType.SingleSelect,
          options: createOptionsFromEnumAllowUndefined(AggregationFocusType)
        }
      }
    }
  },
  LOCAvailableAmount: {
    fieldType: FieldType.Money,
    label: "Unpaid Principal Balance",
    fieldKey: ["LOCAvailableAmount"]
  },
  LOCExpirationDate: {
    fieldType: FieldType.Date,
    label: "LOC Expiration Date",
    fieldKey: ["LOCExpirationDate"]
  },
  CashOutAmount: {
    fieldType: FieldType.Money,
    label: "Total Cash Out Amount",
    fieldKey: ["CashOutAmount"]
  },
  DownPaymentAmount: {
    fieldType: FieldType.Money,
    label: "Down Payment Amount",
    fieldKey: ["DownPaymentAmount"]
  },
  AggregateOccupancyPercent: {
    fieldType: FieldType.Percentage,
    label: "Aggregated Occupancy Percent",
    fieldKey: ["AggregateOccupancyPercent"]
  },
  PolicySectionNumber: {
    fieldType: FieldType.String,
    label: "Policy Section",
    fieldKey: ["PolicySectionNumber"]
  },
  LenderIdentifier: {
    fieldType: FieldType.SingleSelect,
    label: "Lender Company Name",
    fieldKey: ["LenderIdentifier"],
    options: createOptionsFromEnum(LenderIdentifierType)
  },
  LoanIdentifier: {
    fieldType: FieldType.String,
    label: "Loan Number",
    fieldKey: ["LoanIdentifier"]
  },
  ApplicationReceivedDate: {
    fieldType: FieldType.Date,
    label: "Application Date",
    fieldKey: ["ApplicationReceivedDate"]
  },
  LOCIssuanceDate: {
    fieldType: FieldType.Date,
    label: "Line of Credit Issuance",
    fieldKey: ["LOCIssuanceDate"]
  },
  PreliminaryUnderwritingDecisionDate: {
    fieldType: FieldType.Date,
    label: "Preliminary Underwriting Decision",
    fieldKey: ["PreliminaryUnderwritingDecisionDate"]
  },
  FinalUnderwritingDecision: {
    fieldType: FieldType.String,
    label: "Final Underwriting Decision",
    fieldKey: ["FinalUnderwritingDecision"]
  },
  InitialTaskGenerationDate: {
    fieldType: FieldType.Date,
    label: "Initial Task Generation",
    fieldKey: ["InitialTaskGenerationDate"]
  },
  ClearToCloseDate: {
    fieldType: FieldType.Date,
    label: "Clear to Close",
    fieldKey: ["ClearToCloseDate"]
  },
  LoanName: {
    fieldType: FieldType.String,
    label: "Loan Name",
    fieldKey: ["LoanName"]
  },
  Channel: {
    fieldType: FieldType.SingleSelect,
    label: "Sales Channel",
    fieldKey: ["Channel"],
    options: createOptionsFromEnum(ChannelType)
  },
  RealEstateProgramType: {
    fieldType: FieldType.SingleSelect,
    label: "Real Estate Program",
    fieldKey: ["RealEstateProgramType"],
    options: createOptionsFromEnum(RealEstateProgramType)
  },
  EmployeeLoanIndicator: {
    fieldType: FieldType.Boolean,
    label: "Lima One - Employee Loan?",
    fieldKey: ["EmployeeLoanIndicator"],
    options: booleanOptions
  },
  TotalPropertyCount: {
    fieldType: FieldType.Integer,
    label: "Number of Properties",
    fieldKey: ["TotalPropertyCount"]
  },
  RecourseType: {
    fieldType: FieldType.SingleSelect,
    label: "Recourse-Guaranty",
    fieldKey: ["RecourseType"],
    options: createOptionsFromEnum(RecourseType)
  },
  WireReleaseDate: {
    fieldType: FieldType.Date,
    label: "Wire Release Date",
    fieldKey: ["WireReleaseDate"]
  },
  LoanFundingDate: {
    fieldType: FieldType.Date,
    label: "Close of Escrow Date",
    fieldKey: ["LoanFundingDate"]
  },

  PaymentDueDay: {
    fieldType: FieldType.SingleSelect,
    label: "Payment Due Day",
    fieldKey: ["PaymentDueDay"],
    options: createOptionsFromEnum(PaymentDueDayType)
  },
  NextPaymentDueDate: {
    fieldType: FieldType.Date,
    label: "Next Payment Due Date",
    fieldKey: ["NextPaymentDueDate"]
  },

  AmortizationType: {
    fieldType: FieldType.SingleSelect,
    label: "Note Type",
    fieldKey: ["AmortizationType"],
    options: createOptionsFromEnum(AmortizationType)
  },
  ExecutionType: {
    fieldType: FieldType.SingleSelect,
    label: "Loan Execution Type",
    fieldKey: ["ExecutionType"],
    options: createOptionsFromEnum(ExecutionType)
  },
  LoanPaymentType: {
    fieldType: FieldType.SingleSelect,
    label: "Payment Type",
    fieldKey: ["LoanPaymentType"],
    options: createOptionsFromEnum(LoanPaymentType)
  },
  LoanTermPeriodMonthCount: {
    fieldType: FieldType.SingleSelect,
    label: "Loan Term (Months)",
    fieldKey: ["LoanTermPeriodMonthCount"],
    options: createOptionsFromEnum(LoanTermPeriodMonthCount)
  },
  LoanAmortizationPeriodMonthCount: {
    fieldType: FieldType.Integer,
    label: "Amortization Period (Months)",
    fieldKey: ["LoanAmortizationPeriodMonthCount"]
  },
  LoanInterestOnlyPeriodMonthCount: {
    fieldType: FieldType.Integer,
    label: "IO Period (Months)",
    fieldKey: ["LoanInterestOnlyPeriodMonthCount"]
  },
  LoanAfterInterestOnlyPeriodMonthCount: {
    fieldType: FieldType.Integer,
    label: "Amortization After IO Period (Months)",
    fieldKey: ["LoanAfterInterestOnlyPeriodMonthCount"]
  },
  InterestAccrualMethodType: {
    fieldType: FieldType.SingleSelect,
    label: "Interest Rate Accrual Method",
    fieldKey: ["InterestAccrualMethodType"],
    options: createOptionsFromEnum(InterestAccrualMethodType)
  },

  LoanAmortizationPeriodYearCount: {
    fieldType: FieldType.Year,
    label: "Amortization Period (Years)",
    fieldKey: ["LoanAmortizationPeriodYearCount"]
  },
  IndexSourceType: {
    fieldType: FieldType.SingleSelect,
    label: "Interest Index",
    fieldKey: ["IndexSourceType"],
    options: createOptionsFromEnum(IndexSourceType)
  },
  CapitalStructureType: {
    fieldType: FieldType.SingleSelect,
    label: "Cap Structure",
    fieldKey: ["CapitalStructureType"],
    options: createOptionsFromEnum(CapitalStructureType)
  },
  MarginRatePercent: {
    fieldType: FieldType.PercentageThreeDecimal,
    label: "Margin",
    fieldKey: ["MarginRatePercent"]
  },
  FloorRatePercent: {
    fieldType: FieldType.PercentageThreeDecimal,
    label: "Floor",
    fieldKey: ["FloorRatePercent"]
  },
  LifetimeCapRatePercent: {
    fieldType: FieldType.PercentageThreeDecimal,
    label: "Lifetime Cap",
    fieldKey: ["LifetimeCapRatePercent"]
  },
  ARMRepriceRoundingRatePercentType: {
    fieldType: FieldType.SingleSelect,
    label: "ARM Reprice Rounding %",
    fieldKey: ["ARMRepriceRoundingRatePercentType"],
    options: createOptionsFromEnum(ARMRepriceRoundingRatePercentType)
  },
  ARMRepriceRoundingMethodType: {
    fieldType: FieldType.SingleSelect,
    label: "ARM Reprice Rounding Method",
    fieldKey: ["ARMRepriceRoundingMethodType"],
    options: createOptionsFromEnum(ARMRepriceRoundingMethodType)
  },
  ARMRepriceLookbackType: {
    fieldType: FieldType.SingleSelect,
    label: "ARM Reprice Lookback",
    fieldKey: ["ARMRepriceLookbackType"],
    options: createOptionsFromEnum(ARMRepriceLookbackType)
  },

  UCCExpirationDate: {
    fieldType: FieldType.Date,
    label: "UCC Expiration Date",
    fieldKey: ["UCCExpirationDate"]
  },
  DefaultInterestRatePercent: {
    fieldType: FieldType.Percentage,
    label: "Default Interest Rate",
    fieldKey: ["DefaultInterestRatePercent"]
  },
  WarehouseBankType: {
    fieldType: FieldType.SingleSelect,
    label: "Warehouse Bank",
    fieldKey: ["WarehouseBankType"],
    options: createOptionsFromEnum(WarehouseBankType)
  },
  WarehouseBankStatusType: {
    fieldType: FieldType.SingleSelect,
    label: "Warehouse Status",
    fieldKey: ["WarehouseBankStatusType"],
    options: createOptionsFromEnum(WarehouseBankStatusType)
  },
  ACHElectedIndicator: {
    fieldType: FieldType.Boolean,
    label: "ACH Elected?",
    fieldKey: ["ACHElectedIndicator"],
    options: booleanOptions
  },
  SellerHUDAddedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Seller HUD Added?",
    fieldKey: ["SellerHUDAddedIndicator"],
    options: booleanOptions
  },
  PurchaserWarrantyDeedAddedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Purchaser Warranty Deed Added?",
    fieldKey: ["PurchaserWarrantyDeedAddedIndicator"],
    options: booleanOptions
  },
  FundingShieldStatusType: {
    fieldType: FieldType.SingleSelect,
    label: "FundingShield Status",
    fieldKey: ["FundingShieldStatusType"],
    options: createOptionsFromEnum(FundingShieldStatusType)
  },
  HaircutAmount: {
    fieldType: FieldType.Money,
    label: "Haircut Amount",
    fieldKey: ["HaircutAmount"]
  },
  PostClosingNotes: {
    fieldType: FieldType.RichText,
    label: "Funding Specialist Notes",
    fieldKey: ["PostClosingNotes"]
  },
  CollateralPackageStatusType: {
    fieldType: FieldType.SingleSelect,
    label: "Collateral Package Status",
    fieldKey: ["CollateralPackageStatusType"],
    options: createOptionsFromEnum(CollateralPackageStatusType)
  },
  CollateralPackageTrackingNumber: {
    fieldType: FieldType.String,
    label: "Collateral Package Tracking Information",
    fieldKey: ["CollateralPackageTrackingNumber"]
  },
  Ledger: {
    AppraisalDepositAmount: {
      fieldType: FieldType.Money,
      label: "Appraisal Deposit",
      fieldKey: ["Ledger", "AppraisalDepositAmount"]
    },
    AppraisalFeeAmount: {
      fieldType: FieldType.Money,
      label: "Appraisal Fee",
      fieldKey: ["Ledger", "AppraisalFeeAmount"]
    },
    CreditReportFeeAmount: {
      fieldType: FieldType.Money,
      label: "Credit Report Fee",
      fieldKey: ["Ledger", "CreditReportFeeAmount"]
    },

    InterestReserveAmount: {
      fieldType: FieldType.Money,
      label: "Interest Reserve Amount",
      fieldKey: ["Ledger", "InterestReserveAmount"]
    },
    NewYorkProcessingFeeAmount: {
      fieldType: FieldType.Money,
      label: "New York Processing Fee",
      fieldKey: ["Ledger", "NewYorkProcessingFeeAmount"]
    },
    CondoCertificationFeeAmount: {
      fieldType: FieldType.Money,
      label: "Condo Certification Fee",
      fieldKey: ["Ledger", "CondoCertificationFeeAmount"]
    },
    ContractorReviewFeeAmount: {
      fieldType: FieldType.Money,
      label: "Contractor Review Fee",
      fieldKey: ["Ledger", "ContractorReviewFeeAmount"]
    },
    FeasibilityReviewFeeAmount: {
      fieldType: FieldType.Money,
      label: "Feasibility Review Fee",
      fieldKey: ["Ledger", "FeasibilityReviewFeeAmount"]
    },
    LenderCounselFeeAmount: {
      fieldType: FieldType.Money,
      label: "Lender Counsel Fee",
      fieldKey: ["Ledger", "LenderCounselFeeAmount"]
    }
  },

  LienPosition: {
    fieldType: FieldType.Number,
    label: "Lien Position",
    fieldKey: ["LienPosition"]
  },
  UCCFiledIndicator: {
    fieldType: FieldType.Boolean,
    label: "UCC Filed?",
    fieldKey: ["UCCFiledIndicator"],
    options: booleanOptions
  },

  Integrations: {
    WireInsuranceCertificate: {
      FundingShield: {
        userFirstName: {
          fieldType: FieldType.String,
          label: "Funding Shield User First Name",
          fieldKey: [
            "Integrations",
            IntegrationType.WireInsuranceCertificate,
            WireInsuranceVendorType.FundingShield,
            "userFirstName"
          ]
        },
        userLastName: {
          fieldType: FieldType.String,
          label: "Funding Shield User Last Name",
          fieldKey: [
            "Integrations",
            IntegrationType.WireInsuranceCertificate,
            WireInsuranceVendorType.FundingShield,
            "userLastName"
          ]
        },
        userEmail: {
          fieldType: FieldType.Email,
          label: "Funding Shield User Email",
          fieldKey: [
            "Integrations",
            IntegrationType.WireInsuranceCertificate,
            WireInsuranceVendorType.FundingShield,
            "userEmail"
          ]
        },
        rushOrderIndicator: {
          fieldType: FieldType.Boolean,
          label: "Funding Shield Rush Order?",
          fieldKey: [
            "Integrations",
            IntegrationType.WireInsuranceCertificate,
            WireInsuranceVendorType.FundingShield,
            "rushOrderIndicator"
          ],
          options: mapEnumValues(BooleanSelectType)
        }
      }
    }
  },
  TitleCompany: {
    AddressLineText: {
      ...addressFieldSpecs.AddressLineText,
      label: "Closing Office Address Line 1",
      fieldKey: ["TitleCompany", "AddressLineText"]
    },
    AddressUnitIdentifier: {
      ...addressFieldSpecs.AddressUnitIdentifier,
      label: "Closing Office Unit Identifier",
      fieldKey: ["TitleCompany", "AddressUnitIdentifier"]
    },
    CityName: {
      ...addressFieldSpecs.CityName,
      label: "Closing Office City Name",
      fieldKey: ["TitleCompany", "CityName"]
    },
    CountyName: {
      ...addressFieldSpecs.CountyName,
      label: "Closing Office County Name",
      fieldKey: ["TitleCompany", "CountyName"]
    },
    PostalCode: {
      ...addressFieldSpecs.PostalCode,
      label: "Closing Office Zip Code",
      fieldKey: ["TitleCompany", "PostalCode"]
    },
    StateCode: {
      ...addressFieldSpecs.StateCode,
      label: "Closing Office State Code",
      fieldKey: ["TitleCompany", "StateCode"]
    },
    StateName: {
      ...addressFieldSpecs.StateName,
      label: "Closing Office State Name",
      fieldKey: ["TitleCompany", "StateName"]
    },
    Attorney: {
      FullName: {
        fieldType: FieldType.String,
        label: "Attorney",
        fieldKey: ["TitleCompany", "Attorney", "FullName"]
      }
    },
    FullName: {
      fieldType: FieldType.String,
      label: "Closing Office Company Name",
      fieldKey: ["TitleCompany", "FullName"]
    },
    ClosingAgentContactPointTelephoneValue: {
      fieldType: FieldType.Phone,
      label: "Closing Agent Phone Number",
      fieldKey: ["TitleCompany", "ClosingAgentContactPointTelephoneValue"]
    },
    ClosingAgentContactPointEmailValue: {
      fieldType: FieldType.Email,
      label: "Closing Agent Email Address",
      fieldKey: ["TitleCompany", "ClosingAgentContactPointEmailValue"]
    },
    ClosingAgentFirstName: {
      fieldType: FieldType.String,
      label: "Closing Agent Contact First Name",
      fieldKey: ["TitleCompany", "ClosingAgentFirstName"]
    },
    ClosingAgentLastName: {
      fieldType: FieldType.String,
      label: "Closing Agent Contact Last Name",
      fieldKey: ["TitleCompany", "ClosingAgentLastName"]
    },
    TitleInsuranceUnderwriter: {
      fieldType: FieldType.String,
      label: "Title Insurance Underwriter",
      fieldKey: ["TitleCompany", "TitleInsuranceUnderwriter"]
    }
  },
  BlendedLTCLoanAmount: {
    fieldType: FieldType.Money,
    label: "Blended LTC Maximum Loan Amount",
    fieldKey: ["BlendedLTCLoanAmount"]
  },
  ConstructionLTCRatePercent: {
    fieldType: FieldType.Percentage,
    label: "Max Construction Budget Loan to Cost",
    fieldKey: ["ConstructionLTCRatePercent"]
  },
  TotalPropertyValuationAmount: {
    fieldType: FieldType.Money,
    label: "Value",
    fieldKey: ["TotalPropertyValuationAmount"]
  },
  MaxARVAmount: {
    fieldType: FieldType.Money,
    label: "After Repair Value",
    fieldKey: ["MaxARVAmount"]
  },
  InitialAdvancedFundAmount: {
    fieldType: FieldType.Money,
    label: "Initially Advanced Funds",
    fieldKey: ["InitialAdvancedFundAmount"]
  },
  TotalLoanFeesAndClosingCostAmount: {
    fieldType: FieldType.Money,
    label: "Total Loan Fees & Closing Costs",
    fieldKey: ["TotalLoanFeesAndClosingCostAmount"]
  },
  ClosingAnalystUserId: {
    fieldType: FieldType.SingleSelect,
    label: "Closing Analyst",
    fieldKey: ["ClosingAnalystUserId"],
    componentOverride: createCustomComponentConfig({
      component: LOSUserSearch,
      props: {
        showRoles: [UserRoleType.ClosingAnalyst]
      }
    })
  },
  PromissoryNoteComments: {
    fieldType: FieldType.RichText,
    label: "Promissory Note Comments",
    fieldKey: ["PromissoryNoteComments"]
  },
  ShippingCompanyType: {
    fieldType: FieldType.SingleSelect,
    label: "Shipping Company",
    fieldKey: ["ShippingCompanyType"],
    options: createOptionsFromEnum(ShippingCompanyType)
  },
  NoteToWarehouseTrackingNumber: {
    fieldType: FieldType.String,
    label: "Note to Warehouse Tracking Number",
    fieldKey: ["NoteToWarehouseTrackingNumber"]
  },
  SettlementStatementFileNumber: {
    fieldType: FieldType.String,
    label: "Settlement Statement File Number",
    fieldKey: ["SettlementStatementFileNumber"]
  },
  PersonalGuarantorsCount: {
    fieldType: FieldType.Number,
    label: "Number of Personal Guarantors",
    fieldKey: ["PersonalGuarantorsCount"]
  },
  PowerOfAttorneyFullName: {
    fieldType: FieldType.String,
    label: "Power of Attorney",
    fieldKey: ["PowerOfAttorneyFullName"]
  },
  AllongeOrAssignmentSentToWarehouseIndicator: {
    fieldType: FieldType.Boolean,
    label: "Allonge/Assignment Sent to Warehouse",
    fieldKey: ["AllongeOrAssignmentSentToWarehouseIndicator"],
    options: booleanOptions
  },
  GeneralContractor: {
    FullName: {
      fieldType: FieldType.String,
      label: "General Contractor Company",
      fieldKey: ["GeneralContractor", "FullName"]
    },
    RepresentativeFirstName: {
      fieldType: FieldType.String,
      label: "General Contractor First Name",
      fieldKey: ["GeneralContractor", "RepresentativeFirstName"]
    },
    RepresentativeLastName: {
      fieldType: FieldType.String,
      label: "General Contractor Last Name",
      fieldKey: ["GeneralContractor", "RepresentativeLastName"]
    },
    ContactPointTelephoneValue: {
      fieldType: FieldType.Phone,
      label: "General Contractor Phone Number",
      fieldKey: ["GeneralContractor", "ContactPointTelephoneValue"]
    },
    ContactPointEmailValue: {
      fieldType: FieldType.Email,
      label: "General Contractor Email Address",
      fieldKey: ["GeneralContractor", "ContactPointEmailValue"]
    },
    CapitalExpenditureAmount: {
      fieldType: FieldType.Money,
      label: "Capital Expenditure",
      fieldKey: ["GeneralContractor", "CapitalExpenditureAmount"]
    }
  },
  Broker: {
    FullName: {
      fieldType: FieldType.String,
      label: "Broker Company",
      fieldKey: ["Broker", "FullName"]
    },
    RepresentativeFirstName: {
      fieldType: FieldType.String,
      label: "Broker First Name",
      fieldKey: ["Broker", "RepresentativeFirstName"]
    },
    RepresentativeLastName: {
      fieldType: FieldType.String,
      label: "Broker Last Name",
      fieldKey: ["Broker", "RepresentativeLastName"]
    },
    ContactPointTelephoneValue: {
      fieldType: FieldType.Phone,
      label: "Broker Phone Number",
      fieldKey: ["Broker", "ContactPointTelephoneValue"]
    },
    ContactPointEmailValue: {
      fieldType: FieldType.Email,
      label: "Broker Email Address",
      fieldKey: ["Broker", "ContactPointEmailValue"]
    },
    NMLSIdentifier: {
      fieldType: FieldType.String,
      label: "Broker NMLS ID",
      fieldKey: ["Broker", "NMLSIdentifier"]
    },
    BrokerWiringInstructionsIndicator: {
      fieldType: FieldType.Boolean,
      label: "Broker Wiring Instructions",
      fieldKey: ["Broker", "BrokerWiringInstructionsIndicator"],
      options: booleanOptions
    }
  },
  Lender: {
    AddressLineText: {
      ...addressFieldSpecs.AddressLineText,
      label: "Lender Address Line 1",
      fieldKey: ["Lender", "AddressLineText"]
    },
    AddressUnitIdentifier: {
      ...addressFieldSpecs.AddressUnitIdentifier,
      label: "Lender Unit Identifier",
      fieldKey: ["Lender", "AddressUnitIdentifier"]
    },
    CityName: {
      ...addressFieldSpecs.CityName,
      label: "Lender City Name",
      fieldKey: ["Lender", "CityName"]
    },
    CountyName: {
      ...addressFieldSpecs.CountyName,
      label: "Lender County Name",
      fieldKey: ["Lender", "CountyName"]
    },
    PostalCode: {
      ...addressFieldSpecs.PostalCode,
      label: "Lender Zip Code",
      fieldKey: ["Lender", "PostalCode"]
    },
    StateCode: {
      ...addressFieldSpecs.StateCode,
      label: "Lender State Code",
      fieldKey: ["Lender", "StateCode"]
    },
    StateName: {
      ...addressFieldSpecs.StateName,
      label: "Lender State Name",
      fieldKey: ["Lender", "StateName"]
    },
    FormationState: {
      fieldType: FieldType.SingleSelect,
      label: "Lender Formation State Name",
      fieldKey: ["Lender", "FormationState"],
      options: createOptionsFromEnum(StateName)
    },
    EntityType: {
      fieldType: FieldType.SingleSelect,
      label: "Lender Entity Type",
      fieldKey: ["Lender", "EntityType"],
      options: createOptionsFromEnum(LenderEntityType)
    }
  },
  PropertyModelType: {
    fieldType: FieldType.SingleSelect,
    label: "Property Model",
    fieldKey: ["PropertyModelType"],
    options: createOptionsFromEnum(PropertyModelType)
  },
  PropertyManagement: {
    PropertyManagerType: {
      fieldType: FieldType.SingleSelect,
      label: "How are properties managed?",
      fieldKey: ["PropertyManagement", "PropertyManagerType"],
      options: createOptionsFromEnum(PropertyManagerType)
    },
    ContactPointEmailValue: {
      fieldType: FieldType.Email,
      label: "Property Management Email Address",
      fieldKey: ["PropertyManagement", "ContactPointEmailValue"]
    },
    ContactPointTelephoneValue: {
      fieldType: FieldType.Phone,
      label: "Property Management Phone Number",
      fieldKey: ["PropertyManagement", "ContactPointTelephoneValue"]
    },
    FullAddressName: {
      fieldType: FieldType.String,
      label: "Property Management Full Address",
      fieldKey: ["PropertyManagement", "FullAddressName"]
    },
    FullName: {
      fieldType: FieldType.String,
      label: "Property Management Company",
      fieldKey: ["PropertyManagement", "FullName"]
    },
    PropertyManagementComments: {
      fieldType: FieldType.RichText,
      label: "Property Management Comments",
      fieldKey: ["PropertyManagement", "PropertyManagementComments"]
    },
    RepresentativeFullName: {
      fieldType: FieldType.String,
      label: "Property Management Primary Contact",
      fieldKey: ["PropertyManagement", "RepresentativeFullName"]
    }
  },
  LastPaymentDueDate: {
    fieldType: FieldType.Date,
    label: "Last Payment Due Date",
    fieldKey: ["LastPaymentDueDate"]
  },
  GrossSpreadPercent: {
    fieldType: FieldType.Percentage,
    label: "Gross Spread Percent",
    fieldKey: ["GrossSpreadPercent"]
  },
  GracePeriodDays: {
    fieldType: FieldType.Number,
    label: "Grace Period Days",
    fieldKey: ["GracePeriodDays"]
  },
  LetterOfIntentSignedDate: {
    fieldType: FieldType.Date,
    label: "Letter of Intent Signed Date",
    fieldKey: ["LetterOfIntentSignedDate"]
  },
  ReleasePercent: {
    fieldType: FieldType.Percentage,
    label: "Release Percent",
    fieldKey: ["ReleasePercent"]
  },
  LateFeePercent: {
    fieldType: FieldType.Percentage,
    label: "Late Fee Percent",
    fieldKey: ["LateFeePercent"]
  },
  Exceptions: {
    ExceptionType: {
      label: "Exception Type",
      fieldType: FieldType.MultiSelect,
      fieldKey: ["Exceptions", "ExceptionType"],
      options: createOptionsFromEnum(ExceptionType)
    },
    PolicySection: {
      label: "Policy Section",
      fieldKey: ["Exceptions", "PolicySection"],
      fieldType: FieldType.MultiSelect,
      options: createOptionsFromEnum(ExceptionPolicy)
    },
    ExceptionCommentary: {
      fieldType: FieldType.RichText,
      label: "Exception Commentary",
      fieldKey: ["Exceptions", "ExceptionCommentary"]
    },
    ExceptionApprovedBy: {
      fieldType: FieldType.SingleSelect,
      label: "Exception Approved By",
      fieldKey: ["Exceptions", "ExceptionApprovedBy"],
      options: createOptionsFromEnum(DealExceptionApprovedBy)
    }
  },
  PromissoryNoteRequestedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Note Requested",
    fieldKey: ["PromissoryNoteRequestedIndicator"],
    options: booleanOptions
  },
  PromissoryNoteShippedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Note Shipped",
    fieldKey: ["PromissoryNoteShippedIndicator"],
    options: booleanOptions
  },
  PromissoryNoteTrackingNumber: {
    fieldType: FieldType.String,
    label: "Note to MFA Tracking Number",
    fieldKey: ["PromissoryNoteTrackingNumber"]
  },
  AllongeAssignmentSentToWarehouseIndicator: {
    fieldType: FieldType.Boolean,
    label: "Allonge and Assignment Sent to Warehouse",
    fieldKey: ["AllongeAssignmentSentToWarehouseIndicator"],
    options: booleanOptions
  },
  AllongeAssignmentTrackingNumber: {
    fieldType: FieldType.String,
    label: "Allonge and Assignment Tracking Number",
    fieldKey: ["AllongeAssignmentTrackingNumber"]
  },
  NotaryUserId: {
    fieldType: FieldType.SingleSelect,
    label: "Notary",
    fieldKey: ["NotaryUserId"],
    componentOverride: createCustomComponentConfig({
      component: LOSUserSearch,
      props: {
        showRoles: [UserRoleType.Notary]
      }
    })
  },
  NotaryExpirationDate: {
    fieldType: FieldType.Date,
    label: "Notary Expiration Date",
    fieldKey: ["NotaryExpirationDate"]
  },
  LoanPackageTrackingNumber: {
    fieldType: FieldType.String,
    label: "Physical Executed Loan Package Tracking Number",
    fieldKey: ["LoanPackageTrackingNumber"]
  },
  LoanPackageNoteTrackingNumber: {
    fieldType: FieldType.String,
    label: "Note to Warehouse Tracking Number",
    fieldKey: ["LoanPackageNoteTrackingNumber"]
  },
  FundingAndDisbursementApprovalIndicator: {
    fieldType: FieldType.Boolean,
    label: "Funding and Disbursement Approved",
    fieldKey: ["FundingAndDisbursementApprovalIndicator"],
    options: booleanOptions
  },
  LoanPackageInternationalExecutionIndicator: {
    fieldType: FieldType.Boolean,
    label: "International Execution?",
    fieldKey: ["LoanPackageInternationalExecutionIndicator"],
    options: booleanOptions
  },
  LoanPackageNoteReceivedByLenderIndicator: {
    fieldType: FieldType.Boolean,
    label: "Note Received by Lima One?",
    fieldKey: ["LoanPackageNoteReceivedByLenderIndicator"],
    options: booleanOptions
  },
  LoanPackageChecksReceivedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Checks Received",
    fieldKey: ["LoanPackageChecksReceivedIndicator"],
    options: booleanOptions
  },
  LoanPackageCheckNumber: {
    fieldType: FieldType.String,
    label: "Check Number",
    fieldKey: ["LoanPackageCheckNumber"]
  },
  LoanPackageCheckAmount: {
    fieldType: FieldType.Money,
    label: "Check Amount",
    fieldKey: ["LoanPackageCheckAmount"]
  },

  UnderwriterApprovalStatus: {
    fieldType: FieldType.SingleSelect,
    label: "Underwriter Approval Status",
    fieldKey: ["UnderwriterApprovalStatus"],
    options: createOptionsFromEnum(UnderwriterApprovalStatusType)
  },
  UnderwriterApprovalComments: {
    fieldType: FieldType.RichText,
    label: "Underwriter Approval Comments",
    fieldKey: ["UnderwriterApprovalComments"]
  },
  AssetSummaryReportIndicator: {
    fieldType: FieldType.Boolean,
    label: "Asset Summary Report",
    fieldKey: ["AssetSummaryReportIndicator"],
    options: booleanOptions
  },
  CreditMemorandumIndicator: {
    fieldType: FieldType.Boolean,
    label: "Credit Memorandum",
    fieldKey: ["CreditMemorandumIndicator"],
    options: booleanOptions
  },
  CreditReviewCommitteeApprovalIndicator: {
    fieldType: FieldType.Boolean,
    label: "Credit Review Committee Approval",
    fieldKey: ["CreditReviewCommitteeApprovalIndicator"],
    options: booleanOptions
  },
  ProgramDirectorApprovalIndicator: {
    fieldType: FieldType.Boolean,
    label: "Program Director Approval",
    fieldKey: ["ProgramDirectorApprovalIndicator"],
    options: booleanOptions
  },
  ProgramDirectorComments: {
    fieldType: FieldType.RichText,
    label: "Program Director Comments",
    fieldKey: ["ProgramDirectorComments"]
  },
  PricingEngineExceptionStatus: {
    fieldType: FieldType.SingleSelect,
    label: "Exception Status",
    fieldKey: ["PricingEngineExceptionStatus"],
    options: createOptionsFromEnum(PricingEngineExceptionStatusType)
  },
  ChargentTransactionIdentifier: {
    fieldType: FieldType.String,
    label: "Chargent Transaction ID",
    fieldKey: ["ChargentTransactionIdentifier"]
  },
  ChargentGatewayIdentifier: {
    fieldType: FieldType.String,
    label: "Chargent Gateway ID",
    fieldKey: ["ChargentGatewayIdentifier"]
  },
  ChargentCollectionAmount: {
    fieldType: FieldType.Money,
    label: "Chargent Collection Amount",
    fieldKey: ["ChargentCollectionAmount"]
  },
  ValuationFeesCollectedBySalesIndicator: {
    fieldType: FieldType.Boolean,
    label: "Valuation Fees Collected By Sales",
    fieldKey: ["ValuationFeesCollectedBySalesIndicator"],
    options: booleanOptions
  },
  CRMId: {
    fieldType: FieldType.String,
    label: "CRM ID",
    fieldKey: ["CRMId"]
  },
  OperationsDepartment: {
    fieldType: FieldType.SingleSelect,
    label: "Operations Department",
    fieldKey: ["OperationsDepartment"],
    options: createOptionsFromEnum(DealOperationsDepartmentType)
  },
  InitialLTVRatePercent: {
    fieldType: FieldType.Percentage,
    label: "Initial Loan to Value",
    fieldKey: ["InitialLTVRatePercent"]
  },
  PropertyInsurancePremiumPaymentType: {
    fieldType: FieldType.SingleSelect,
    label: "Property Insurance Premium Payment",
    fieldKey: ["PropertyInsurancePremiumPaymentType"],
    options: createOptionsFromEnum(PropertyInsurancePremiumPaymentType)
  },
  FloodInsurancePremiumPaymentType: {
    fieldType: FieldType.SingleSelect,
    label: "Flood Insurance Premium Payment",
    fieldKey: ["FloodInsurancePremiumPaymentType"],
    options: createOptionsFromEnum(FloodInsurancePremiumPaymentType)
  },
  PropertyInsuranceAgency: {
    FullName: {
      fieldType: FieldType.String,
      label: "Property Insurance Agency",
      fieldKey: ["PropertyInsuranceAgency", "FullName"]
    }
  },
  FloodInsuranceAgency: {
    FullName: {
      fieldType: FieldType.String,
      label: "Flood Insurance Agency",
      fieldKey: ["FloodInsuranceAgency", "FullName"]
    }
  },
  InterestReserveDepositAmount: {
    fieldType: FieldType.Money,
    label: "Interest Reserve Deposit",
    fieldKey: ["InterestReserveDepositAmount"]
  },
  DeferredMaintenanceAmount: {
    fieldType: FieldType.Money,
    label: "Deferred Maintenance",
    fieldKey: ["DeferredMaintenanceAmount"]
  },
  TitleCommitment: {
    AllPayoffsLiensIncludedInSection1RequirementsIndicator: {
      fieldType: FieldType.Boolean,
      label: "Section 1 Requirements Includes All Payoffs and Liens?",
      fieldKey: [
        "TitleCommitment",
        "AllPayoffsLiensIncludedInSection1RequirementsIndicator"
      ],
      options: booleanOptions
    },
    AllSubjectPropertiesIncludedIndicator: {
      fieldType: FieldType.Boolean,
      label: "All Subject Properties Included?",
      fieldKey: ["TitleCommitment", "AllSubjectPropertiesIncludedIndicator"],
      options: booleanOptions
    },
    LeaseholdEndorsementIndicator: {
      fieldType: FieldType.Boolean,
      label: "Leasehold Endorsement, if applicable?",
      fieldKey: ["TitleCommitment", "LeaseholdEndorsementIndicator"],
      options: booleanOptions
    },
    PropertyRightsOwnershipType: {
      fieldKey: ["TitleCommitment", "PropertyRightsOwnershipType"],
      fieldType: FieldType.SingleSelect,
      label: "Ownership (Property Rights)",
      options: createOptionsFromEnum(PropertyRightsOwnershipType)
    },
    RequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator: {
      fieldType: FieldType.Boolean,
      label:
        "Section 2 Exceptions Does Not Include Required Payoffs and Liens?",
      fieldKey: [
        "TitleCommitment",
        "RequiredPayoffsLiensNotIncludedInSection2ExceptionsIndicator"
      ],
      options: booleanOptions
    },
    TitleCommitmentComments: {
      fieldType: FieldType.RichText,
      label: "Title Commitment Comments",
      fieldKey: ["TitleCommitment", "TitleCommitmentComments"]
    },
    TitleEffectiveDate: {
      fieldType: FieldType.Date,
      label: "Title Commitment Effective Date",
      fieldKey: ["TitleCommitment", "TitleEffectiveDate"]
    },
    TitleExpirationDate: {
      fieldType: FieldType.Date,
      label: "Title Commitment Expiration Date",
      fieldKey: ["TitleCommitment", "TitleExpirationDate"]
    },
    TitleInternationalExecutionIndicator: {
      fieldType: FieldType.Boolean,
      label: "International Execution?",
      fieldKey: ["TitleCommitment", "TitleInternationalExecutionIndicator"],
      options: booleanOptions
    },
    TitlePolicyAmount: {
      fieldType: FieldType.Money,
      label: "Policy Amount",
      fieldKey: ["TitleCommitment", "TitlePolicyAmount"]
    },
    TitleVestingType: {
      fieldType: FieldType.SingleSelect,
      label: "Title Vesting",
      fieldKey: ["TitleCommitment", "TitleVestingType"],
      options: createOptionsFromEnum(TitleVestingType)
    }
  },
  TotalLiquidAssetAmount: {
    fieldType: FieldType.Money,
    label: "Liquid Asset Total Amount",
    fieldKey: ["TotalLiquidAssetAmount"]
  },
  SettlementStatementMatchLedgerIndicator: {
    fieldType: FieldType.Boolean,
    label: "Settlement Statement Matches Ledger?",
    fieldKey: ["SettlementStatementMatchLedgerIndicator"],
    options: booleanOptions
  },
  LoanAmountVerifiedMatchIndicator: {
    fieldType: FieldType.Boolean,
    label: "Loan Amount Verified Match?",
    fieldKey: ["LoanAmountVerifiedMatchIndicator"],
    options: booleanOptions
  },
  ConstructionBudgetVerifiedMatchIndicator: {
    fieldType: FieldType.Boolean,
    label: "Construction Budget Verified Match?",
    fieldKey: ["ConstructionBudgetVerifiedMatchIndicator"],
    options: booleanOptions
  },
  SettlementStatementComments: {
    fieldType: FieldType.RichText,
    label: "Settlement Statement Comments",
    fieldKey: ["SettlementStatementComments"]
  },
  EOExpirationDate: {
    fieldType: FieldType.Date,
    label: "E&O Expiration Date",
    fieldKey: ["EOExpirationDate"]
  },
  EONamedInsuredMatchIndicator: {
    fieldType: FieldType.Boolean,
    label: "E&O Named Insured Match?",
    fieldKey: ["EONamedInsuredMatchIndicator"],
    options: booleanOptions
  },
  WireABARoutingNumber: {
    fieldType: FieldType.String,
    label: "Wire Info - ABA/Routing Number",
    fieldKey: ["WireABARoutingNumber"]
  },
  WireAccountNumber: {
    fieldType: FieldType.String,
    label: "Wire Info - Account Number",
    fieldKey: ["WireAccountNumber"]
  },
  WireBankName: {
    fieldType: FieldType.String,
    label: "Wire Info - Bank Name",
    fieldKey: ["WireBankName"]
  },
  WireAccountName: {
    fieldType: FieldType.String,
    label: "Wire Info - Name on Account",
    fieldKey: ["WireAccountName"]
  },
  TitleFormsComments: {
    fieldType: FieldType.RichText,
    label: "Title Forms Comments",
    fieldKey: ["TitleFormsComments"]
  },
  LoanPurposeType: {
    fieldType: FieldType.SingleSelect,
    label: "Loan Purpose",
    fieldKey: ["LoanPurposeType"],
    options: createOptionsFromEnum(LoanPurposeType)
  },
  PortfolioLoanIndicator: {
    fieldType: FieldType.Boolean,
    label: "Portfolio Loan?",
    fieldKey: ["PortfolioLoanIndicator"],
    options: booleanOptions
  },
  RateLockStartDate: {
    fieldType: FieldType.Date,
    label: "Rate Lock Start Date",
    fieldKey: ["RateLockStartDate"]
  },
  RateLockEndDate: {
    fieldType: FieldType.Date,
    label: "Rate Lock End Date",
    fieldKey: ["RateLockEndDate"]
  },
  TotalCashToFromBorrower: {
    fieldType: FieldType.SignedMoney,
    label: "Total Cash To/From Borrower",
    fieldKey: ["TotalCashToFromBorrower"]
  },
  CashFromBorrowerAmount: {
    fieldType: FieldType.Money,
    label: "Cash From Borrower",
    fieldKey: ["CashFromBorrowerAmount"]
  },
  CashToBorrowerAmount: {
    fieldType: FieldType.Money,
    label: "Cash To Borrower",
    fieldKey: ["CashToBorrowerAmount"]
  },
  MarketingPromotionCreditAmount: {
    fieldType: FieldType.SignedMoney,
    label: "Marketing Promotion Credit",
    fieldKey: ["MarketingPromotionCreditAmount"]
  },
  MarketingPromotionCode: {
    fieldType: FieldType.String,
    label: "Marketing Promotion Code",
    fieldKey: ["MarketingPromotionCode"]
  },
  BuildingEnvelopeExpandedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Are you expanding the building envelope to add livable space?",
    fieldKey: ["BuildingEnvelopeExpandedIndicator"],
    options: booleanOptions
  },
  LoadBearingWallsRemoveIntendedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Do you intend to remove two or more load-bearing walls?",
    fieldKey: ["LoadBearingWallsRemoveIntendedIndicator"],
    options: booleanOptions
  },
  SquareFootageAddedIndicator: {
    fieldType: FieldType.Boolean,
    label: "Are you adding livable square footage to the existing structure?",
    fieldKey: ["SquareFootageAddedIndicator"],
    options: booleanOptions
  },
  InterestReserveEscrowOverrideMonthCount: {
    fieldType: FieldType.Integer,
    label: "Interest Reserve Escrow Override (Months)",
    fieldKey: ["InterestReserveEscrowOverrideMonthCount"]
  },
  InterestReserveEscrowOverrideAmount: {
    fieldType: FieldType.Money,
    label: "Interest Reserve Escrow Override (Dollars)",
    fieldKey: ["InterestReserveEscrowOverrideAmount"]
  },
  ProcessingFeeOverride: {
    fieldType: FieldType.Money,
    label: "Processing Fee Override",
    fieldKey: ["ProcessingFeeOverride"]
  },

  LenderFinance: {
    PartnerFirstName: {
      fieldType: FieldType.String,
      label: "Lender Finance Partner First Name",
      fieldKey: ["LenderFinance", "PartnerFirstName"]
    },
    PartnerLastName: {
      fieldType: FieldType.String,
      label: "Lender Finance Partner Last Name",
      fieldKey: ["LenderFinance", "PartnerLastName"]
    },
    PartnerInitialTermSheetApprovalStatusIndicator: {
      fieldType: FieldType.Boolean,
      label: "Initial Term Sheet Approval Status (LF Partner)",
      fieldKey: [
        "LenderFinance",
        "PartnerInitialTermSheetApprovalStatusIndicator"
      ],
      options: booleanOptions
    },
    PartnerInitialTermSheetApprovalStatusDate: {
      fieldType: FieldType.Date,
      label: "Initial Term Sheet Approval Date (LF Partner)",
      fieldKey: ["LenderFinance", "PartnerInitialTermSheetApprovalStatusDate"]
    },
    PartnerFinalTermSheetApprovalStatusDate: {
      fieldType: FieldType.Date,
      label: "Final Term Sheet Approval Date (LF Partner)",
      fieldKey: ["LenderFinance", "PartnerFinalTermSheetApprovalStatusDate"]
    },
    BorrowerFinalTermSheetApprovalStatusDate: {
      fieldType: FieldType.Date,
      label: "Final Term Sheet Approval Date (LF Borrower)",
      fieldKey: ["LenderFinance", "BorrowerFinalTermSheetApprovalStatusDate"]
    }
  },
  ExitStrategy: {
    fieldType: FieldType.String,
    label: "Exit Strategy",
    fieldKey: ["ExitStrategy"]
  },
  TargetCloseDate: {
    fieldType: FieldType.Date,
    label: "Target Close Date",
    fieldKey: ["TargetCloseDate"]
  },
  ApplicationRequestedLoanAmount: {
    fieldType: FieldType.Money,
    label: "Requested Loan Amount",
    fieldKey: ["ApplicationRequestedLoanAmount"]
  },
  ProjectAnticipatedCompletionTimeline: {
    fieldType: FieldType.String,
    label: "Anticipated Completion Timeline",
    fieldKey: ["ProjectAnticipatedCompletionTimeline"]
  },
  ConstructionBudgetRemainingAmount: {
    fieldType: FieldType.Money,
    label: "Total Rehab Budget Remaining",
    fieldKey: ["ConstructionBudgetRemainingAmount"]
  },
  VerifiableConstructionCompletedAmount: {
    fieldType: FieldType.Money,
    label: "Total Verifiable Rehab Completed",
    fieldKey: ["VerifiableConstructionCompletedAmount"]
  },
  LienDollarAmount: {
    fieldType: FieldType.Money,
    label: "Lien Dollar Amount",
    fieldKey: ["LienDollarAmount"]
  },
  PropertyTypeChangingIndicator: {
    fieldType: FieldType.Boolean,
    label: "Will the property type be changing?",
    fieldKey: ["PropertyTypeChangingIndicator"],
    options: booleanOptions
  },
  PropertyZoningChangesIndicator: {
    fieldType: FieldType.Boolean,
    label: "Does the property require any zoning changes?",
    fieldKey: ["PropertyZoningChangesIndicator"],
    options: booleanOptions
  },
  SubdividePropertyPartialReleasesIndicator: {
    fieldType: FieldType.Boolean,
    label:
      "Do you intend to subdivide the property or request partial releases?",
    fieldKey: ["SubdividePropertyPartialReleasesIndicator"],
    options: booleanOptions
  },
  LenderTitleProviderIndicator: {
    fieldType: FieldType.Boolean,
    label: "Use Lender's Preferred Title Insurance Provider",
    fieldKey: ["LenderTitleProviderIndicator"],
    options: booleanOptions
  },
  LenderPropertyInsuranceProviderIndicator: {
    fieldType: FieldType.Boolean,
    label: "Use Lender's Preferred Property Insurance Provider",
    fieldKey: ["LenderPropertyInsuranceProviderIndicator"],
    options: booleanOptions
  },
  HOAContactName: {
    fieldType: FieldType.String,
    label: "HOA Contact Name",
    fieldKey: ["HOAContactName"]
  },
  HOAContactPhone: {
    fieldType: FieldType.Phone,
    label: "HOA Contact Phone",
    fieldKey: ["HOAContactPhone"]
  },
  HOAContactEmail: {
    fieldType: FieldType.Email,
    label: "HOA Contact Email",
    fieldKey: ["HOAContactEmail"]
  },
  Occupancy: {
    fieldType: FieldType.Percentage,
    label: "Occupancy %",
    fieldKey: ["Occupancy"]
  },
  PropertyRentReadyConditionIndicator: {
    fieldType: FieldType.Boolean,
    label: "Is the property in rent ready condition?",
    fieldKey: ["PropertyRentReadyConditionIndicator"],
    options: booleanOptions
  },
  PropertyLeasePurchaseOptionIndicator: {
    fieldType: FieldType.Boolean,
    label: "Is the property subject to a lease purchase option?",
    fieldKey: ["PropertyLeasePurchaseOptionIndicator"],
    options: booleanOptions
  },
  OccupationIndicator: {
    fieldType: FieldType.Boolean,
    label:
      "Do any sponsors, entity members, or family intend to occupy the property?",
    fieldKey: ["OccupationIndicator"],
    options: booleanOptions
  },
  SellerRelationshipIndicator: {
    fieldType: FieldType.Boolean,
    label:
      "If a purchase, does any loan party have a personal or business relationship with the seller?",
    fieldKey: ["SellerRelationshipIndicator"],
    options: booleanOptions
  },
  AssociatedPropertiesDescription: {
    fieldType: FieldType.RichText,
    label: "List All Associated Properties",
    fieldKey: ["AssociatedPropertiesDescription"]
  },
  DevelopmentStrategy: {
    fieldType: FieldType.String,
    label: "Development Strategy",
    fieldKey: ["DevelopmentStrategy"]
  },
  LotStatus: {
    fieldType: FieldType.String,
    label: "Lot Status",
    fieldKey: ["LotStatus"]
  },
  CurrentLotMarketValueAmount: {
    fieldType: FieldType.Money,
    label: "Current Lot Market Value",
    fieldKey: ["CurrentLotMarketValueAmount"]
  },
  LotZonedParcelIdIndicator: {
    fieldType: FieldType.Boolean,
    label:
      "Is the lot already properly zoned and individually platted with a parcel ID #?",
    fieldKey: ["LotZonedParcelIdIndicator"],
    options: booleanOptions
  },
  BuildingPermitsIndicator: {
    fieldType: FieldType.Boolean,
    label: "Do you already have the required building permits?",
    fieldKey: ["BuildingPermitsIndicator"],
    options: booleanOptions
  },
  BuildingPermitsExpectedDate: {
    fieldType: FieldType.Date,
    label: "If no, when do you expect to receive them?",
    fieldKey: ["BuildingPermitsExpectedDate"]
  },
  LotUtilitiesIndicator: {
    fieldType: FieldType.Boolean,
    label:
      "Are utilities available at the lot? (at the street, ready to tap into, etc.)",
    fieldKey: ["LotUtilitiesIndicator"],
    options: booleanOptions
  },
  PrimaryBorrowers: {
    fieldType: FieldType.Array,
    label: "Primary borrowers",
    fieldKey: ["PrimaryBorrowers"]
  },
  SecondaryBorrowers: {
    fieldType: FieldType.Array,
    label: "borrowers",
    fieldKey: ["SecondaryBorrowers"]
  },
  PrimarySponsors: {
    fieldType: FieldType.Array,
    label: "Primary sponsors",
    fieldKey: ["PrimarySponsors"]
  },
  SecondarySponsors: {
    fieldType: FieldType.Array,
    label: "sponsors",
    fieldKey: ["SecondarySponsors"]
  },
  CreditReviewCommittee: {
    creditMemoDraftedDate: {
      fieldType: FieldType.Date,
      label: "Credit Memo Drafted",
      fieldKey: ["CreditReviewCommittee", "creditMemoDraftedDate"]
    },
    creditMemoApprovedDate: {
      fieldType: FieldType.Date,
      label: "Credit Memo Approved",
      fieldKey: ["CreditReviewCommittee", "creditMemoApprovedDate"]
    },
    internalSubmissionDate: {
      fieldType: FieldType.Date,
      label: "Internal CRC Submitted for Review",
      fieldKey: ["CreditReviewCommittee", "internalSubmissionDate"]
    },
    internalApprovalDate: {
      fieldType: FieldType.Date,
      label: "Internal CRC Approved",
      fieldKey: ["CreditReviewCommittee", "internalApprovalDate"]
    },
    externalSubmissionDate: {
      fieldType: FieldType.Date,
      label: "External CRC Submitted for Review",
      fieldKey: ["CreditReviewCommittee", "externalSubmissionDate"]
    },
    externalApprovalDate: {
      fieldType: FieldType.Date,
      label: "External CRC Approved",
      fieldKey: ["CreditReviewCommittee", "externalApprovalDate"]
    },
    lOIIssuedDate: {
      fieldType: FieldType.Date,
      label: "LOI Issued",
      fieldKey: ["CreditReviewCommittee", "lOIIssuedDate"]
    },
    lOIAcceptedDate: {
      fieldType: FieldType.Date,
      label: "LOI Accepted - Money Up",
      fieldKey: ["CreditReviewCommittee", "lOIAcceptedDate"]
    }
  }
};

export const dealFieldSpecs = createSpecWithFieldMeta({
  spec: dealFieldBaseSpecs
});
