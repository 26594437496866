import { Box, Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import { useOutletContext } from "react-router";

import useTaskHooks from "../../hooks/task.hooks";
import { ExportTaskChecklist } from "./export-checklist/ExportTaskChecklist";
import {
  AutoGenerateTaskModal,
  ManualGenerateTaskModal
} from "./GenerateTaskComponent";
import { TaskFormContainer } from "./TaskForm";
import { TaskTableContainer } from "./TaskTable";

const TaskPage = (props: {
  partyId?: string;
  propertyId?: string;
  dealId?: string;
  snapshotId?: string;
}) => {
  const { dealId: dealIdOutlet, snapshotId: snapshotIdOutlet } =
    useOutletContext<{
      dealId?: string;
      snapshotId?: string;
    }>() || {};

  const { dealId = dealIdOutlet, snapshotId = snapshotIdOutlet } = props;

  const { taskState, setSelectedTask } = useTaskHooks();

  useEffect(() => {
    setSelectedTask("");
  }, [dealId, props.partyId]);

  return (
    <Box w="100%" h="100%">
      <Flex w="100%">
        <Box pl="10px">
          <Flex>
            <Box>
              <AutoGenerateTaskModal
                dealId={dealId}
                partyId={props.partyId}
                isDisabled={!!snapshotId}
              />
            </Box>
            <Box pl="10px">
              <ManualGenerateTaskModal
                dealId={dealId}
                partyId={props.partyId}
                isDisabled={!!snapshotId}
              />
            </Box>
            <Box pl="10px">
              <ExportTaskChecklist
                dealId={dealId}
                partyId={props.partyId}
                isDisabled={!!snapshotId}
              />
            </Box>
          </Flex>
        </Box>
      </Flex>
      <Flex w="100%" p="10px">
        <Box w={taskState?.selectedId ? "40%" : "100%"} pr="10px">
          <TaskTableContainer
            dealId={dealId}
            partyId={props.partyId}
            snapshotId={snapshotId}
          />
        </Box>
        <Box w={taskState?.selectedId ? "60%" : "0%"}>
          {taskState?.selectedId && (
            <TaskFormContainer snapshotId={snapshotId} />
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default TaskPage;
