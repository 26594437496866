import { Badge, Box, Flex, Text, Tooltip } from "@chakra-ui/react";
import {
  AuditEvent,
  AuditEventFieldType,
  AuditEventOperation,
  FieldStatus
} from "@elphi/types";
import { EMPTY, NONE, NOT_AVAILABLE } from "../../../../constants/common";
import { RightDirectionArrow } from "../../../icons";
import {
  fieldStatusToColor,
  fieldStatusToLetter
} from "../../../task/field-selection/d2v.utils";
import {
  ELPHI_TRX_STATUS_COLOR,
  ELPHI_TRX_STATUS_COLOR_TEXT
} from "../../../task/integrations/integration.type";
import {
  fieldTypeMap,
  focusBgColorMap,
  focusColorMap,
  getFieldLabel,
  getPrettyIdentifier
} from "./eventRow.utils";

const FieldValue = (props: FieldValueProps) => {
  const value = props.value || NONE;
  return (
    <Tooltip label={value}>
      <Text whiteSpace={"nowrap"} textOverflow={"ellipsis"} overflow={"hidden"}>
        {value}
      </Text>
    </Tooltip>
  );
};

const FieldStatusValue = (props: FieldValueProps) => {
  return (
    <Text
      size="xs"
      boxShadow={"md"}
      color={"white"}
      borderRadius={"50%"}
      w="23px"
      h="23px"
      fontSize={"xs"}
      fontWeight={600}
      p={0.5}
      textAlign={"center"}
      bgColor={fieldStatusToColor[props.value || FieldStatus.None]}
    >
      {fieldStatusToLetter[props.value || FieldStatus.None]}
    </Text>
  );
};

const FocusValue = (props: FieldValueProps) => {
  const bgColor = focusBgColorMap[props.value || EMPTY];
  const color = focusColorMap[props.value || EMPTY];
  const displayValue = props.value === "thirdParty" ? "3rd party" : props.value;

  return displayValue ? (
    <Text
      boxShadow="md"
      borderRadius="2px"
      textAlign="center"
      fontSize="xs"
      fontWeight={600}
      p="2px"
      minWidth="70px"
      bgColor={bgColor}
      color={color}
    >
      {displayValue}
    </Text>
  ) : (
    <Text p="2px">{NONE}</Text>
  );
};

const IntegrationStatusValue = (props: FieldValueProps) => {
  const { value } = props;
  const bgColor =
    value && ELPHI_TRX_STATUS_COLOR[value]
      ? ELPHI_TRX_STATUS_COLOR[value]
      : ELPHI_TRX_STATUS_COLOR.default;

  const borderColor =
    value && ELPHI_TRX_STATUS_COLOR[value]
      ? ELPHI_TRX_STATUS_COLOR[value]
      : ELPHI_TRX_STATUS_COLOR.default;

  const color =
    value && ELPHI_TRX_STATUS_COLOR_TEXT[value]
      ? ELPHI_TRX_STATUS_COLOR_TEXT[value]
      : ELPHI_TRX_STATUS_COLOR_TEXT.default;

  return (
    <Badge
      fontSize={12}
      bgColor={bgColor}
      borderColor={borderColor}
      color={color}
    >
      {value}
    </Badge>
  );
};

type FieldValueProps = {
  value: string | undefined;
};

type FieldValueComponentMap = {
  [key in AuditEventFieldType]: React.ComponentType<FieldValueProps>;
};

const fieldValueComponentMap: FieldValueComponentMap = {
  [AuditEventFieldType.Focus]: FocusValue,
  [AuditEventFieldType.FieldStatus]: FieldStatusValue,
  [AuditEventFieldType.IntegrationStatus]: IntegrationStatusValue,
  [AuditEventFieldType.Value]: FieldValue,
  [AuditEventFieldType.DeletedFile]: FieldValue,
  [AuditEventFieldType.UploadedFile]: FieldValue
};

export const EventEntityPath = (props: { event: AuditEvent }) => {
  const { event } = props;
  const { operation, fieldType } = event;
  const prettyIdentifier = getPrettyIdentifier(event);
  if (
    operation === AuditEventOperation.Created ||
    operation === AuditEventOperation.Deleted
  ) {
    return <Box>{prettyIdentifier}</Box>;
  }
  if (operation === AuditEventOperation.Updated) {
    const fieldLabel = getFieldLabel(event);
    const fieldPrettyType = `${
      fieldTypeMap[fieldType || EMPTY] || NOT_AVAILABLE
    }`;
    return (
      <Box>{`${prettyIdentifier} | ${fieldLabel} | ${fieldPrettyType}`}</Box>
    );
  }
  return <>{NOT_AVAILABLE}</>;
};

export const EventEntityValue = (props: { event: AuditEvent }) => {
  const { operation, entityId, fieldType, beforeValue, afterValue } =
    props.event;

  if (
    operation === AuditEventOperation.Created ||
    operation === AuditEventOperation.Deleted
  ) {
    return <Text>{`id: ${entityId}`}</Text>;
  }

  if (operation === AuditEventOperation.Updated && fieldType) {
    const ValueComponent = fieldValueComponentMap[fieldType];
    if (ValueComponent) {
      return (
        <Flex width={"100%"}>
          <Box maxWidth={"50%"}>
            <ValueComponent value={beforeValue} />
          </Box>
          <RightDirectionArrow mx={2} w={6} h={6} />
          <Box maxWidth={"50%"}>
            <ValueComponent value={afterValue} />
          </Box>
        </Flex>
      );
    }
    return <FieldValue value={afterValue} />;
  }
  return <>{NOT_AVAILABLE}</>;
};
