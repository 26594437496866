import { FirebaseCollections } from "../../db";
import { Flatten } from "../../utils/flatten";
import { Deal } from "../deal.types";
import { PermissionField } from "./permission.types";

export type BaseFieldGroup<
  T extends FirebaseCollections,
  D extends Flatten<object, object>
> = {
  name: string;
  collection: T;
  fields: D;
};

export type PostClosingDealFields = Pick<
  Flatten<Deal, PermissionField>,
  | "DealMetadata.milestoneData.Close of Escrow.type"
  | "DealMetadata.milestoneData.Close of Escrow.timestamp"
  | "DealMetadata.milestoneData.File Complete.type"
  | "DealMetadata.milestoneData.File Complete.timestamp"
  | "DealMetadata.milestone"
  | "fieldMeta.PromissoryNoteComments.status"
  | "PromissoryNoteComments"
  | "fieldMeta.AllongeAssignmentSentToWarehouseIndicator.status"
  | "AllongeAssignmentSentToWarehouseIndicator"
  | "fieldMeta.ClosingAnalystUserId.status"
  | "ClosingAnalystUserId"
  | "fieldMeta.NotaryUserId.status"
  | "NotaryUserId"
  | "fieldMeta.NotaryExpirationDate.status"
  | "NotaryExpirationDate"
  | "fieldMeta.LoanPackageNoteReceivedByLenderIndicator.status"
  | "LoanPackageNoteReceivedByLenderIndicator"
  | "fieldMeta.LoanPackageChecksReceivedIndicator.status"
  | "LoanPackageChecksReceivedIndicator"
  | "fieldMeta.LoanPackageCheckNumber.status"
  | "LoanPackageCheckNumber"
  | "fieldMeta.LoanPackageCheckAmount.status"
  | "LoanPackageCheckAmount"
  | "fieldMeta.LoanPackageTrackingNumber.status"
  | "LoanPackageTrackingNumber"
>;
export const postClosingFields: BaseFieldGroup<
  FirebaseCollections.Deal,
  PostClosingDealFields
> = {
  name: "postClosingFields",
  collection: FirebaseCollections.Deal,
  fields: {
    "DealMetadata.milestoneData.Close of Escrow.type": {
      read: true,
      write: true
    },
    "DealMetadata.milestoneData.Close of Escrow.timestamp": {
      read: true,
      write: true
    },
    "DealMetadata.milestoneData.File Complete.type": {
      read: true,
      write: true
    },
    "DealMetadata.milestoneData.File Complete.timestamp": {
      read: true,
      write: true
    },
    "DealMetadata.milestone": {
      read: true,
      write: true
    },
    "fieldMeta.PromissoryNoteComments.status": { 
      read: true,
      write: true
    },
    "fieldMeta.AllongeAssignmentSentToWarehouseIndicator.status": {
      read: true,
      write: true
    },
    "fieldMeta.ClosingAnalystUserId.status": {
      read: true,
      write: true
    },
    "fieldMeta.NotaryUserId.status": {
      read: true,
      write: true
    },
    "fieldMeta.NotaryExpirationDate.status": {
      read: true,
      write: true
    },
    "fieldMeta.LoanPackageNoteReceivedByLenderIndicator.status": {
      read: true,
      write: true
    },
    "fieldMeta.LoanPackageChecksReceivedIndicator.status": {
      read: true,
      write: true
    },
    "fieldMeta.LoanPackageCheckNumber.status": {
      read: true,
      write: true
    },
    "fieldMeta.LoanPackageCheckAmount.status": {
      read: true,
      write: true
    },
    "fieldMeta.LoanPackageTrackingNumber.status": {
      read: true,
      write: true
    },
    PromissoryNoteComments: {
      read: true,
      write: true
    },
    AllongeAssignmentSentToWarehouseIndicator: {
      read: true,
      write: true
    },
    ClosingAnalystUserId: {
      read: true,
      write: true
    },
    NotaryUserId: {
      read: true,
      write: true
    },
    NotaryExpirationDate: {
      read: true,
      write: true
    },
    LoanPackageNoteReceivedByLenderIndicator: {
      read: true,
      write: true
    },
    LoanPackageChecksReceivedIndicator: {
      read: true,
      write: true
    },
    LoanPackageCheckNumber: {
      read: true,
      write: true
    },
    LoanPackageCheckAmount: {
      read: true,
      write: true
    },
    LoanPackageTrackingNumber: {
      read: true,
      write: true
    }
  }
};
