import { Type } from "@sinclair/typebox";
import { StringField } from "./utils.typebox";

export const partyGroupModel = Type.Object({
  name: Type.String({ minLength: 1 }),
  description: Type.Optional(StringField),
  status: Type.Optional(
    Type.Union([Type.Literal("active"), Type.Literal("disabled")])
  )
});

export const partyGroupUpdate = Type.Object({
  name: Type.Optional(StringField),
  description: Type.Optional(StringField),
  status: Type.Optional(
    Type.Union([Type.Literal("active"), Type.Literal("disabled")])
  )
});
