import {
  ElphiEntityType,
  ElphiTrxStatus,
  FieldType,
  IntegrationTaskTemplate,
  IntegrationType,
  StatusCode,
  Task,
  TaskType
} from "@elphi/types";
import { removeEmpty } from "@elphi/utils/src/common.utils";
import { EntityId } from "@reduxjs/toolkit";
import { responseHandler } from "../apis/rtk/response.handler";
import { IntegrationCenterTaskCard } from "../components/check-list/integrations-center/integrationCenter.types";
import { useFormBuilderStateHandler } from "../components/form-builder/InputBuilder";
import { orderStatusHandlerFactory } from "../components/task/orderStatus/handlers";
import {
  getDealIdentifier,
  getModifiedAt,
  getPropertyAddress
} from "../components/task/taskPrint.utils";
import { getTaskRowData } from "../components/task/taskRowData.utils";
import { useElphiToast } from "../components/toast/toast.hook";
import { AppConfig } from "../config/appConfig";
import { EMPTY, NOT_AVAILABLE } from "../constants/common";
import { taskApi } from "../redux/v2/task";
import useDealHooks from "./deal.hooks";
import { useDealPropertyRelationHooks } from "./dealPropertyRelation.hooks";
import { usePropertyHooks } from "./property.hooks";
import useTaskHooks from "./task.hooks";

export const useIntegrationCenterHooks = () => {
  const [bulkOrderApi, bulkOrderResponse] = taskApi.useBulkOrderMutation();
  const [entityTasksApi, entityTasksResponse] =
    taskApi.useLazyEntityTasksQuery();

  const { taskState, setSelectedTask, updateTasksHandler } = useTaskHooks();
  const { selectedDeal, selectedId } = useDealHooks();
  const { propertyState } = usePropertyHooks();
  const { dealPropertyRelationState } = useDealPropertyRelationHooks();
  const { successToast, errorToast } = useElphiToast();

  const getIntegrationTasks = (relationshipIds: EntityId[]) => {
    const tasks = taskState.ids
      .filter((id: string) =>
        relationshipIds.includes(taskState?.entities?.[id]?.entityId || "")
      )
      .map((id: string) => taskState?.entities?.[id])
      .filter(removeEmpty);

    return tasks
      .map((task) => {
        if (
          task?.type === TaskType.Integration &&
          (task.entityType === ElphiEntityType.deal ||
            task.entityType === ElphiEntityType.property)
          //integration center support deal and property for now,
        ) {
          const integrationTask: IntegrationTaskTemplate["selectedTask"] = task;
          return integrationTask;
        }
        return undefined;
      })
      .filter(removeEmpty);
  };

  const prepareTaskCards = (relationshipIds: EntityId[]) => {
    const integrationTasks = getIntegrationTasks(relationshipIds);
    return integrationTasks
      .map((task): IntegrationCenterTaskCard | undefined => {
        const handler = orderStatusHandlerFactory(TaskType.Integration);
        const taskRowData = getTaskRowData(task, {
          selectedDeal,
          propertyState
        });
        const orderStatusResponse = handler(taskRowData);
        const taskCard = {
          id: task.id,
          taskStatus: task.taskStatus,
          taskName: task.name,
          orderStatus: task.orderStatus,
          vendorStatus: orderStatusResponse?.orderStatus || NOT_AVAILABLE,
          entityType: task.entityType,
          checklistOf: task.checklistOf,
          kct: task.keyClosingTaskType,
          containFiles: !!task.documents,
          isReadyToOrder: !!task.isReadyToOrder,
          modifiedAt: getModifiedAt(task)
        };
        const { integrationType } = task;
        if (
          integrationType === IntegrationType.FloodCertificate ||
          integrationType === IntegrationType.PropertyReport ||
          integrationType === IntegrationType.RentalData
        ) {
          const property = propertyState.entities?.[task.entityId];
          return {
            ...taskCard,
            stepRequired: !!task?.stepRequired,
            elphiStatus: task.elphiTrxStatus || ElphiTrxStatus.Pending,
            entityInfo: getPropertyAddress(property)
          };
        }
        if (task.integrationType === IntegrationType.WireInsuranceCertificate) {
          return {
            ...taskCard,
            elphiStatus: task.elphiTrxStatus || ElphiTrxStatus.Pending,
            entityInfo: `Deal: ${getDealIdentifier(selectedDeal)}`
          };
        }
        return undefined;
      })
      .filter(removeEmpty);
  };

  const bulkOrderHandler = async (taskIds: string[]) => {
    await bulkOrderApi({
      taskIds
    })
      .then(responseHandler)
      .then((r) => {
        if (r.status === StatusCode.OK) {
          successToast({
            title: "Bulk order",
            description: "Successfully ordered"
          });
        }
        if (r.status === StatusCode.BadRequest) {
          errorToast({
            title: "Bulk order",
            description: `Failed - ${JSON.stringify(r.data)}`
          });
        }
      });
  };

  return {
    taskState,
    setSelectedTask,
    getIntegrationTasks,
    prepareTaskCards,
    updateTasksHandler,
    bulkOrderHandler,
    bulkOrderResponse,
    entityTasksApi,
    entityTasksResponse,
    propertyState,
    dealPropertyRelationState,
    selectedDealId: selectedId
  };
};

export const useStepRequiredHooks = () => {
  const { updateTasksHandler } = useTaskHooks();
  const { errorToast } = useElphiToast();
  const { onChange, syncState } = useFormBuilderStateHandler({
    initialState: { tasks: {} as { [id: string]: Task } },
    callback: async (t) => {
      if (!t.tasks) return null;
      return await updateTasksHandler(t.tasks).then((r) => {
        if (r?.status === StatusCode.BadRequest) {
          errorToast({
            title: "Step required",
            description: `${r?.data.error} - ${r?.data.description}`
          });
        }
        return !r ? null : r;
      });
    },
    callbackOptions: {
      clearDiff: true,
      debounceRate: AppConfig.debounceRate
    }
  });

  const disableStepRequired = (taskId: string) => {
    onChange({
      fieldKey: ["tasks", taskId, "stepRequired"],
      fieldType: FieldType.String,
      value: EMPTY
    });
  };

  return {
    disableStepRequired,
    syncState
  };
};
