import { Box, Spinner } from "@chakra-ui/react";

const ElphiSpinner = () => {
  return (
    <Box
      width="100px"
      height="100px"
      position="absolute"
      top="0"
      bottom="0"
      left="0"
      right="0"
      margin="auto"
    >
      <Spinner
        w="200px"
        h="200px"
        color="lightblue"
        thickness="5px"
        speed="1.34s"
        emptyColor="gray.200"
      />
    </Box>
  );
};

export default ElphiSpinner;
