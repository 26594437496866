import { FeatureFlag } from "@elphi/utils";
import { InitOptions, loadSpace } from "@usersnap/browser";
import { useGate } from "statsig-react";
import { getUsersnapConfig } from "../utils/envUtils";

const initializeUsersnap = (apiKey: string, initParams?: InitOptions) => {
  loadSpace(apiKey)
    .then((api) => api.init(initParams))
    .catch((e) =>
      console.error(
        "initializeUsersnap, loadSpace failed error:",
        JSON.stringify(e)
      )
    );
};

export const useUsersnap = (initParams?: InitOptions) => {
  const { value: isEnabled } = useGate(FeatureFlag.ESD_2824_Usersnap);
  const apiKey = getUsersnapConfig()?.apiKey;

  const manageUsersnap = () => {
    if (!isEnabled) {
      console.info("Usersnap is disabled");
      return;
    }
    if (!apiKey) {
      console.warn(
        "Usersnap space API key is null or empty, can't operate on Usersnap space"
      );
      return;
    }

    initializeUsersnap(apiKey, initParams);
  };

  return { manageUsersnap };
};
