import { AddIcon, RepeatIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import { FieldType } from "@elphi/types";
import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import elphiTheme from "../../assets/themes/elphi.theme.default";
import { usePartyHooks } from "../../hooks/party.hooks";
import { usePartyRelationHooks } from "../../hooks/partyrelation.hooks";
import { useTaskHooks } from "../../hooks/task.hooks";
import { RootState } from "../../redux/store";
import { AssetQuerySearch, assetQueryType } from "../asset/AssetQuerySearch";
import DealSearch from "../deal/DealSearch";
import FormBuilder from "../form-builder/FormBuilder";
import { useFormBuilderStateHandler } from "../form-builder/InputBuilder";
import { DealPartySearch } from "../party/DealPartySearch";
import PartySearch from "../party/PartySearch";
import { DealPropertySearchContainer } from "../property/PropertySearch";
import {
  StatementQuerySearch,
  statementQueryType
} from "../statement/StatementQuerySearch";
import { useElphiToast } from "../toast/toast.hook";
import TaskConfigurationSearch from "./task-configuration/TaskConfigurationSearch";

export const GenerateTaskForm = (props: {
  dealId?: string;
  partyId?: string;
  onSuccess: () => void;
}) => {
  const { generateTasks, generateTasksApiResponse } = useTaskHooks();
  const { errorToast, successToast } = useElphiToast();
  const isStateValidForCreation = true;

  const generateTaskHandler = () => {
    generateTasks({ dealId: props.dealId, partyId: props.partyId }).then(
      (r) => {
        if (r.status === 200) {
          let taskCreatedCount = 0;
          if (
            r.data?.results?.tasks?.deal &&
            r.data?.results?.tasks?.party &&
            r.data?.results?.tasks?.property
          ) {
            taskCreatedCount =
              r.data?.results?.tasks?.deal?.length +
              r.data?.results?.tasks?.party?.length +
              r.data?.results?.tasks?.property?.length;
          }
          successToast({
            title: "Tasks Created",
            description: `total tasks created: ${taskCreatedCount}`
          });
          props.onSuccess();
        }
        r.status === 400 &&
          errorToast({
            title: "Failed to create tasks",
            description: r.data.description
          });
      }
    );
  };
  return (
    <>
      <Button
        {...elphiTheme.components.light.button.primary}
        onClick={() => generateTaskHandler()}
        isDisabled={!isStateValidForCreation}
        isLoading={generateTasksApiResponse.isLoading || false}
      >
        Submit
      </Button>
    </>
  );
};

export const AutoGenerateTaskModal = (props: {
  dealId?: string;
  partyId?: string;
  isDisabled?: boolean;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Button
        isDisabled={props.isDisabled}
        leftIcon={<RepeatIcon />}
        {...elphiTheme.components.light.button.success}
        onClick={onOpen}
      >
        Auto Generate Tasks
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Submit to generate tasks</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <GenerateTaskForm
              dealId={props.dealId}
              partyId={props.partyId}
              onSuccess={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export type ManualGenerateTaskFormState = {
  entityType: "deal" | "party" | "property" | "asset" | "statement";
  deal: { [id: string]: { id: string } };
  party: { [id: string]: { id: string } };
  property: { [id: string]: { id: string } };
  asset: { [id: string]: { id: string } };
  statement: { [id: string]: { id: string } };
  taskConfiguration: { [id: string]: { id: string } };
};
export const ManualGenerateTaskForm = (props: {
  dealId?: string;
  propertyId?: string;
  partyId?: string;
  onSuccess: () => void;
}) => {
  const { generateManualTasks, generateManualTasksApiResponse } =
    useTaskHooks();
  const { errorToast, successToast } = useElphiToast();
  const { partyRelationState } = usePartyRelationHooks();
  const { partyTreeIds } = usePartyHooks();

  const dealPropertyState = useSelector(
    (state: RootState) => state.dealPropertyRelation,
    shallowEqual
  );
  const dealPartyState = useSelector(
    (state: RootState) => state.dealPartyRelation,
    shallowEqual
  );
  const partyState = useSelector(
    (state: RootState) => state.party,
    shallowEqual
  );
  const assetState = useSelector(
    (state: RootState) => state.asset,
    shallowEqual
  );

  const generateTaskHandler = () => {
    const generateManualTasksPayload = {
      dealId: props.dealId ? [props.dealId] : [],
      partyId: props.partyId
        ? [props.partyId]
        : selectedPartyIds
        ? selectedPartyIds
        : [],
      propertyId: props.propertyId ? [props.propertyId] : [],
      assetId: selectedAssetIds ? selectedAssetIds : [],
      statementId: [],
      taskConfigurationId: selectedConfigurations,
      [`${state.entityType}Id`]: selectedItems
    };

    if (state.entityType === "deal" && props.dealId) {
      const dealPartyIds = dealPartyState.ids.filter(
        (id) => dealPartyState?.entities?.[id]?.dealId === props.dealId
      );
      const dealPropertyIds = dealPropertyState.ids.filter(
        (id) => dealPropertyState?.entities?.[id]?.dealId === props.dealId
      );
      generateManualTasksPayload.dealParty = dealPartyIds;
      generateManualTasksPayload.dealProperty = dealPropertyIds;
    } else if (state.entityType === "property" && props.propertyId) {
      const dealPropertyIds = dealPropertyState.ids.filter(
        (id) =>
          dealPropertyState?.entities?.[id]?.propertyId === props.propertyId
      );
      generateManualTasksPayload.dealProperty = dealPropertyIds;
    } else if (state.entityType === "party" && props.partyId) {
      const dealPartyIds = dealPartyState.ids.filter(
        (id) => dealPartyState?.entities?.[id]?.partyId === props.partyId
      );
      generateManualTasksPayload.dealParty = dealPartyIds;
    }

    generateManualTasks(generateManualTasksPayload).then((r) => {
      if (r.status === 200) {
        let taskCreatedCount = 0;
        if (
          r.data?.results?.tasks?.deal &&
          r.data?.results?.tasks?.party &&
          r.data?.results?.tasks?.property &&
          r.data?.results?.tasks?.asset &&
          r.data?.results?.tasks?.statement
        ) {
          taskCreatedCount =
            r.data?.results?.tasks?.deal?.length +
            r.data?.results?.tasks?.party?.length +
            r.data?.results?.tasks?.property?.length +
            r.data?.results?.tasks?.asset?.length +
            r.data?.results?.tasks?.statement?.length;
        }
        successToast({
          title: "Tasks Created",
          description: `total tasks created: ${taskCreatedCount}`
        });
        props.onSuccess();
      }
      r.status === 400 &&
        errorToast({
          title: "Failed to create tasks",
          description: r.data.description
        });
    });
  };

  const { state, onChange } = useFormBuilderStateHandler({
    initialState: {} as Partial<ManualGenerateTaskFormState>
  });
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [selectedPartyIds, setSelectedPartyIds] = useState<string[]>([]);
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
  const [selectedConfigurations, setSelectedConfigurations] = useState<
    string[]
  >([]);

  useEffect(() => {
    let preSelectedItem: string[] = [];
    if (state.entityType === "deal" && props.dealId) {
      preSelectedItem = [props.dealId];
    } else if (state.entityType === "party" && props.partyId) {
      preSelectedItem = [props.partyId];
    } else if (state.entityType === "property" && props.propertyId) {
      preSelectedItem = [props.propertyId];
    }
    setSelectedItems([...preSelectedItem]);
    setSelectedConfigurations([]);
  }, [state.entityType]);

  const [partyTreeChildren, setPartyTreeChildren] = useState<string[]>([]);
  useEffect(() => {
    if (props.partyId) {
      const partyTree = partyTreeIds(partyRelationState, [props.partyId]);
      setPartyTreeChildren(partyTree);
    }
  }, [props.partyId]);
  useEffect(() => {
    if (selectedAssetIds.length || selectedPartyIds.length)
      setSelectedItems([]);
  }, [selectedAssetIds, selectedPartyIds]);
  useEffect(() => {
    setSelectedAssetIds([]);
    setSelectedItems([]);
  }, [selectedPartyIds]);
  useEffect(() => {
    if (!state.entityType) {
      setSelectedAssetIds([]);
      setSelectedPartyIds([]);
    }
  }, [state.entityType]);

  const entityTypeOptions = [
    {
      label: "deal",
      value: "deal"
    },
    {
      label: "party",
      value: "party"
    },
    {
      label: "property",
      value: "property"
    },
    {
      label: "asset",
      value: "asset"
    },
    {
      label: "statement",
      value: "statement"
    }
  ];
  const entityTypeFilteredOptions = entityTypeOptions.filter(
    (v) =>
      (["party", "asset", "statement"].includes(v.value) && props.partyId) ||
      !props.partyId
  );
  return (
    <>
      <Box>
        <FormBuilder
          customKey="selectTasks"
          elphiView="form"
          sections={[
            {
              header: "Select Tasks",
              inputs: [
                {
                  currentValue: state.entityType,
                  fieldKey: ["entityType"],
                  fieldType: FieldType.SingleSelect,
                  label: "entity type",
                  labelPosition: "up",
                  isValid: !!state.entityType,
                  options: entityTypeFilteredOptions
                }
              ]
            }
          ]}
          onChange={onChange}
        />
        {state.entityType && state.entityType === "party" && props.dealId && (
          <DealPartySearch
            fieldType={FieldType.MultiSelect}
            dealId={props.dealId}
            onSelect={setSelectedItems}
            currentValue={selectedItems}
            allowPartyRelations={true}
            label={"select parties"}
          />
        )}
        {state.entityType && state.entityType === "deal" && props.dealId && (
          <DealSearch
            onSelect={(_: string) => {}}
            currentValue={props.dealId}
            label={"selected deal"}
            isReadOnly={true}
          />
        )}
        {state.entityType &&
          ["party", "asset", "statement"].includes(state.entityType) &&
          props.partyId && (
            <PartySearch
              filter={(p) => {
                return !!partyTreeChildren.includes(p.id);
              }}
              fieldType={FieldType.MultiSelect}
              onSelect={
                state.entityType === "party"
                  ? setSelectedItems
                  : setSelectedPartyIds
              }
              currentValue={
                state.entityType === "party" ? selectedItems : selectedPartyIds
              }
              label={"selected party"}
            />
          )}
        {state.entityType &&
          state.entityType === "property" &&
          props.dealId && (
            <DealPropertySearchContainer
              fieldType={FieldType.MultiSelect}
              dealId={props.dealId}
              onSelect={setSelectedItems}
              currentValue={selectedItems}
              label={"select properties"}
            />
          )}
        {state.entityType &&
          ["asset", "statement"].includes(state.entityType) &&
          props.dealId && (
            <DealPartySearch
              fieldType={FieldType.MultiSelect}
              dealId={props.dealId}
              onSelect={setSelectedPartyIds}
              currentValue={selectedPartyIds}
              allowPartyRelations={true}
              label={"select party"}
            />
          )}
        {state.entityType &&
          ["asset", "statement"].includes(state.entityType) &&
          !!selectedPartyIds.length && (
            <AssetQuerySearch
              fieldType={FieldType.MultiSelect}
              onSelect={
                state.entityType === "asset"
                  ? setSelectedItems
                  : setSelectedAssetIds
              }
              currentValue={
                state.entityType === "asset"
                  ? selectedItems
                  : selectedAssetIds || ""
              }
              label={"select assets"}
              selectedParty={selectedPartyIds.map(
                (id) => partyState.entities[id]
              )}
              query={assetQueryType.onlyPartyAssets}
            />
          )}
        {state.entityType &&
          state.entityType === "statement" &&
          !!selectedAssetIds.length && (
            <StatementQuerySearch
              fieldType={FieldType.MultiSelect}
              onSelect={setSelectedItems}
              currentValue={selectedItems}
              label={"select statements"}
              selectedAsset={selectedAssetIds.map(
                (id) => assetState.entities[id]
              )}
              query={statementQueryType.onlyAssetStatements}
            />
          )}
        {state.entityType &&
          (selectedItems.length > 0 ||
            (props.dealId && state.entityType === "deal") ||
            (props.partyId && state.entityType === "party") ||
            (props.propertyId && state.entityType === "property")) && (
            <TaskConfigurationSearch
              filter={(v) =>
                v.taskEntity === state.entityType &&
                v.autoGenerate === "never" &&
                v.status === "active"
              }
              fieldType={FieldType.MultiSelect}
              currentValue={selectedConfigurations}
              onSelect={setSelectedConfigurations}
              label={"select tasks"}
            />
          )}
      </Box>
      <Button
        {...elphiTheme.components.light.button.primary}
        onClick={() => generateTaskHandler()}
        isDisabled={
          !(
            selectedItems.length ||
            (props.dealId && state.entityType === "deal") ||
            (props.partyId && state.entityType === "party") ||
            (props.propertyId && state.entityType === "property")
          ) ||
          !selectedConfigurations.length ||
          !state.entityType
        }
        isLoading={generateManualTasksApiResponse.isLoading || false}
      >
        Submit
      </Button>
    </>
  );
};
export const ManualGenerateTaskModal = (props: {
  dealId?: string;
  propertyId?: string;
  partyId?: string;
  isDisabled?: boolean;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <Button
        isDisabled={props.isDisabled}
        leftIcon={<AddIcon />}
        {...elphiTheme.components.light.button.primary}
        onClick={onOpen}
      >
        Manual Generate Tasks
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={"600px"}>
          <ModalHeader>Submit to generate tasks</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ManualGenerateTaskForm {...props} onSuccess={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
