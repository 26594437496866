import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { ReadonlyFields } from "../entities";
import { FieldMeta } from "../entities/fieldMeta.types";
import { StorageProvider } from "../storage";

/////////////////
//
//  firebase conflicts with firebase-admin types
//   timestamp and arrayUnion/arrayRemove can be imported via firestore shared lib which uses the firebase admin
//
//

// export type FirestoreFieldValue = firebase.firestore.FieldValue;
// export const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
// export const arrayRemove = firebase.firestore.FieldValue.arrayRemove;
// const timestamp = firebase.firestore.FieldValue.serverTimestamp;
export type StorageMetaFields = {
  folderId?: string;
};
export type StorageMeta = {
  storageMeta?: {
    [StorageProvider.Box]?: StorageMetaFields;
    [StorageProvider.GCP]?: StorageMetaFields;
  };
};
export type ElphiScope = {
  orgId: string[];
  groupId: string[];
  teamId: string[];
};

export type SearchTags = {
  keywords?: string[];
};

export type BaseEntity<T extends object> = {
  deleted?: boolean;
  isCloned?: boolean;
  cascaded?: boolean;
  id: string;
  index: string;
  scope?: ElphiScope;
  createdUID?: string;
  modifiedUID?: string;
} & Timestamp &
  T &
  SearchTags &
  ReadonlyFields &
  StorageMeta &
  FieldMeta<T>;

export type TimestampField = {
  _seconds?: number;
  _nanoseconds?: number;
  seconds?: number;
  nanoseconds?: number;
};

export type FirestoreTimestampFieldUnionType =
  | string
  | TimestampField
  | firebase.firestore.Timestamp
  | firebase.firestore.FieldValue;
export type Timestamp = {
  createdAt: FirestoreTimestampFieldUnionType;
  modifiedAt: FirestoreTimestampFieldUnionType;
};
export type OmitCreate<T extends BaseEntity<object>> = ForcefullyOmit<
  T,
  keyof BaseEntity<object>
>;
export type OmitUpdate<T extends BaseEntity<object>> = ForcefullyOmit<
  T,
  keyof (Timestamp & Pick<BaseEntity<object>, "index">)
>;

export type ForcefullyOmit<T, K extends keyof T> = Omit<T, K> &
  Partial<Record<K, never>>;
