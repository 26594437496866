import { createIcon } from "@chakra-ui/react";

const customIcon = (fill?: string) =>
  createIcon({
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 13H11V15H3V13ZM3 17H11V19H3V17ZM3 9H11V11H3V9ZM3 5H11V7H3V5ZM19 7V17H15V7H19ZM21 5H13V19H21V5Z"
          fill={fill}
        />
      </svg>
    ),
    defaultProps: {
      fill: "black"
    }
  });

export const MenuRightIcon = customIcon("black");
