import { Box, Button } from "@chakra-ui/react";
import { useGate } from "statsig-react";
import { GateKeeper } from "../../gate/GateKeeper";

export const TestFeatureFlag = () => {
  const { value } = useGate("test-gate");
  console.log("test-gate value: ", value);
  return (
    <Box>
      {value ? "PASS" : "FAIL"}
      <TestGateKeeper />
    </Box>
  );
};
export const TestFeature = () => {
  return (
    <Box>
      <Button>FEATURE</Button>
    </Box>
  );
};
export const TestGateKeeper = () => {
  return (
    <GateKeeper gate={"test-gate"}>
      <TestFeature />
    </GateKeeper>
  );
};
