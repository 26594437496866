import { useCallback } from "react";
import { useRolodexConfiguration } from "../../../../../../hooks/rolodexConfiguration.hooks";
import { getRankedData } from "../../../../../../utils/ranked.utils";
import { SearchComponentProps } from "../../../../../SearchComponent";
import { SearchHandler } from "../../../../../search/SearchHandler";
import { ConfigurationSearchProps } from "../serviceDomainConfiguration.types";
import { buildSearchOption } from "../utils/configuration.utils";

export const ConfigurationSearch = (props: ConfigurationSearchProps) => {
  const {
    configurationState,
    searchApi,
    searchResponse,
    rankedSort,
    dataRank
  } = useRolodexConfiguration();

  const customFilterLogic: SearchComponentProps["filterOption"] = (
    option,
    searchText
  ) => {
    if (!searchText) {
      return true;
    }
    const configuration = configurationState?.entities?.[option.data.value];
    if (!configuration?.id) {
      return false;
    }
    return (
      getRankedData(searchText, configuration, dataRank) > 0.5 ||
      searchText === configuration.id.toLowerCase()
    );
  };
  const customFilter = useCallback(customFilterLogic, [
    configurationState?.entities
  ]);

  return (
    <SearchHandler
      {...props}
      version={"v2"}
      label={"Search configuration"}
      labelPosition={"placeHolder"}
      searchApi={searchApi}
      searchResponse={searchResponse}
      state={configurationState}
      filterOption={customFilter}
      buildOption={buildSearchOption}
      rankedSort={rankedSort}
    />
  );
};
