import {
  BranchServiceProviderFields,
  CompanyServiceProviderFields,
  FieldType,
  RepresentativeServiceProviderFields,
  ServiceProviderLendingPartnerType
} from "@elphi/types";
import { createOptionsFromEnum } from "../../../utils/formUtils";
import { nestedAddressFieldSpecs } from "../address/address.fields";
import { EntityFormFieldSpecs } from "../fields.types";

export const companyServiceProviderFieldsSpecs: EntityFormFieldSpecs<CompanyServiceProviderFields> =
  {
    name: {
      fieldKey: ["name"],
      fieldType: FieldType.String,
      label: "Name"
    },
    ein: {
      fieldKey: ["ein"],
      fieldType: FieldType.String,
      label: "EIN"
    }
  };

export const branchServiceProviderFieldsSpecs: EntityFormFieldSpecs<BranchServiceProviderFields> =
  {
    ...nestedAddressFieldSpecs,
    name: {
      fieldKey: ["name"],
      fieldType: FieldType.String,
      label: "Name"
    },
    ein: {
      fieldKey: ["ein"],
      fieldType: FieldType.String,
      label: "EIN"
    },
    phoneNumber: {
      fieldKey: ["phoneNumber"],
      fieldType: FieldType.Phone,
      label: "Phone Number"
    },
    email: {
      fieldKey: ["email"],
      fieldType: FieldType.Email,
      label: "Email"
    },
    coverageArea: {
      fieldKey: ["coverageArea"],
      fieldType: FieldType.String,
      label: "Coverage Area"
    },
    numberOfEmployees: {
      fieldKey: ["numberOfEmployees"],
      fieldType: FieldType.Number,
      label: "Number Of Employees"
    },
    nmlsLicenseNumber: {
      fieldKey: ["nmlsLicenseNumber"],
      fieldType: FieldType.Number,
      label: "NMLS License Number"
    },
    lendingPartnerType: {
      fieldKey: ["lendingPartnerType"],
      fieldType: FieldType.SingleSelect,
      label: "Lending Partner Type",
      options: createOptionsFromEnum(ServiceProviderLendingPartnerType)
    },
    wireRoutingNumber: {
      fieldKey: ["wireRoutingNumber"],
      fieldType: FieldType.Number,
      label: "Wire Routing Number"
    },
    wireAccountNumber: {
      fieldKey: ["wireAccountNumber"],
      fieldType: FieldType.Number,
      label: "Wire Account Number"
    },
    wireNameOnAccount: {
      fieldKey: ["wireNameOnAccount"],
      fieldType: FieldType.String,
      label: "Wire Name On Account"
    }
  };

export const repServiceProviderFieldsSpecs: EntityFormFieldSpecs<RepresentativeServiceProviderFields> =
  {
    ...nestedAddressFieldSpecs,
    firstName: {
      fieldKey: ["firstName"],
      fieldType: FieldType.String,
      label: "Name"
    },
    middleName: {
      fieldKey: ["middleName"],
      fieldType: FieldType.String,
      label: "First Name"
    },
    lastName: {
      fieldKey: ["lastName"],
      fieldType: FieldType.String,
      label: "Last Name"
    },
    phoneNumber: {
      fieldKey: ["phoneNumber"],
      fieldType: FieldType.Phone,
      label: "Phone Number"
    },
    email: {
      fieldKey: ["email"],
      fieldType: FieldType.Email,
      label: "Email"
    },
    licenseNumber: {
      fieldKey: ["licenseNumber"],
      fieldType: FieldType.String,
      label: "License Number"
    },
    driversLicenseNumber: {
      fieldKey: ["driversLicenseNumber"],
      fieldType: FieldType.String,
      label: "Drivers License Number"
    },
    nmlsLicenseNumber: {
      fieldKey: ["nmlsLicenseNumber"],
      fieldType: FieldType.Number,
      label: "NMLS License Number"
    },
    birthDate: {
      fieldKey: ["birthDate"],
      fieldType: FieldType.Date,
      label: "Birth Date"
    },
    businessRegistrationStates: {
      fieldKey: ["businessRegistrationStates"],
      fieldType: FieldType.Array,
      label: "Business Registration States"
    }
  };
