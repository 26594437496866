import { FirebaseCollections } from "@elphi/types";
import assert from "assert";
import { converter } from "../../snapshots/src/data/convertor.utils";

export const setMockData =
  (firestoreService: {
    collection: (
      cp: string
    ) => FirebaseFirestore.CollectionReference<FirebaseFirestore.DocumentData>;
  }) =>
  async <T extends Partial<object>>(r: {
    id: string;
    firebaseCollection: FirebaseCollections;
    data: T;
  }) => {
    await firestoreService
      .collection(r.firebaseCollection)
      .doc(r.id)
      .set({ ...r.data, id: r.id }, { merge: true })
      .catch(() =>
        assert.fail(
          `failed setMockData for id ${r.id} on ${r.firebaseCollection}`
        )
      );
  };

export const deleteMockData =
  (firestoreService: {
    collection: (
      cp: string
    ) => FirebaseFirestore.CollectionReference<FirebaseFirestore.DocumentData>;
  }) =>
  async (r: { id: string; firebaseCollection: FirebaseCollections }) => {
    await firestoreService
      .collection(r.firebaseCollection)
      .doc(r.id)
      .delete()
      .catch(() =>
        assert.fail(
          `failed deleteMockData for id ${r.id} on ${r.firebaseCollection}`
        )
      );
  };

export const getMockData =
  (firestoreService: {
    collection: (
      cp: string
    ) => FirebaseFirestore.CollectionReference<FirebaseFirestore.DocumentData>;
  }) =>
  async <T extends Partial<object>>(r: {
    id: string;
    firebaseCollection: FirebaseCollections;
  }) => {
    return await firestoreService
      .collection(r.firebaseCollection)
      .doc(r.id)
      .withConverter(converter<T>())
      .get()
      .then((x) => x.data() || ({} as T))
      .catch(() =>
        assert.fail(
          `failed getMockData for id ${r.id} on ${r.firebaseCollection}`
        )
      );
  };
