export * from "./asset.template";
export * from "./creditReport.template";
export * from "./deal.template";
export * from "./dealParty.template";
export * from "./dealProperty.template";
export * from "./documentConfiguration.template";
export * from "./documentPackageOrder.template";
export * from "./party.template";
export * from "./partyGroup.template";
export * from "./partyRelation.template";
export * from "./property.template";
export * from "./statement.template";
