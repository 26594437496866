import { Deal } from "@elphi/types";
import { ObjectKeyValidation } from "../../form-builder/field-specs/objectKeyValidation.types";

export const netWireAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "NetWireAmount"]
> = ["aggregations", "NetWireAmount"];

export const estimatedClosingDateFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "EstimatedClosingDate"]
> = ["aggregations", "EstimatedClosingDate"];

export const wireReleaseDateFieldPath: ObjectKeyValidation<
  Deal,
  ["WireReleaseDate"]
> = ["WireReleaseDate"];

export const loanFundingDateFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanFundingDate"]
> = ["LoanFundingDate"];

export const warehouseBankTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["WarehouseBankType"]
> = ["WarehouseBankType"];

export const warehouseBankStatusTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["WarehouseBankStatusType"]
> = ["WarehouseBankStatusType"];

export const fundingShieldStatusTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["FundingShieldStatusType"]
> = ["FundingShieldStatusType"];

export const haircutAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["HaircutAmount"]
> = ["HaircutAmount"];

export const postClosingNotesFieldPath: ObjectKeyValidation<
  Deal,
  ["PostClosingNotes"]
> = ["PostClosingNotes"];

export const closingAgentFirstNameFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "ClosingAgentFirstName"]
> = ["TitleCompany", "ClosingAgentFirstName"];

export const closingAgentLastNameFieldPath: ObjectKeyValidation<
  Deal,
  ["TitleCompany", "ClosingAgentLastName"]
> = ["TitleCompany", "ClosingAgentLastName"];

export const promissoryNoteCommentsFieldPath: ObjectKeyValidation<
  Deal,
  ["PromissoryNoteComments"]
> = ["PromissoryNoteComments"];

export const shippingCompanyTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["ShippingCompanyType"]
> = ["ShippingCompanyType"];

export const noteToWarehouseTrackingNumberFieldPath: ObjectKeyValidation<
  Deal,
  ["NoteToWarehouseTrackingNumber"]
> = ["NoteToWarehouseTrackingNumber"];

export const settlementStatementFileNumberFieldPath: ObjectKeyValidation<
  Deal,
  ["SettlementStatementFileNumber"]
> = ["SettlementStatementFileNumber"];

export const personalGuarantorsCountFieldPath: ObjectKeyValidation<
  Deal,
  ["PersonalGuarantorsCount"]
> = ["PersonalGuarantorsCount"];

export const powerOfAttorneyFullNameFieldPath: ObjectKeyValidation<
  Deal,
  ["PowerOfAttorneyFullName"]
> = ["PowerOfAttorneyFullName"];

export const loanProgramTypeFieldPath: ObjectKeyValidation<
  Deal,
  ["aggregations", "LoanProgramType"]
> = ["aggregations", "LoanProgramType"];

export const promissoryNoteRequestedIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["PromissoryNoteRequestedIndicator"]
> = ["PromissoryNoteRequestedIndicator"];

export const promissoryNoteShippedIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["PromissoryNoteShippedIndicator"]
> = ["PromissoryNoteShippedIndicator"];

export const promissoryNoteTrackingNumberFieldPath: ObjectKeyValidation<
  Deal,
  ["PromissoryNoteTrackingNumber"]
> = ["PromissoryNoteTrackingNumber"];

export const allongeAssignmentSentToWarehouseIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["AllongeAssignmentSentToWarehouseIndicator"]
> = ["AllongeAssignmentSentToWarehouseIndicator"];

export const allongeAssignmentTrackingNumberFieldPath: ObjectKeyValidation<
  Deal,
  ["AllongeAssignmentTrackingNumber"]
> = ["AllongeAssignmentTrackingNumber"];

export const closingAnalystFieldPath: ObjectKeyValidation<
  Deal,
  ["ClosingAnalystUserId"]
> = ["ClosingAnalystUserId"];

export const notaryAnalystFieldPath: ObjectKeyValidation<
  Deal,
  ["NotaryUserId"]
> = ["NotaryUserId"];

export const notaryExpirationDateFieldPath: ObjectKeyValidation<
  Deal,
  ["NotaryExpirationDate"]
> = ["NotaryExpirationDate"];

export const loanPackageTrackingNumberFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanPackageTrackingNumber"]
> = ["LoanPackageTrackingNumber"];

export const loanPackageNoteTrackingNumberFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanPackageNoteTrackingNumber"]
> = ["LoanPackageNoteTrackingNumber"];

export const fundingAndDisbursementApprovalIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["FundingAndDisbursementApprovalIndicator"]
> = ["FundingAndDisbursementApprovalIndicator"];

export const loanPackageInternationalExecutionIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanPackageInternationalExecutionIndicator"]
> = ["LoanPackageInternationalExecutionIndicator"];

export const loanPackageNoteReceivedByLenderIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanPackageNoteReceivedByLenderIndicator"]
> = ["LoanPackageNoteReceivedByLenderIndicator"];

export const loanPackageChecksReceivedIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanPackageChecksReceivedIndicator"]
> = ["LoanPackageChecksReceivedIndicator"];

export const loanPackageCheckNumberFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanPackageCheckNumber"]
> = ["LoanPackageCheckNumber"];

export const loanPackageCheckAmountFieldPath: ObjectKeyValidation<
  Deal,
  ["LoanPackageCheckAmount"]
> = ["LoanPackageCheckAmount"];

export const achElectedIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["ACHElectedIndicator"]
> = ["ACHElectedIndicator"];
export const sellerHUDAddedIndicatorFieldPath: ObjectKeyValidation<
  Deal,
  ["SellerHUDAddedIndicator"]
> = ["SellerHUDAddedIndicator"];
export const purchaserWarrantyDeedAddedIndicator: ObjectKeyValidation<
  Deal,
  ["PurchaserWarrantyDeedAddedIndicator"]
> = ["PurchaserWarrantyDeedAddedIndicator"];
