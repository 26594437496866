import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ElphiSpinner from "../../../../common-components/loading-spinner/components/ElphiSpinner";
import { auth } from "../../../../firebase/firebaseConfig";
import { useLOSUserHooks } from "../../../../hooks/losuser.hooks";
import { useOrgHooks } from "../../../../hooks/org.hooks";
import PathVariables from "../../../../routes/pathVariables";
import { newTab } from "../../../utils/navigationUtils";

export type SelectOrganizationProps = {
  op: "new-tab" | "current-tab";
  onSuccess?: () => void;
};
export type SelectOrganizationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  op: SelectOrganizationProps["op"];
};
export const SelectOrganizationList = (props: SelectOrganizationProps) => {
  const {
    orgState,
    setSelectedOrg,
    getUserOrganizations,
    getUserOrganizationsApiResponse
  } = useOrgHooks();
  const { getLOSUser } = useLOSUserHooks();
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.currentUser) getUserOrganizations(auth.currentUser.uid);
  }, [auth.currentUser]);

  useEffect(() => {
    if (orgState.ids.length === 1) {
      handleOrganizationSelection(orgState.ids?.[0]?.toString());
    }
  }, [orgState.ids]);

  const handleOrganizationSelection = (id: string) => {
    if (props.op === "current-tab") setSelectedOrg(id.toString());
    if (auth.currentUser?.uid) {
      getLOSUser(auth.currentUser.uid, true)
        .then(() => {
          const path = `${id.toString()}${PathVariables.LoanPipeline}`;
          if (props.op === "new-tab") {
            newTab(`/${path}`, {
              features: ""
            });
          } else {
            navigate(`/${path}`);
          }
          props?.onSuccess?.();
        })
        .catch((err) => {
          console.log("failed to fetch userData", err);
          auth.signOut();
        });
    }
  };
  return (
    <Box>
      {getUserOrganizationsApiResponse.isFetching ||
      getUserOrganizationsApiResponse.isLoading ? (
        <ElphiSpinner />
      ) : null}
      {orgState.ids.map((id, key) => {
        return (
          <Box
            p="5px"
            key={key}
            boxShadow="lg"
            _hover={{ background: "lightblue" }}
            cursor={auth.currentUser ? "pointer" : "progress"}
            onClick={() => handleOrganizationSelection(id.toString())}
          >
            <Box>
              <Text fontWeight="bold">{orgState?.entities?.[id]?.name}</Text>
            </Box>
            <Box>
              <Text>{orgState?.entities?.[id]?.description}</Text>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export const SelectOrganizationModal = (
  props: SelectOrganizationModalProps
) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box p="10px">
            <Text fontSize="28px" fontWeight={"bold"}>
              Select Organization
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SelectOrganizationList op={props.op} onSuccess={props.onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default SelectOrganizationList;
