import {
  Box,
  Button,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import {
  ElphiEntityType,
  EntitiesFieldSelection,
  FieldSelection,
  FieldStatus,
  TaskConfiguration,
  TaskConfigurationDataToType
} from "@elphi/types";
import { merge } from "lodash";
import { ReactElement, SetStateAction, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { responseHandler } from "../../../apis/rtk/response.handler";
import elphiTheme from "../../../assets/themes/elphi.theme.default";
import { useTaskConfigurationHooks } from "../../../hooks/taskConfiguration.hooks";
import { taskConfigurationApi } from "../../../redux/v2/task-configuration/taskConfiguration.service";
import { getSortedData } from "../../../redux/v2/utils/sort.utils";
import { sequentializeIndexes } from "../../../utils/common";
import {
  BaseButtonIconAdd,
  BaseButtonIconDelete
} from "../../button-icon/ButtonIcon";
import {
  InputBuilderSpecs,
  OnChangeInput
} from "../../form-builder/FormBuilder";
import StyledInputBuilder, {
  useFormBuilderStateHandler
} from "../../form-builder/InputBuilder";
import { FieldType } from "../../form-builder/fieldFormat.types";
import { useElphiToast } from "../../toast/toast.hook";
import { fieldSelection } from "../../utils/field-selection/builders/field-selection-task.builder";

type FormState = EntitiesFieldSelection;

const sortEntityFieldSelection = ({
  fieldKey,
  inputsIndices,
  state
}: {
  fieldKey: string[];
  inputsIndices: {
    [id: string]: { index: number };
  };
  state: EntitiesFieldSelection;
}): EntitiesFieldSelection => {
  const [entityName] = fieldKey;
  const updated = structuredClone(state);
  const fieldSelections: FieldSelection | undefined = updated[entityName];
  fieldSelections &&
    Object.entries(fieldSelections).forEach(
      ([k, f]: [
        string,
        { fieldPath: string; status?: FieldStatus; index: number }
      ]) => {
        f.index = inputsIndices[k]?.index || 0;
      }
    );
  return updated;
};

export const createFieldSelectInput = (entityType: ElphiEntityType) => {
  const id = uuid();
  return {
    id,
    input: (state: FormState) => {
      return {
        fieldType: FieldType.SingleSelect,
        fieldKey: [entityType, id, "fieldPath"],
        options: fieldSelection.build(entityType),
        currentValue: state?.[entityType]?.[id]?.fieldPath,
        label: "",
        isValid: state?.[entityType]?.[id]?.fieldPath?.length > 0
      };
    }
  };
};
export const createFieldSelectInputFromState = (
  state: FormState,
  entityType: ElphiEntityType
) => {
  const sortedInputs = state?.[entityType] && getSortedData(state[entityType]);
  return Object.keys(sortedInputs || {}).map((k) => {
    return {
      id: k,
      input: (state: FormState) => {
        return {
          fieldType: FieldType.SingleSelect,
          fieldKey: [entityType, k, "fieldPath"],
          options: fieldSelection.build(entityType),
          currentValue: state?.[entityType]?.[k]?.fieldPath,
          label: "",
          isValid: state?.[entityType]?.[k]?.fieldPath?.length > 0
        };
      }
    };
  });
};
export const FieldSelectionConfigurationForm = (props: {
  taskConfiguration: TaskConfiguration;
  fieldSelection: TaskConfigurationDataToType;
}) => {
  const { taskConfigurationState } = useTaskConfigurationHooks();
  const defaultFieldSelection: FormState = {
    deal: {},
    property: {},
    party: {},
    asset: {},
    statement: {},
    "credit-score": {},
    "deal-party": {},
    "deal-property": {},
    "party-relation": {},
    task: {}
  };

  const {
    state,
    onChange: onFormChange,
    setState,
    setDiffState,
    syncState
  } = useFormBuilderStateHandler({
    initialState:
      props?.taskConfiguration?.[props.fieldSelection] || defaultFieldSelection
  });
  const { taskConfiguration } = props;
  const fields =
    taskConfigurationState?.["entities"]?.[taskConfiguration.id]?.[
      props.fieldSelection
    ];
  useEffect(() => {
    syncState({
      shouldSync: !!fields,
      state: fields
    });
  }, [fields]);

  const { successToast, errorToast } = useElphiToast();
  const [updateConfigurationApi, updateResponse] =
    taskConfigurationApi.useSystemUpdateMutation();
  const updateConfigurationHandler = async (
    fieldsSelection: EntitiesFieldSelection
  ) => {
    updateConfigurationApi({
      id: props.taskConfiguration.id,
      [props.fieldSelection]: fieldsSelection
    } as { id: string } & Partial<TaskConfiguration>)
      .then(responseHandler)
      .then((r) => {
        if (r.status === 200) {
          successToast({
            title: "Task configuration update",
            description: `${props.taskConfiguration.name}`
          });
        } else if (r.status === 400) {
          errorToast({
            title: "Failed to update",
            description: r?.data?.description
          });
        }
      });
  };

  const onChangeHandler = ({
    value,
    inputsIndices
  }: {
    value: OnChangeInput;
    inputsIndices: {
      [id: string]: { index: number };
    };
  }) => {
    onFormChange(value);
    const sorted = sortEntityFieldSelection({
      fieldKey: value.fieldKey,
      inputsIndices,
      state
    });
    setState(sorted);
  };

  const commonProps = {
    onChange: onChangeHandler,
    setDiffState,
    setState,
    state
  };
  return (
    <Flex flexDir={"column"} w="100%" overflow="scroll">
      <Box w="100%" p="10px">
        <Button
          onClick={() => {
            updateConfigurationHandler(state);
          }}
          isLoading={updateResponse.isLoading}
          float="right"
          {...elphiTheme.components.light.button.primary}
        >
          Save
        </Button>
      </Box>
      <Box>
        <EntityFieldSelectionFormConfiguration
          entityType={ElphiEntityType.deal}
          label="Deal Fields Selection"
          {...commonProps}
        />
        <EntityFieldSelectionFormConfiguration
          entityType={ElphiEntityType.party}
          label="Party Fields Selection"
          {...commonProps}
        />
        <EntityFieldSelectionFormConfiguration
          entityType={ElphiEntityType.property}
          label="Property Fields Selection"
          {...commonProps}
        />
        <EntityFieldSelectionFormConfiguration
          entityType={ElphiEntityType.asset}
          label="Asset Fields Selection"
          {...commonProps}
        />
        <EntityFieldSelectionFormConfiguration
          entityType={ElphiEntityType.statement}
          label="Statement Fields Selection"
          {...commonProps}
        />
        <EntityFieldSelectionFormConfiguration
          entityType={ElphiEntityType.creditScore}
          label="Credit Score Fields Selection"
          {...commonProps}
        />
      </Box>
    </Flex>
  );
};

const EntityFieldSelectionFormConfiguration = (props: {
  entityType: ElphiEntityType;
  state: FormState;
  label: string;
  setState: (value: SetStateAction<FormState>) => void;
  setDiffState: (value: SetStateAction<Partial<FormState>>) => void;
  onChange: (r: {
    value: OnChangeInput;
    inputsIndices: {
      [id: string]: { index: number };
    };
  }) => void;
}) => {
  const [inputs, setInputs] = useState<
    { id: string; input: (state: FormState) => InputBuilderSpecs }[]
  >([]);

  useEffect(() => {
    setInputs(createFieldSelectInputFromState(props.state, props.entityType));
  }, []);
  const inputsIndices = inputs.reduce((acc, input, index) => {
    acc[input.id] = { index };
    return acc;
  }, {});
  return (
    <Box p="10px" h="100%" w="100%">
      <Box shadow="md" p="10px" h="100%">
        <Box bgColor="blue.100" p="10px" borderRadius={"8px"}>
          <Text fontSize="18px" align="center">
            {props.label}
          </Text>
        </Box>
        <Box h="100%">
          {inputs.map((ib, index) => {
            const specs = ib.input(props.state);
            const newState = sortEntityFieldSelection({
              state: props.state,
              fieldKey: specs.fieldKey,
              inputsIndices
            });
            return (
              <FieldSelectionController
                onDelete={() => {
                  const newInputs = inputs.filter((i) => i.id !== ib.id);
                  delete newState[specs.fieldKey[0]][specs.fieldKey[1]];
                  const specAlignedIndexes = sequentializeIndexes(
                    newState[specs.fieldKey[0]]
                  );
                  newState[specs.fieldKey[0]] = specAlignedIndexes;
                  props.setState({ ...newState });
                  props.setDiffState({});
                  setInputs([...newInputs]);
                }}
                key={index}
                input={specs}
                onChange={(e) => props.onChange({ value: e, inputsIndices })}
              />
            );
          })}
        </Box>
        <Box>
          <Center>
            <BaseButtonIconAdd
              onClick={() => {
                const newState = merge({}, props.state);
                props.setState({ ...newState });
                setInputs([
                  ...inputs,
                  createFieldSelectInput(props.entityType)
                ]);
              }}
              isDisabled={false}
            />
          </Center>
        </Box>
      </Box>
    </Box>
  );
};
export const FieldSelectionController = (props: {
  onDelete: () => void;
  onChange: (v: OnChangeInput) => void;
  input: InputBuilderSpecs;
}) => {
  return (
    <Flex w="100%">
      <Box w="100%" p="10px">
        <StyledInputBuilder
          {...props.input}
          size={{
            maxW: "100%"
          }}
          onChange={(e) => {
            props.onChange({
              fieldKey: props.input.fieldKey,
              fieldType: props.input.fieldType,
              value: e.target.value
            });
          }}
        />
      </Box>
      <Box p="10px">
        <BaseButtonIconDelete onClick={props.onDelete} />
      </Box>
    </Flex>
  );
};

export const ConfigurationModalHeaderTitle = ({
  title,
  taskConfigurationName
}: {
  title: string;
  taskConfigurationName: string;
}) => {
  const taskConfigurationText = `Task Configuration: ${taskConfigurationName}`;
  return (
    <>
      <Box p="5">
        <Text textAlign={"center"}>{title}</Text>
      </Box>
      <Box pl="5">
        <Text textAlign={"center"}>{taskConfigurationText}</Text>
      </Box>
    </>
  );
};

export const FieldSelectionConfigurationModal = (props: {
  taskConfiguration: TaskConfiguration;
  fieldSelection: TaskConfigurationDataToType;
  headerTitle: ReactElement;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box h="100%">
      <Box>
        <Button
          float={"right"}
          {...elphiTheme.components.light.button.secondary}
          onClick={onOpen}
        >
          Edit
        </Button>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW="800px" width="100%">
          <ModalHeader>{props.headerTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FieldSelectionConfigurationForm
              taskConfiguration={props.taskConfiguration}
              fieldSelection={props.fieldSelection}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
