import { EntityParty } from "@elphi/types";
import { ObjectKeyValidation } from "../../form-builder/field-specs/objectKeyValidation.types";

export const entityTypeFieldPath: ObjectKeyValidation<
  EntityParty,
  ["EntityType"]
> = ["EntityType"];

export const entityFullNameFieldPath: ObjectKeyValidation<
  EntityParty,
  ["FullName"]
> = ["FullName"];

export const representativeCreditScoreFieldPath: ObjectKeyValidation<
  EntityParty,
  ["RepresentativeCreditScore"]
> = ["RepresentativeCreditScore"];

export const businessIncorporationStateNameFieldPath: ObjectKeyValidation<
  EntityParty,
  ["BusinessIncorporationStateName"]
> = ["BusinessIncorporationStateName"];

export const businessIncorporationStateCodeFieldPath: ObjectKeyValidation<
  EntityParty,
  ["BusinessIncorporationStateCode"]
> = ["BusinessIncorporationStateCode"];

export const businessRegistrationStatesFieldPath: ObjectKeyValidation<
  EntityParty,
  ["BusinessRegistrationStates"]
> = ["BusinessRegistrationStates"];

export const fixAndFlipBridgePlusTierFieldPath: ObjectKeyValidation<
  EntityParty,
  ["FixAndFlipBridgePlusTier"]
> = ["FixAndFlipBridgePlusTier"];

export const newConstructionEligibilityIndicatorFieldPath: ObjectKeyValidation<
  EntityParty,
  ["NewConstructionEligibilityIndicator"]
> = ["NewConstructionEligibilityIndicator"];

export const remainingLineOfCreditAmountFieldPath: ObjectKeyValidation<
  EntityParty,
  ["RemainingLineOfCreditAmount"]
> = ["RemainingLineOfCreditAmount"];

export const realEstateOperationTypeFieldPath: ObjectKeyValidation<
  EntityParty,
  ["RealEstateOperationType"]
> = ["RealEstateOperationType"];

export const totalFixAndFlipNewConstructionSoldPastThreeYearsCountFieldPath: ObjectKeyValidation<
  EntityParty,
  ["TotalFixAndFlipNewConstructionSoldPastThreeYearsCount"]
> = ["TotalFixAndFlipNewConstructionSoldPastThreeYearsCount"];
export const liquidAssetTotalAmountFieldPath: ObjectKeyValidation<
  EntityParty,
  ["LiquidAssetTotalAmount"]
> = ["LiquidAssetTotalAmount"];
export const operationsDepartmentFieldPath: ObjectKeyValidation<
  EntityParty,
  ["OperationsDepartment"]
> = ["OperationsDepartment"];

export const crmIdFieldPath: ObjectKeyValidation<EntityParty, ["CRMId"]> = [
  "CRMId"
];

export const entityNotesFieldPath: ObjectKeyValidation<EntityParty, ["Notes"]> =
  ["Notes"];

export const evidenceOfGoodStandingExpirationDateFieldPath: ObjectKeyValidation<
  EntityParty,
  ["EvidenceOfGoodStandingExpirationDate"]
> = ["EvidenceOfGoodStandingExpirationDate"];

export const partyMilestoneFieldPath: ObjectKeyValidation<
  EntityParty,
  ["PartyMilestone"]
> = ["PartyMilestone"];

export const taxpayerIdentifierValueFieldPath: ObjectKeyValidation<
  EntityParty,
  ["TaxpayerIdentifierValue"]
> = ["TaxpayerIdentifierValue"];
