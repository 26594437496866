import { TaskConfiguration } from "@elphi/types";
import {
  createSlice,
  EntityId,
  EntityState,
  PayloadAction
} from "@reduxjs/toolkit";
import { union } from "lodash";

import sliceBuilder from "../builders/slice.builder";
import { SearchCursorState } from "../utils/search.utils";
import { taskConfigurationEntityAdapter as entityAdapter } from "./taskConfiguration.adapter";
import { taskConfigurationApi } from "./taskConfiguration.service";

export type TaskConfigurationTableFilterState = {
  taskName?: string[];
  taskNameOp: "in" | "not-in";
  selectedTaskId?: string;
};

export type TaskConfigurationSliceStateFields = {
  selectedId?: EntityId;
  searchCursor: SearchCursorState;
  searchResultsIds: EntityId[];
  taskConfigurationTable: {
    selectedId?: string;
    filter: TaskConfigurationTableFilterState;
    cursor: {
      currentId: string;
      hasMore: {
        [uniqId: string]: boolean;
      };
    };
  };
};

export type TaskConfigurationSliceState = EntityState<TaskConfiguration> &
  TaskConfigurationSliceStateFields;

export const initialState: TaskConfigurationSliceStateFields = {
  selectedId: undefined,
  searchResultsIds: [],
  searchCursor: {
    query: {}
  },
  taskConfigurationTable: {
    cursor: {
      currentId: "none",
      hasMore: {}
    },
    filter: {
      taskName: [],
      taskNameOp: "in"
    }
  }
};

export const taskConfigurationSlice = createSlice({
  name: "taskConfiguration",
  initialState: entityAdapter.getInitialState(initialState),
  reducers: {
    update: entityAdapter.updateOne,
    remove: entityAdapter.removeOne,
    add: entityAdapter.addOne,
    upsert: entityAdapter.upsertOne,
    upsertMany: entityAdapter.upsertMany,
    removeMany: entityAdapter.removeMany,
    updateMany: entityAdapter.updateMany,
    selectedId: (state, action: PayloadAction<{ id: EntityId }>) => {
      state.selectedId = action.payload.id;
    },
    setTaskConfigurationTableFilter: (
      state,
      action: PayloadAction<TaskConfigurationTableFilterState>
    ) => {
      state.taskConfigurationTable.filter = {
        ...state.taskConfigurationTable.filter,
        ...action.payload
      };
    },
    setTaskConfigurationTableCurrentCursorIdHasMore: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.taskConfigurationTable.cursor.hasMore[
        state.taskConfigurationTable.cursor.currentId
      ] = action.payload;
    },
    setTaskConfigurationTableCurrentCursorId: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      state.taskConfigurationTable.cursor.currentId = action.payload.id;
    }
  },
  extraReducers: (builder) => {
    sliceBuilder.crudExtraReducers(taskConfigurationApi)(builder);
    builder.addMatcher(
      taskConfigurationApi.endpoints.search.matchFulfilled,
      (state, { payload }) => {
        state.searchResultsIds = union(
          state.searchResultsIds,
          payload.results.ids
        );
        state.ids = union(state.ids, payload.results.ids);
        state.entities = {
          ...state.entities,
          ...payload.results.entities
        };
        state.searchCursor.query[payload.query] = {
          hasMore: payload.hasMore,
          nextCursor: payload.nextCursor
        };
      }
    );
    builder.addMatcher(
      taskConfigurationApi.endpoints.paginate.matchFulfilled,
      (state, { payload }) => {
        state.ids = union(state.ids, payload.ids);
        state.entities = { ...state.entities, ...payload.entities };
      }
    );
  }
});
