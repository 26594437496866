import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
type ConfigType = string | number | Date | null | undefined;
type FormatObject = {
  locale?: string;
  format?: string;
  utc?: boolean;
};
type OptionType = FormatObject | string | string[];

type ElphiDate = (
  date?: ConfigType,
  option?: {
    format?: OptionType;
    locale?: string;
    strict?: boolean;
  }
) => {
  format(format: string): string;
  isValid(): boolean;
  toDate(): Date;
  get(): Date;
};

const dateFactory = (): ElphiDate => (date, option) => {
  const { format, locale, strict } = option || {};

  const instance = dayjs(date, format, locale, strict);
  return {
    format: (format: string) => instance.format(format),
    isValid: () => instance.isValid(),
    toDate: () => instance.toDate(),
    get: () => instance.toDate()
  };
};

export const elphiDate = dateFactory();
