import { createIcon } from "@chakra-ui/react";

const customIcon = (fill?: string) =>
  createIcon({
    path: (
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="18" height="18" rx="9" fill={fill} />
        <path
          d="M5.25 8.5625L9 5.28125L12.75 8.5625V13.25H10.4063V11.375C10.4063 11.0021 10.2581 10.6444 9.99436 10.3806C9.73065 10.1169 9.37294 9.96875 9 9.96875C8.62702 9.96875 8.26936 10.1169 8.00564 10.3806C7.74191 10.6444 7.59375 11.002 7.59375 11.375V13.25H5.25L5.25 8.5625Z"
          stroke="#7B341E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    defaultProps: {
      fill: "none"
    }
  });

export const HouseIcon = customIcon();
export const HouseOrange100Icon = customIcon("#FEEBCB");
